import { memo, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCheckApp, getLkGetZoom, getLkViewContent, getViewMenu } from '../../../Api'
import { selectLKAuth } from '../../../store/reducer/lk/authChech/selector'
import { setCheckDownloadedApp } from '../../../store/reducer/lk/authChech/slice'
import { selectLKContract } from '../../../store/reducer/lk/contract/selector'
import { setIsCheckFromPage } from '../../../store/reducer/lk/contract/slice.js'
import { selectLKPayment } from '../../../store/reducer/lk/payment/selector'
import { selectLKSidebar } from '../../../store/reducer/lk/sidebar/selector'
import { setAddStatusZoom } from '../../../store/reducer/lk/zoom/slice'
import { setAddActiveLink } from '../../../store/reducer/main/slice'
import { setShowSidebar } from '../../../store/reducer/lk/sidebar/slice'
import Card from './Card/Card'
import Fancybox from '../../Fancybox'
import s from './material.module.scss'
import { setAddDoNotUnsubscribe } from '../../../store/reducer/formUnsubscribe/slice'

const carData = [
  {
    id: 1,
    img: '/static/lk/illustration/card/illustration.png',
    title: 'Презентация',
    subtitle: '7 минут на просмотр',
    descr: 'Из презентации ты узнаешь, как можно инвестировать и зарабатывать в Skilla',
    btn: 'Смотреть',
    link: 'https://skilla.ru/skillaprezent2022.pdf',
  },
  {
    id: 2,
    img: '/static/lk/illustration/card/illustration2.png',
    title: 'Кейсы из жизни',
    subtitle: '15 минут на изучение',
    descr: 'Более 250 предпринимателей уже зарабатывают со Skilla много лет',
    btn: 'Изучить кейсы',
    link: 'https://skilla.ru/business/partners',
  },
  {
    id: 3,
    img: '/static/lk/illustration/card/illustration3.png',
    title: 'Вопрос-ответ',
    subtitle: '10 минут на изучение',
    descr: 'Подробные ответы на вопросы, которые так часто нам задают новички бизнеса',
    btn: 'Читать',
    link: 'https://skilla.ru/business/faq',
  },
  {
    id: 4,
    img: '/static/lk/illustration/card/illustration4.png',
    title: 'Отзывы',
    subtitle: '8 минут на изучение',
    descr: 'Достоверные отзывы видео и текстовые от партнеров-предпринимателей',
    btn: 'Читать отзывы',
    link: 'https://skilla.ru/business/reviews',
  },
]

const Material = memo(({ endDatePremium }) => {
  const [width, setWidth] = useState(window?.innerWidth)
  const [videoPlay, setVideoPlay] = useState(false)
  const dispatch = useDispatch()
  const { user } = useSelector(selectLKAuth)
  const { activeLink, socketData } = useSelector(selectLKSidebar)
  const menuActive = user?.user?.menu
  const [play, setPlay] = useState('0')
  const { persent } = useSelector(selectLKContract)
  const { paymentInfo } = useSelector(selectLKPayment)
  const statusPayment = paymentInfo?.payment?.status
  const [scroll, setScroll] = useState(0)
  const [scrool2, setScrool2] = useState(0)
  const [bm, setBm] = useState(JSON.parse(localStorage.getItem('bm')) || false)
  const [pres, setPres] = useState(JSON.parse(localStorage.getItem('pres')) || false)
  const [quest, setQuest] = useState(JSON.parse(localStorage.getItem('quest')) || false)
  const [aplic, setAplic] = useState(JSON.parse(localStorage.getItem('aplic')) || false)
  const [gov, setGov] = useState(JSON.parse(localStorage.getItem('gov')) || false)
  const [rev, setRev] = useState(JSON.parse(localStorage.getItem('rev')) || false)
  const [caseic, setCase] = useState(JSON.parse(localStorage.getItem('caseic')) || false)
  const [contract, setContract] = useState(JSON.parse(localStorage.getItem('contract')) || false)
  const [scrollMob, setScrollMob] = useState(0)
  const videoRef = useRef();
  console.log(play)

  useEffect(() => {
    if (scroll >= 0 && 17.5 > scroll && !bm) {
      setBm(true)
      localStorage.setItem('bm', JSON.stringify(true))
    } else if (scroll >= 17.5 && 28.7 > scroll && !pres) {
      setPres(true)
      localStorage.setItem('pres', JSON.stringify(true))
    } else if (scroll > 28.7 && 40.7 > scroll && !quest) {
      setQuest(true)
      localStorage.setItem('quest', JSON.stringify(true))
    } else if (scroll > 40.7 && 51 > scroll && !aplic) {
      setAplic(true)
      localStorage.setItem('aplic', JSON.stringify(true))
    } else if (scroll > 51 && 63.7 > scroll && !gov) {
      setGov(true)
      localStorage.setItem('gov', JSON.stringify(true))
    } else if (scroll > 63.7 && 75 > scroll && !rev) {
      setRev(true)
      localStorage.setItem('rev', JSON.stringify(true))
    } else if (scroll > 75 && 92 > scroll && !caseic) {
      setCase(true)
      localStorage.setItem('caseic', JSON.stringify(true))
    } else if (scroll > 90 && !contract) {
      setContract(true)
      localStorage.setItem('contract', JSON.stringify(true))
    }
  }, [scroll])

  useEffect(() => {
    if (bm && !pres) {
      setScrool2(0)
    } else if (pres && !quest) {
      setScrool2(17)
    } else if (quest && !aplic) {
      setScrool2(27.8)
    } else if (aplic && !gov) {
      setScrool2(38)
    } else if (gov && !rev) {
      setScrool2(48.5)
    } else if (rev && !caseic) {
      setScrool2(59.5)
    } else if (caseic && !contract) {
      setScrool2(70)
    } else if (contract) {
      setScrool2(100)
    }
  }, [scroll])

  const prevCountRef = useRef()
  useEffect(() => {
    prevCountRef.current = scroll
  })
  const prevScrool = prevCountRef.current

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)
    window.scroll(0, 0)

    return () => window.removeEventListener('resize', cb)
  }, [])

  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  useEffect(() => {
    getLkGetZoom(user?.user?.code).then(({ data }) => {
      dispatch(setAddStatusZoom(data))
      localStorage.setItem('statusZoom', JSON.stringify(data))
    })
  }, [socketData])

  useEffect(() => {
    const checkApp = async () => {
      try {
        const { data } = await getCheckApp(user?.user?.code)
        dispatch(setCheckDownloadedApp(data.result))
        localStorage.setItem('checkDownloadedApp', JSON.stringify(data.result))
      } catch (e) {
        console.log(e)
      }
    }

    checkApp()
  }, [socketData])
  console.log(window)

  useEffect(() => {
    if (menuActive?.['2']?.status !== 'finished') {
      if (typeof window.ym === 'function') {
        //console.log('ENTERLK!');
        window.ym(90102156, 'reachGoal', 'ENTERLK')
      }
    }
    const checkViewMenu = async () => {
      try {
        const { data } = await getViewMenu(user?.user?.code, activeLink)
      } catch (e) {
        console.log(e)
      }
    }

    checkViewMenu()
  }, [])

  useEffect(() => { })

  useEffect(() => {
    document.addEventListener('scroll', function (e) {
      setScrollMob((window.scrollY / 5563) * 100)
      if (bm && !pres) {
        setScroll((window.scrollY / 4263) * 100)
      } else if (pres && !quest) {
        setScroll((window.scrollY / 4163) * 100)
      } else if (quest && !aplic) {
        setScroll((window.scrollY / 4263) * 100)
      } else if (aplic && !gov) {
        setScroll((window.scrollY / 4763) * 100)
      } else if (gov && !rev) {
        setScroll((window.scrollY / 4763) * 100)
      } else if (rev && !caseic) {
        setScroll((window.scrollY / 5463) * 100)
      } else if (caseic && !contract) {
        setScroll((window.scrollY / 6163) * 100)
      } else {
        setScroll((window.scrollY / 5863) * 100)
      }
    })
  }, [bm, pres, quest, aplic, gov, rev, caseic, contract])

  const toGoVipPage = () => {
    dispatch(setIsCheckFromPage(true))
    localStorage.setItem('isCheckFromPage', JSON.stringify(true))
    window.open('/premium', '_blank')
  }

  const viewContent = async (content_id, content_name) => {
    console.log(content_id, content_name)
    const { data } = await getLkViewContent(user?.user?.code, content_id, content_name)
  }

  const handlePlayVideo = () => {
    const player = document.getElementById('ytplayer');
    player.contentWindow.postMessage(JSON.stringify({
      type: 'player:play',
      data: {}
    }), '*');
  }

  return (
    <>
      {statusPayment &&
        persent &&
        statusPayment !== 'prepaid' &&
        statusPayment !== 'paid' &&
        persent <= 4 &&
        endDatePremium &&
        (width > 575 ? (
          <header onClick={toGoVipPage} className={s.header}>
            <img src="/static/lk/background.png" alt="Баннер" />

            <div className={s.header_btn}>Узнать больше</div>
          </header>
        ) : (
          <header className={s.header}>
            <div onClick={toGoVipPage}>
              <img style={{ width: '100%' }} src="/static/lk/background-mobile.png" alt="Баннер" />
            </div>
          </header>
        ))}

      {/* <div className={s.material}>
				<div className={s.wrapper}>
					<div className={s.wrapper_title}>
						<img src='/static/lk/iconDoneBig.svg' alt='Иконка' />
						{width > 575 ? <h4>2. Материалы</h4> : <h4>Шаг 2</h4>}
					</div>

					<h1 className={`${s.title} lk-title`}>Изучи материалы про бизнес</h1>
				</div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
				<div className={s.container}>

					<div className={s.container_text}>
						<h2 className={s.card_title}>Ролик про бизнес-модель</h2>
						<p className={s.card_subtitle}>1 мин. на просмотр</p>
						<p className={s.card_discript}>Посмотри короткий ролик и узнай, как работает бизнес на самозанятых в твоем городе</p>
						<div className={s.card_list}>
							<div className={s.card_item}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
										fill='#002CFB'
									/>
									<path
										d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
										fill='#002CFB'
									/>
									<path
										d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
										fill='#002CFB'
									/>
								</svg>
								<p>Актуальный в 2023-2024</p>
							</div>

							<div className={s.card_item}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
										fill='#002CFB'
									/>
									<path
										d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
										fill='#002CFB'
									/>
									<path
										d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
										fill='#002CFB'
									/>
								</svg>
								<p>Высокодоходный бизнес</p>
							</div>

							<div className={s.card_item}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
										fill='#002CFB'
									/>
									<path
										d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
										fill='#002CFB'
									/>
									<path
										d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
										fill='#002CFB'
									/>
								</svg>
								<p>При поддержке государства</p>
							</div>
						</div>
					</div>

				    <div className={s.video}>
                        <iframe id="ytplayer" type="text/html" width="100%" height="100%" src="https://www.youtube.com/embed/rCwDVlPZIOo?controls=2&loop=1&cc_load_policy=1&cc_lang_pref=ru" frameborder="0" allowFullScreen></iframe>
                    </div>
				</div>
				</div>

				<div className={s.cards}>
					{carData?.map((item) => (
						<Card viewContent={viewContent} key={item.id} {...item} />
					))}cd
				</div>

				<div className={s.footer}>
					<h4>Полезные ссылки</h4>

					<div className={s.links}>
						<div>
							<div className={s.link}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M13.1265 2C14.3136 2 15.4394 2.52729 16.1994 3.43926L19.0729 6.88746C19.6719 7.60632 20 8.51245 20 9.4482V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6C4 3.79086 5.79086 2 8 2H13.1265ZM12 4H8C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V10H17C14.2386 10 12 7.76142 12 5V4ZM17.3966 8H17C15.3431 8 14 6.65685 14 5V4.20083C14.2523 4.32333 14.479 4.49896 14.6629 4.71963L17.3966 8ZM7.5 17C7.5 16.4477 7.94772 16 8.5 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H8.5C7.94772 18 7.5 17.5523 7.5 17ZM7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5H15C15.5523 12.5 16 12.9477 16 13.5C16 14.0523 15.5523 14.5 15 14.5H8.5C7.94772 14.5 7.5 14.0523 7.5 13.5Z'
									/>
								</svg>

								<a
									onClick={() =>
										viewContent(5, 'Лицензионный договор на пользование ПО')
									}
									href='https://skilla.ru/docs/licence_contract.pdf'
									target='_blank'
								>
									Лицензионный договор на пользование ПО
								</a>
							</div>

							<div className={s.link}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M13.1265 2C14.3136 2 15.4394 2.52729 16.1994 3.43926L19.0729 6.88746C19.6719 7.60632 20 8.51245 20 9.4482V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6C4 3.79086 5.79086 2 8 2H13.1265ZM12 4H8C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V10H17C14.2386 10 12 7.76142 12 5V4ZM17.3966 8H17C15.3431 8 14 6.65685 14 5V4.20083C14.2523 4.32333 14.479 4.49896 14.6629 4.71963L17.3966 8ZM7.5 17C7.5 16.4477 7.94772 16 8.5 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H8.5C7.94772 18 7.5 17.5523 7.5 17ZM7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5H15C15.5523 12.5 16 12.9477 16 13.5C16 14.0523 15.5523 14.5 15 14.5H8.5C7.94772 14.5 7.5 14.0523 7.5 13.5Z'
									/>
								</svg>

								<a
									onClick={() =>
										viewContent(6, 'Заключение о стоимости ПО Skilla IS')
									}
									href='https://skilla.ru/docs/cms.pdf'
									target='_blank'
								>
									Заключение о стоимости ПО Skilla IS
								</a>
							</div>
						</div>

						<div>
							<div className={s.link}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M13.1265 2C14.3136 2 15.4394 2.52729 16.1994 3.43926L19.0729 6.88746C19.6719 7.60632 20 8.51245 20 9.4482V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6C4 3.79086 5.79086 2 8 2H13.1265ZM12 4H8C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V10H17C14.2386 10 12 7.76142 12 5V4ZM17.3966 8H17C15.3431 8 14 6.65685 14 5V4.20083C14.2523 4.32333 14.479 4.49896 14.6629 4.71963L17.3966 8ZM7.5 17C7.5 16.4477 7.94772 16 8.5 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H8.5C7.94772 18 7.5 17.5523 7.5 17ZM7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5H15C15.5523 12.5 16 12.9477 16 13.5C16 14.0523 15.5523 14.5 15 14.5H8.5C7.94772 14.5 7.5 14.0523 7.5 13.5Z'
									/>
								</svg>

								<a
									onClick={() =>
										viewContent(7, 'Свидетельство о регистрации ПО Skilla IS')
									}
									href='https://skilla.ru/svidetelstvo.html'
									target='_blank'
								>
									Свидетельство о регистрации ПО Skilla IS
								</a>
							</div>

							<div className={s.link}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M13.1265 2C14.3136 2 15.4394 2.52729 16.1994 3.43926L19.0729 6.88746C19.6719 7.60632 20 8.51245 20 9.4482V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6C4 3.79086 5.79086 2 8 2H13.1265ZM12 4H8C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V10H17C14.2386 10 12 7.76142 12 5V4ZM17.3966 8H17C15.3431 8 14 6.65685 14 5V4.20083C14.2523 4.32333 14.479 4.49896 14.6629 4.71963L17.3966 8ZM7.5 17C7.5 16.4477 7.94772 16 8.5 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H8.5C7.94772 18 7.5 17.5523 7.5 17ZM7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5H15C15.5523 12.5 16 12.9477 16 13.5C16 14.0523 15.5523 14.5 15 14.5H8.5C7.94772 14.5 7.5 14.0523 7.5 13.5Z'
									/>
								</svg>

								<a
									onClick={() =>
										viewContent(8, 'Свидетельство о регистрации ТМ Skilla')
									}
									href='https://skilla.ru/docs/sv-reg.pdf'
									target='_blank'
								>
									Свидетельство о регистрации ТМ Skilla
								</a>
							</div>
						</div>

						<div>
							<div className={s.link}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M13.1265 2C14.3136 2 15.4394 2.52729 16.1994 3.43926L19.0729 6.88746C19.6719 7.60632 20 8.51245 20 9.4482V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6C4 3.79086 5.79086 2 8 2H13.1265ZM12 4H8C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V10H17C14.2386 10 12 7.76142 12 5V4ZM17.3966 8H17C15.3431 8 14 6.65685 14 5V4.20083C14.2523 4.32333 14.479 4.49896 14.6629 4.71963L17.3966 8ZM7.5 17C7.5 16.4477 7.94772 16 8.5 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H8.5C7.94772 18 7.5 17.5523 7.5 17ZM7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5H15C15.5523 12.5 16 12.9477 16 13.5C16 14.0523 15.5523 14.5 15 14.5H8.5C7.94772 14.5 7.5 14.0523 7.5 13.5Z'
									/>
								</svg>

								<a
									onClick={() => viewContent(9, 'Правила брендирования офиса')}
									href='https://skilla.ru/oferta_brand/'
									target='_blank'
								>
									Правила брендирования офиса
								</a>
							</div>

							<div className={s.link}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M13.1265 2C14.3136 2 15.4394 2.52729 16.1994 3.43926L19.0729 6.88746C19.6719 7.60632 20 8.51245 20 9.4482V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6C4 3.79086 5.79086 2 8 2H13.1265ZM12 4H8C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V10H17C14.2386 10 12 7.76142 12 5V4ZM17.3966 8H17C15.3431 8 14 6.65685 14 5V4.20083C14.2523 4.32333 14.479 4.49896 14.6629 4.71963L17.3966 8ZM7.5 17C7.5 16.4477 7.94772 16 8.5 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H8.5C7.94772 18 7.5 17.5523 7.5 17ZM7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5H15C15.5523 12.5 16 12.9477 16 13.5C16 14.0523 15.5523 14.5 15 14.5H8.5C7.94772 14.5 7.5 14.0523 7.5 13.5Z'
									/>
								</svg>

								<a
									onClick={() => viewContent(12, 'ИТ аккредитация')}
									href='https://skilla.ru/itakkreditacia.pdf'
									target='_blank'
								>
									ИТ аккредитация
								</a>
							</div>
						</div>

						<div>
							<div className={s.link}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M13.1265 2C14.3136 2 15.4394 2.52729 16.1994 3.43926L19.0729 6.88746C19.6719 7.60632 20 8.51245 20 9.4482V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6C4 3.79086 5.79086 2 8 2H13.1265ZM12 4H8C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V10H17C14.2386 10 12 7.76142 12 5V4ZM17.3966 8H17C15.3431 8 14 6.65685 14 5V4.20083C14.2523 4.32333 14.479 4.49896 14.6629 4.71963L17.3966 8ZM7.5 17C7.5 16.4477 7.94772 16 8.5 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H8.5C7.94772 18 7.5 17.5523 7.5 17ZM7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5H15C15.5523 12.5 16 12.9477 16 13.5C16 14.0523 15.5523 14.5 15 14.5H8.5C7.94772 14.5 7.5 14.0523 7.5 13.5Z'
									/>
								</svg>

								<a
									onClick={() => viewContent(10, 'Регистрация в реестре IT')}
									href='https://reestr.digital.gov.ru/reestr/1284748/'
									target='_blank'
								>
									Регистрация в реестре IT
								</a>
							</div>

							<div className={`${s.link} ${s.link_last}`}>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										fill-rule='evenodd'
										clip-rule='evenodd'
										d='M13.1265 2C14.3136 2 15.4394 2.52729 16.1994 3.43926L19.0729 6.88746C19.6719 7.60632 20 8.51245 20 9.4482V18C20 20.2091 18.2091 22 16 22H8C5.79086 22 4 20.2091 4 18V6C4 3.79086 5.79086 2 8 2H13.1265ZM12 4H8C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V10H17C14.2386 10 12 7.76142 12 5V4ZM17.3966 8H17C15.3431 8 14 6.65685 14 5V4.20083C14.2523 4.32333 14.479 4.49896 14.6629 4.71963L17.3966 8ZM7.5 17C7.5 16.4477 7.94772 16 8.5 16H15C15.5523 16 16 16.4477 16 17C16 17.5523 15.5523 18 15 18H8.5C7.94772 18 7.5 17.5523 7.5 17ZM7.5 13.5C7.5 12.9477 7.94772 12.5 8.5 12.5H15C15.5523 12.5 16 12.9477 16 13.5C16 14.0523 15.5523 14.5 15 14.5H8.5C7.94772 14.5 7.5 14.0523 7.5 13.5Z'
									/>
								</svg>

								<a
									onClick={() => viewContent(11, 'Переход на skilla.ru')}
									href='https://skilla.ru/business'
									target='_blank'
								>
									Skilla.ru
								</a>
							</div>
						</div>
					</div>
				</div>
			</div> */}

      <div style={{ display: 'none' }} className={s.progress_mob}>
        <div className={s.linemob}>
          <div style={{ width: `${scrollMob}%` }} className={s.linemob_fill}></div>
          <div style={{ width: `${scrool2 - 4}%` }} className={s.linemob_fill}></div>
        </div>
      </div>
      {/*  <div
        style={{ display: 'none' }}
        className={`${s.container_progress} ${scroll > 2.5 ? s.container_progress_view : ''}`}
      >
        <div className={s.progress_box}>
          <div className={s.progressbar}>
            <div className={`${s.check} ${s.check_bp}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={bm ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Бизнес-модель</p>
            </div>
            <div className={`${s.check} ${s.check_pres}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={pres ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Презентация</p>
            </div>
            <div className={`${s.check} ${s.check_qustion}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={quest ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Вопрос-ответ</p>
            </div>
            <div className={`${s.check} ${s.check_aplic}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={aplic ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Программное обеспечение</p>
            </div>
            <div className={`${s.check} ${s.check_business}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={gov ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Государственная поддержка</p>
            </div>
            <div className={`${s.check} ${s.check_review}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={rev ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Видео-отзывы</p>
            </div>

            <div className={`${s.check} ${s.check_case}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={caseic ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Кейсы предпринимателей</p>
            </div>

            <div className={`${s.check} ${s.check_contract}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={contract ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Договор и гарантии</p>
            </div>
            <div className={s.line}>
              <div style={{ width: `${scroll * 1.15}%` }} className={s.line_green}></div>
              <div style={{ width: `${scrool2 * 1.15}%`, zIndex: '30' }} className={s.line_green2}></div>
            </div>
          </div>
        </div>
      </div> */}
      <div className={s.material}>
        <div className={s.wrapper}>
          <div className={s.wrapper_title}>
            <img src="/static/lk/iconDoneBig.svg" alt="Иконка" />
            {width > 575 ? <h4>2. Материалы</h4> : <h4>Шаг 2</h4>}
          </div>

          <h1 className={`${s.title} lk-title`}>Изучи материалы</h1>
        </div>

        {/*   <div style={{ display: 'none' }} className={s.progress_box}>
          <div className={s.progressbar}>
            <div className={`${s.check} ${s.check_bp}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={bm ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Бизнес-модель</p>
            </div>
            <div className={`${s.check} ${s.check_pres}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={pres ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Презентация</p>
            </div>
            <div className={`${s.check} ${s.check_qustion}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={quest ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Вопрос-ответ</p>
            </div>
            <div className={`${s.check} ${s.check_aplic}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={aplic ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Программное обеспечение</p>
            </div>
            <div className={`${s.check} ${s.check_business}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={gov ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Государственная поддержка</p>
            </div>
            <div className={`${s.check} ${s.check_review}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={rev ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Видео-отзывы</p>
            </div>

            <div className={`${s.check} ${s.check_case}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={caseic ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Кейсы предпринимателей</p>
            </div>

            <div className={`${s.check} ${s.check_contract}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24" fill="none">
                <g clip-path="url(#clip0_166_752)">
                  <path
                    d="M13.3574 24C19.9848 24 25.3574 18.6274 25.3574 12C25.3574 5.37258 19.9848 0 13.3574 0C6.73 0 1.35742 5.37258 1.35742 12C1.35742 18.6274 6.73 24 13.3574 24Z"
                    fill={contract ? '#28A879' : '#E5ECF8'}
                  />
                  <path
                    d="M7.35742 12.4286L8.64313 11.1429L11.6432 14.1429L18.0718 7.71436L19.3575 9.00006L11.6432 16.7144L7.35742 12.4286Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_166_752">
                    <rect width="25" height="24" fill="white" transform="translate(0.785156)" />
                  </clipPath>
                </defs>
              </svg>
              <p>Договор и гарантии</p>
            </div>
            <div className={s.line}>
              <div style={{ width: `${scroll * 1.15}%` }} className={s.line_green}></div>
              <div style={{ width: `${scrool2 * 1.15}%`, zIndex: '30' }} className={s.line_green2}></div>
            </div>
          </div>
        </div> */}

        <div className={`${s.container} ${s.container_videotop}`}>
          <div className={s.block_text}>
            <h2 className={s.title_block}>
              <span>Skilla Работа</span> — сервис, соединяющий заказчиков и исполнителей
            </h2>
            <ul className={`${s.list} ${s.list_video}`}>
              <li className={s.item}>
                <p className={s.number}>1.</p>
                <p className={s.text}>Компании размещают заказы</p>
              </li>

              <li className={s.item}>
                <p className={s.number}>2.</p>
                <p className={s.text}>Исполнители их выполняют</p>
              </li>

              <li className={s.item}>
                <p className={s.number}>3.</p>
                <p className={s.text}>
                  Предприниматели-посредники получают<br></br> до 50% комиссии за организацию процесса
                </p>
              </li>
            </ul>

            <a
              onClick={() => viewContent(14, 'Видео')}
              target="_blank"
              href="https://rutube.ru/video/bfe36022288e54d74c1d89a040d912c3/"
              className={`${s.button} ${s.button_video}`}
            >
              Смотреть на RUTUBE
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M6.34315 6.34313V8.33717L14.2415 8.34425L5.63604 16.9497L7.05025 18.3639L15.6557 9.75846L15.6628 17.6568H17.6569V6.34313H6.34315Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
          <div className={s.block_video}>
            <div className={s.video}>
              <div
                onClick={() => {
                  viewContent(14, 'Видео')
                  setPlay('1')
                  handlePlayVideo()
                }}
                className={s.videoover}
                style={{ display: play === '1' ? 'none' : '' }}
              ></div>
              <iframe
                ref={videoRef}
                id="ytplayer"
                type="text/html"
                width="100%"
                height="100%"
                src={`https://rutube.ru/play/embed/bfe36022288e54d74c1d89a040d912c3/`}  /* https://www.youtube.com/embed/e0S5QWIEc_Q?autoplay=${play}&controls=2&loop=1&cc_load_policy=1&cc_lang_pref=ru */
                frameborder="0"
                allow="clipboard-write; autoplay"
                webkitAllowFullScreen
                mozallowfullscreen
                allowFullScreen
              ></iframe>
            </div>
          </div>


          <a
            onClick={() => viewContent(14, 'Видео')}
            target="_blank"
            href="https://www.youtube.com/watch?v=e0S5QWIEc_Q"
            className={s.button_another}
          >
            Смотреть на Youtube
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path
                d="M6.34315 6.34313V8.33717L14.2415 8.34425L5.63604 16.9497L7.05025 18.3639L15.6557 9.75846L15.6628 17.6568H17.6569V6.34313H6.34315Z"
                fill="white"
              />
            </svg>
          </a>

          <div className={s.logos}>
            <div className={`${s.logo} ${s.logo_dl}`}></div>

            <div className={`${s.logo} ${s.logo_mv}`}></div>

            <div className={`${s.logo} ${s.logo_sb}`}></div>

            <div className={`${s.logo} ${s.logo_pr}`}></div>

            <div className={`${s.logo} ${s.logo_av}`}></div>

            <div className={`${s.logo} ${s.logo_fi}`}></div>

            <div className={`${s.logo} ${s.logo_dns}`}></div>

            <div className={`${s.logo} ${s.logo_mag}`}></div>
          </div>
        </div>

        <div className={`${s.container} ${s.container_present}`}>
          <img className={s.mokup} src="/static/lk/mokup_present.png"></img>
          <div className={s.box}>
            <div className={`${s.block_present}`}>
              <h2 className={`${s.title_block} ${s.title_present}`}>
                Узнай о возможностях в <span>презентации</span>
              </h2>
              <p className={`${s.text} ${s.text_present}`}>
                Инвестиции в Skilla — это умеренные риски и высокий доход
              </p>
            </div>

            <a
              onClick={() => viewContent(1, 'Презентация')}
              href="https://skilla.ru/skillaprezent2022.pdf"
              target="_blank"
              className={`${s.button} ${s.button_present}`}
            >
              Смотреть PDF
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M6.34315 6.34313V8.33717L14.2415 8.34425L5.63604 16.9497L7.05025 18.3639L15.6557 9.75846L15.6628 17.6568H17.6569V6.34313H6.34315Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>

        <div className={`${s.container} ${s.container_question}`}>

          <div className={s.box}>
            <div className={`${s.block_question}`}>
              <h2 className={s.title_block}>
                Смотри, чем ты будешь заниматься на площадке <span>Скилла:</span>
              </h2>

              <div className={`${s.block_video} ${s.block_video_mob}`}>
                <div className={s.video}>
                  <div
                    onClick={() => {
                      viewContent(14, 'Видео')
                      setPlay('1')
                      handlePlayVideo()
                    }}
                    className={s.videoover}
                    style={{ display: play === '1' ? 'none' : '' }}
                  ></div>
                  <iframe
                    ref={videoRef}
                    id="ytplayer"
                    type="text/html"
                    width="100%"
                    height="100%"
                    src={`https://rutube.ru/play/embed/12a4820d667ce5ab3e8c89963e19dbc8/`}
                    frameborder="0"
                    allow="clipboard-write; autoplay"
                    webkitAllowFullScreen
                    mozallowfullscreen
                    allowFullScreen
                  ></iframe>
                </div>
              </div>

              <ul className={`${s.list} ${s.list_question}`}>
                <li className={s.item}>
                  <p className={s.number}>1.</p>
                  <p className={s.text}>Привлекать исполнителей</p>
                </li>

                <li className={s.item}>
                  <p className={s.number}>2.</p>
                  <p className={s.text}>Обучать самозанятости</p>
                </li>

                <li className={s.item}>
                  <p className={s.number}>3.</p>
                  <p className={s.text}>Регистрировать в Skilla Работа</p>
                </li>

                <li className={s.item}>
                  <p className={s.number}>4.</p>
                  <p className={s.text}>Помогать выполнять заказы</p>
                </li>

                <li className={s.item}>
                  <p className={s.number}>5.</p>
                  <p className={s.text}>Решать вопросы с оплатой и т.д</p>
                </li>
              </ul>
            </div>


            <a
              onClick={() => viewContent(3, 'Вопрос-ответ')}
              href="https://npd.nalog.ru/aggregators/"
              target="_blank"
              className={`${s.button} ${s.button_question}`}
            >
              Скилла в реестре Налоговой РФ
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M6.34315 6.34313V8.33717L14.2415 8.34425L5.63604 16.9497L7.05025 18.3639L15.6557 9.75846L15.6628 17.6568H17.6569V6.34313H6.34315Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>

          <div className={`${s.block_video} ${s.block_video_2}`}>
            <div style={{ margin: '0' }} className={s.video}>
              <div
                onClick={() => {
                  viewContent(14, 'Видео')
                  setPlay('1')
                  handlePlayVideo()
                }}
                className={s.videoover}
                style={{ display: play === '1' ? 'none' : '' }}
              ></div>
              <iframe
                ref={videoRef}
                id="ytplayer"
                type="text/html"
                width="100%"
                height="100%"
                src={`https://rutube.ru/play/embed/12a4820d667ce5ab3e8c89963e19dbc8/`}
                frameborder="0"
                allow="clipboard-write; autoplay"
                webkitAllowFullScreen
                mozallowfullscreen
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>

        <div className={`${s.container} ${s.container_aplication}`}>
          <img className={`${s.mokup_3}`} src="/static/lk/mokup_3.png"></img>
          <img className={`${s.mokup_3mob}`} src="/static/lk/material/backgroundmob_aplication.png"></img>

          <div className={s.block}>
            <h2 className={`${s.title_block} ${s.title_block_aplication}`}>
              <span>Зарабатывай</span> в программе стоимостью 60 млн. рублей
            </h2>
            <div className={s.badge}>
              <img src="/static/lk/bage.png"></img>
              <div className={s.badge__container}>
                <h4>Skilla IS включено в реестр Российского ПО</h4>
                <p>Сведения включены в единый реестр российских программ для ЭВМ и баз данных </p>
              </div>
            </div>
            <ul className={s.list}>
              <li className={s.item}>
                <p className={s.number}>1.</p>
                <p className={s.text}>Подключение к IT-платформе Skilla IS</p>
              </li>

              <li className={s.item}>
                <p className={s.number}>2.</p>
                <p className={s.text}>Интеграция со Skilla Работа (IOS, Android)</p>
              </li>

              <li className={s.item}>
                <p className={s.number}>3.</p>
                <p className={s.text}>Контактный центр для обработки звонков</p>
              </li>

              <li className={s.item}>
                <p className={s.number}>4.</p>
                <p className={s.text}>Десятки личных кабинетов</p>
              </li>

              <li className={s.item}>
                <p className={s.number}>5.</p>
                <p className={s.text}>Ежедневное обновление онлайн</p>
              </li>
            </ul>

            <a
              onClick={() => viewContent(6, 'Заключение о стоимости ПО Skilla IS')}
              href="https://skilla.ru/docs/cms.pdf"
              target="_blank"
              className={`${s.button} ${s.button_aplication}`}
            >
              Заключение о стоимости ПО
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M6.34315 6.34313V8.33717L14.2415 8.34425L5.63604 16.9497L7.05025 18.3639L15.6557 9.75846L15.6628 17.6568H17.6569V6.34313H6.34315Z"
                  fill="white"
                />
              </svg>
            </a>

            <div className={s.logos_aplication}>
              <img src="/static/lk/logo_fns.png" className={s.logo_aplication}></img>
              <img src="/static/lk/logo_contur.png" className={s.logo_aplication}></img>
              <img src="/static/lk/logo_tinkoff.png" className={s.logo_aplication}></img>
              <img src="/static/lk/logo_tochka.png" className={s.logo_aplication}></img>
            </div>
          </div>
        </div>

        <div className={`${s.container} ${s.container_gov}`}>
          <img className={`${s.mokup_gov}`} src="/static/lk/material/mokup_gov.png"></img>

          <div className={s.block}>
            <h2 className={`${s.title_block} ${s.title_block_gov}`}>
              Запусти <span>надёжный бизнес</span> при поддержке государства
            </h2>
            <ul className={s.list}>
              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Помощь самозанятым через национальные проекты</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Компенсация от правительства</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Автоматическая оплата налогов за самозанятых</p>
              </li>
            </ul>

            <img className={`${s.mokup_govmob}`} src="/static/lk/material/backgroundmob_gov.png"></img>

            <div className={s.buttons}>
              <a
                onClick={() => viewContent(7, 'Свидетельство о регистрации ПО Skilla IS')}
                href="https://skilla.ru/svidetelstvo.html"
                target="_blank"
                className={`${s.button} ${s.button_gov}`}
              >
                Свидетельство ПО
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6.34315 6.34313V8.33717L14.2415 8.34425L5.63604 16.9497L7.05025 18.3639L15.6557 9.75846L15.6628 17.6568H17.6569V6.34313H6.34315Z"
                    fill="#002CFB"
                  />
                </svg>
              </a>

              <a
                onClick={() => viewContent(8, 'Свидетельство о регистрации ТМ Skilla')}
                href="https://skilla.ru/docs/sv-reg.pdf"
                target="_blank"
                className={`${s.button} ${s.button_gov}`}
              >
                Свидетельство ТМ
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6.34315 6.34313V8.33717L14.2415 8.34425L5.63604 16.9497L7.05025 18.3639L15.6557 9.75846L15.6628 17.6568H17.6569V6.34313H6.34315Z"
                    fill="#002CFB"
                  />
                </svg>
              </a>

              <a
                onClick={() => viewContent(10, 'Регистрация в реестре IT')}
                href="https://reestr.digital.gov.ru/reestr/1284748/"
                target="_blank"
                className={`${s.button} ${s.button_gov}`}
              >
                Регистрация IT
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M6.34315 6.34313V8.33717L14.2415 8.34425L5.63604 16.9497L7.05025 18.3639L15.6557 9.75846L15.6628 17.6568H17.6569V6.34313H6.34315Z"
                    fill="#002CFB"
                  />
                </svg>
              </a>
            </div>

            <div className={s.logos_gov}>
              <img src="/static/lk/logo_fns2.png" className={s.logo_gov}></img>
              <img src="/static/lk/logo_mincom.png" className={s.logo_gov}></img>
              <img src="/static/lk/logo_pat.png" className={s.logo_gov}></img>
            </div>
          </div>
        </div>

        <div className={`${s.container} ${s.container_video}`}>
          <img className={`${s.mokup_videomob}`} src="/static/lk/material/backgroundmob_video.png"></img>
          <img className={`${s.mokup_video}`} src="/static/lk/mokup_video.png "></img>
          <div className={s.blockvid}>
            <h2 className={`${s.title_block} ${s.title_video}`}>
              <span>Видео-отзывы</span> о партнерстве со Skilla
            </h2>
            <ul className={s.list}>
              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>25+ видео-интервью</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Ответы на актуальные вопросы</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Истории развития партнеров</p>
              </li>
            </ul>

            <a
              onClick={() => viewContent(4, 'Отзывы')}
              href="https://skilla.ru/business/heroes/interview"
              target="_blank"
              className={`${s.button} ${s.button_question}`}
            >
              Смотреть на Youtube
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M6.34315 6.34313V8.33717L14.2415 8.34425L5.63604 16.9497L7.05025 18.3639L15.6557 9.75846L15.6628 17.6568H17.6569V6.34313H6.34315Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>

        <div className={`${s.container} ${s.container_business}`}>
          <div className={`${s.block} ${s.block_bussines}`}>
            <h2 className={`${s.title_block} ${s.title_business}`}>
              Получи <span>бизнес «под ключ»</span> даже без опыта
            </h2>
            <ul className={s.list}>
              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Очное и онлайн обучение</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Личный ассистент и команда поддержки</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Доступ к базе заказчиков</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Бухгалтер и юрист </p>
              </li>
            </ul>
            <div className={s.box_office}>
              <img
                className={`${s.mokup_businessmob}`}
                src="/static/lk/material/backgroundmob_office.png"
              ></img>
            </div>

            <a
              onClick={() => viewContent(9, 'Правила брендирования офиса')}
              href="https://skilla.ru/oferta_brand/"
              target="_blank"
              className={`${s.button} ${s.button_business}`}
            >
              Правила брендирования офиса
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M6.34315 6.34313V8.33717L14.2415 8.34425L5.63604 16.9497L7.05025 18.3639L15.6557 9.75846L15.6628 17.6568H17.6569V6.34313H6.34315Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
          <div className={s.gallery}>
            <div className={s.photo}>
              <a
                className={s.photo__link}
                data-fancybox="gallery"
                href="/static/lk/material/galleryBig_1.png"
              >
                <div className={s.photo__overlay}>
                  <div className={s.photo__icon}></div>
                </div>
                <img className="modal-img" src="/static/lk/material/gallery_1.png" alt="изображение" />
              </a>
            </div>

            <div className={s.photo}>
              <a
                className={s.photo__link}
                data-fancybox="gallery"
                href="/static/lk/material/galleryBig_2.png"
              >
                <div className={s.photo__overlay}>
                  <div className={s.photo__icon}></div>
                </div>
                <img className="modal-img" src="/static/lk/material/gallery_2.png" alt="изображение" />
              </a>
            </div>

            <div className={s.photo}>
              <a
                className={s.photo__link}
                data-fancybox="gallery"
                href="/static/lk/material/galleryBig_3.png"
              >
                <div className={s.photo__overlay}>
                  <div className={s.photo__icon}></div>
                </div>
                <img className="modal-img" src="/static/lk/material/gallery_3.png" alt="изображение" />
              </a>
            </div>

            <div className={s.photo}>
              <a
                className={s.photo__link}
                data-fancybox="gallery"
                href="/static/lk/material/galleryBig_4.png"
              >
                <div className={s.photo__overlay}>
                  <div className={s.photo__icon}></div>
                </div>
                <img className="modal-img" src="/static/lk/material/gallery_4.png" alt="изображение" />
              </a>
            </div>

            <div className={s.photo}>
              <a
                className={s.photo__link}
                data-fancybox="gallery"
                href="/static/lk/material/galleryBig_5.png"
              >
                <div className={s.photo__overlay}>
                  <div className={s.photo__icon}></div>
                </div>
                <img className="modal-img" src="/static/lk/material/gallery_5.png" alt="изображение" />
              </a>
            </div>
          </div>
          <img className={`${s.mokup_business}`} src="/static/lk/mokup_office.png"></img>
        </div>

        <div className={`${s.container} ${s.container_case}`}>
          <img className={`${s.mokup_casemob}`} src="/static/lk/material/backgroundmob_case.png"></img>
          <div className={s.block_case}>
            <h2 className={`${s.title_block} ${s.title_case}`}>
              Прибыльность бизнес-модели доказана на деле
            </h2>
            <ul className={`${s.list} ${s.list_case}`}>
              <li className={`${s.item}`}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#71869D" />
                </svg>
                <p className={`${s.text} ${s.text_case}`}>Сообщество из 350 предпринимателей</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#71869D" />
                </svg>
                <p className={`${s.text} ${s.text_case}`}>Первый партнер с 2014 года</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#71869D" />
                </svg>
                <p className={`${s.text} ${s.text_case}`}>80% начали первый бизнес со Skilla</p>
              </li>
            </ul>

            <a
              onClick={() => viewContent(2, 'Кейсы из жизни')}
              href="https://skilla.ru/business/partners"
              target="_blank"
              className={`${s.button} ${s.button_question}`}
            >
              Смотреть кейсы
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M6.34315 6.34313V8.33717L14.2415 8.34425L5.63604 16.9497L7.05025 18.3639L15.6557 9.75846L15.6628 17.6568H17.6569V6.34313H6.34315Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>

        <div className={`${s.container} ${s.container_advantages}`}>
          <img className={`${s.mokup_advantages}`} src="/static/lk/material/background_advantage.png"></img>
          <div className={s.block}>
            <h2 className={`${s.title_block} ${s.title_advantages}`}>
              <span>7 преимуществ</span> бизнеса Skilla Работа для тебя
            </h2>
            <ul className={s.list}>
              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Быстрое обучение</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Автоматизация IT-инструментами</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Гарантия дохода</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Огромный рынок B2B</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Доход не ограничен радиусом точки</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Посреднический бизнес без рисков неоплаты</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Поддержка государства</p>
              </li>
            </ul>

            <img className={`${s.mokup_advantagesmob}`} src="/static/lk/material/backgroundmob_ad.png"></img>
          </div>
        </div>

        <div className={`${s.container} ${s.container_events}`}>
          <div className={s.photos}>
            <div className={s.photo_big}>
              <a
                className={s.photo__link}
                data-fancybox="gallery2"
                href="/static/lk/material/gallery2Big_1.jpeg"
              >
                <div className={s.photo__overlay}>
                  <div className={s.photo__icon}></div>
                </div>
                <img className="modal-img" src="/static/lk/material/gallery2_1.png" alt="изображение" />
              </a>
            </div>

            <div className={s.gallery}>
              <div className={s.photo_event}>
                <a
                  className={s.photo__link}
                  data-fancybox="gallery2"
                  href="/static/lk/material/gallery2Big_2.png"
                >
                  <div className={s.photo__overlay}>
                    <div className={s.photo__icon}></div>
                  </div>
                  <img className="modal-img" src="/static/lk/material/gallery2_2.png" alt="изображение" />
                </a>
              </div>

              <div className={s.photo_event}>
                <a
                  className={s.photo__link}
                  data-fancybox="gallery2"
                  href="/static/lk/material/gallery2Big_3.png"
                >
                  <div className={s.photo__overlay}>
                    <div className={s.photo__icon}></div>
                  </div>
                  <img className="modal-img" src="/static/lk/material/gallery2_3.png" alt="изображение" />
                </a>
              </div>

              <div className={s.photo_event}>
                <a
                  className={s.photo__link}
                  data-fancybox="gallery2"
                  href="/static/lk/material/gallery2Big_4.png"
                >
                  <div className={s.photo__overlay}>
                    <div className={s.photo__icon}></div>
                  </div>
                  <img className="modal-img" src="/static/lk/material/gallery2_4.png" alt="изображение" />
                </a>
              </div>

              <div className={s.photo_event}>
                <a
                  className={s.photo__link}
                  data-fancybox="gallery2"
                  href="/static/lk/material/gallery2Big_5.png"
                >
                  <div className={s.photo__overlay}>
                    <div className={s.photo__icon}></div>
                  </div>
                  <img className="modal-img" src="/static/lk/material/gallery2_5.png" alt="изображение" />
                </a>
              </div>
            </div>
          </div>
          <div className={s.block}>
            <h2 className={`${s.title_block} ${s.title_events}`}>
              Стань частью <span>сообщества предпринимателей</span>
            </h2>
            <ul className={`${s.list} ${s.list_events}`}>
              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>5 бизнес-завтраков в год</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>4 поездки заграницу и по России</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Ежемесячная корпоративная баня</p>
              </li>
            </ul>
            <div className={s.mokup_events}></div>
            <a
              onClick={() => {
                dispatch(setAddActiveLink('calendar'))

                localStorage.setItem('activeLink', JSON.stringify('calendar'))
              }}
              href="/lk"
              target="_blank"
              className={`${s.button} ${s.button_events}`}
            >
              Смотреть фото с мероприятий
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M6.34315 6.34313V8.33717L14.2415 8.34425L5.63604 16.9497L7.05025 18.3639L15.6557 9.75846L15.6628 17.6568H17.6569V6.34313H6.34315Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>

        <div className={`${s.container} ${s.container_license}`}>
          <img className={`${s.mokup_license}`} src="/static/lk/material/mokup_license.png"></img>
          <div className={s.block}>
            <h2 className={`${s.title_block} ${s.title_license}`}>
              Заключи <span>на 100% официальный</span> договор
            </h2>
            <ul className={s.list}>
              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Лицензионный договор партнерства</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Соответствует законодательству</p>
              </li>

              <li className={s.item}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path d="M4.5 11L3 12.6084L9 19L21 7.5L19.5 6L9 16L4.5 11Z" fill="#002CFB" />
                </svg>
                <p className={s.text}>Гарантия возврата средств</p>
              </li>
            </ul>
            <img className={`${s.mokup_licensemob}`} src="/static/lk/material/mokup_license.png"></img>
            <a
              onClick={() => viewContent(5, 'Лицензионный договор на пользование ПО')}
              href="https://skilla.ru/license"
              target="_blank"
              className={`${s.button} ${s.button_license}`}
            >
              Читать договор
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M6.34315 6.34313V8.33717L14.2415 8.34425L5.63604 16.9497L7.05025 18.3639L15.6557 9.75846L15.6628 17.6568H17.6569V6.34313H6.34315Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </>
  )
})

export default Material
