import { useState, useEffect, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { saveRequest, saveAnketa, getCities, getUserInfo } from '../Api'
import { userActions } from '../store/reducer/userSlice'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import {
  setAddStep,
  setAddCompleteForm,
  setAddSendRequest,
  setAddNameBusiness,
  setAddMessage,
  setAddEmailBusiness,
} from '../store/reducer/form/slice'
import { setAddOnClickForm } from '../store/reducer/main/slice'
import RadioGroup from './RadioGroup'

const FormModal = memo(function FormModal({
  isFormSubmit,
  setIsFormSubmit,
  setAddOnClick,
  activeTarget,
  nameBusiness,
  reqCode,
  messageWhy,
  isExpPeoples,
  isExpBusiness,
  page,
  emailBusiness,
  type,
}) {
  const userCity = useSelector(({ user }) => user.city)
  const [modalMenu, setModalMenu] = useState(false)
  const [cities, setCities] = useState(null)
  const [isCitiesLoading, setIsCitiesLoading] = useState(false)
  const [width, setWidth] = useState(window?.innerWidth)

  const [step, setStep] = useState('')

  const dispatch = useDispatch()
  const navige = useNavigate()

  const showModalMenu = () => {
    setModalMenu(true)
  }

  const closeModal = () => {
    setIsFormSubmit(false)
    dispatch(setAddCompleteForm(false))
    setAddOnClick(false)
    dispatch(setAddOnClickForm(false))
    dispatch(setAddSendRequest(false))

    step === 4 && navige('/business')
  }

  useEffect(() => {
    getUserInfo()
      .then((response) => dispatch(userActions.changeCity(response.data.city)))

      .catch((e) => {
        console.log(e.message)
        dispatch(userActions.changeCity('Москва'))
      })
  }, [])

  useEffect(() => {
    setStep(3)
  }, [isFormSubmit])

  useEffect(() => {
    getCities()
      .then((response) => {
        setCities(response.data)
        setIsCitiesLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }, [])

  useEffect(() => {
    const handleClickModal = (e) => {
      if (e.target.classList.value.includes('modal__content')) {
        setModalMenu(false)
      }

      if (e.target.classList.value.includes('modal---')) {
        closeModal()
        dispatch(setAddOnClick(false))
      }
    }

    document.body.addEventListener('click', handleClickModal)

    return () => document.body.removeEventListener('click', handleClickModal)
  }, [])

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)
    window.scroll(0, 0)

    return () => window.removeEventListener('resize', cb)
  }, [])

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        if (reqCode) {
          switch (step) {
            case 1:
              saveRequest('name', nameBusiness, reqCode)
              saveRequest('city', userCity, reqCode)
              break
            case 2:
              saveRequest('email', emailBusiness, reqCode)
              break
            case 3:
              saveAnketa(isExpPeoples, isExpBusiness, messageWhy, reqCode)
              break
          }
        }

        if (step === 1) {
          if (cities.filter((item) => item.name === userCity)[0]) setStep(step + 1)
          else dispatch(userActions.changeCity(''))
          return
        }

        setStep(step + 1)
      }}
      // className={type === 'calculator' ? 'modal---calc': 'modal---'}>

      className={classNames('modal---', {
        'modal---calc': type === 'calculator',
        'modal---diagnostics': type === 'diagnostics',
      })}
    >
      <div className="modal__content">
        <div className="modal__cross" onClick={closeModal}>
          {type !== 'diagnostics' && <span className="cross icon"></span>}
        </div>
        <h2 className="modal__title">{/* {step === 4 ? 'Хороший ход!' : `Шаг ${step} из 3`} */}Анкета</h2>

        {step === 1 && (
          <>
            <p className="modal__text">
              Мы уже получили заявку и свяжемся с тобой, чтобы ускорить процесс, ответь на вопросы
            </p>
            <input
              type="text"
              required
              maxLength="24"
              minLength="2"
              placeholder="Твое имя"
              value={nameBusiness}
              onChange={(e) => dispatch(setAddNameBusiness(e.target.value))}
              className="modal__input modal__input_step1"
            />

            <div className="modal__input_city modal__input_city_step1">
              <input
                type="text"
                required
                maxLength="20"
                minLength="2"
                value={userCity}
                onClick={showModalMenu}
                onChange={(e) => dispatch(userActions.changeCity(e.target.value))}
                className="modal__input"
              />

              {isCitiesLoading && (
                <div className="modal__input_menu">
                  <div className="lds-dual-ring"></div>
                </div>
              )}

              {!isCitiesLoading && (
                <div className={modalMenu ? 'modal__input_menu active' : 'modal__input_menu'}>
                  {cities &&
                  cities.filter((item) =>
                    item.name.toLowerCase().includes(userCity && userCity.toLowerCase()),
                  )?.length ? (
                    cities &&
                    cities
                      .filter((item) => item.name.toLowerCase().includes(userCity && userCity.toLowerCase()))
                      .map((city, index) => (
                        <p
                          key={index}
                          className="modal__input_city-item"
                          onClick={() => {
                            dispatch(userActions.changeCity(city.name))
                            setModalMenu(false)
                          }}
                        >
                          {city.name}
                        </p>
                      ))
                  ) : (
                    <p className="modal__input_city-item">Города не найдены</p>
                  )}
                </div>
              )}
            </div>

            <button
              onClick={() => dispatch(setAddStep(2))}
              className={activeTarget === 'customer' ? 'modal__button customer' : 'modal__button'}
              type="submit"
            >
              Далее
            </button>
           {/*  <div className={activeTarget === 'customer' ? 'modal__progress customer' : 'modal__progress'}>
              <span />
            </div> */}
          </>
        )}
        {step === 2 && (
          <>
          {/*   <div
              className="modal__back"
              onClick={() => {
                setStep(1)
              }}
            >
              <span className="back icon"></span>
            </div> */}
            <p className="modal__text">Чтобы получить подробные финансовые расчеты, укажи email</p>
            <input
              type="email"
              required
              maxLength="50"
              minLength="2"
              value={emailBusiness}
              onChange={(e) => dispatch(setAddEmailBusiness(e.target.value))}
              placeholder="Твой Email"
              className="modal__input modal__input_step2"
            />
            <button
              onClick={() => dispatch(setAddStep(3))}
              className={activeTarget === 'customer' ? 'modal__button customer' : 'modal__button'}
              type="submit"
            >
              Далее
            </button>
            {/* <div className={activeTarget === 'customer' ? 'modal__progress customer' : 'modal__progress'}>
              <span />
              <span />
            </div> */}
          </>
        )}
        {step === 3 && (
          <>
           {/*  <div
              className="modal__back"
              onClick={() => {
                setStep(2)
              }}
            >
              <span className="back icon"></span>
            </div> */}
            <p className="modal__text">Расскажи, почему мы должны обратить внимание на твою кандидатуру</p>
            <div className="modal__question">
              <p className="modal__question-text">Есть ли у тебя опыт руководства людьми?</p>
              <RadioGroup type="people" page={page} />
            </div>
            <div className="modal__question">
              <p className="modal__question-text">Есть ли у тебя опыт ведения бизнеса?</p>
              <RadioGroup type="business" page={page} />
            </div>
            <div className="modal__question">
              {type !== 'diagnostics' ? (
                <p className="modal__question-text">Почему ты хочешь стать партнером Скилла?</p>
              ) : (
                <p className="modal__question-text">Почему ты хочешь пройти диагностику?</p>
              )}
              <textarea
                required
                value={messageWhy}
                onChange={(e) => dispatch(setAddMessage(e.target.value))}
                type="text"
                placeholder="Твой ответ"
                className="modal__input modal__input_step3"
              />
            </div>
            <button style={{marginBottom: '52px'}}
              onClick={() => dispatch(setAddStep(1))}
              className={
                activeTarget === 'customer'
                  ? 'modal__button customer modal__button_step3'
                  : 'modal__button modal__button_step3'
              }
              type="submit"
            >
              Отправить
            </button>
           {/*  <div className={activeTarget === 'customer' ? 'modal__progress customer' : 'modal__progress'}>
              <span />
              <span />
              <span />
            </div> */}
          </>
        )}
        {step === 4 && (
          <>
            {/* <div
              className="modal__back"
              onClick={() => {
                setStep(3)
              }}
            >
              <span className="back icon"></span>
            </div> */}
            {type === 'diagnostics' ? (
              <>
                <h4
                  style={{
                    color: '#E3E4E8',
                    fontWeight: '400',
                    fontSize: '18px',
                  }}
                >
                  Изучаем твою анкету
                </h4>
                <button
                  style={{
                    margin: width > 768 && '165px auto 0',
                  }}
                  className="header-form__button finished"
                  onClick={() => {
                    setStep(1)
                  }}
                >
                  Дополнить анкету
                  <span className="arrow-chart-business icon"></span>
                </button>
              </>
            ) : (
              <>
                <p className="modal__text">
                  Пока мы изучаем твою анкету, посмотри видео о мероприятии Скилла Бизнес-Герои.
                </p>
                <div className="modal-video">
                  <div className="modal-video__video">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://rutube.ru/play/embed/a07e58595b4ce8aeb8b4ba9db6f2049a"
                      title="YouTube video player"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="modal-video__content">
                    <h6 className="modal-video__title">Как это было - Бизнес-Герои</h6>
                    <p className="modal-video__description">Обзор как прошла встреча партнеров Скилла</p>
                  </div>
                </div>
              </>
            )}
           {/*  <div className={activeTarget === 'customer' ? 'modal__progress customer' : 'modal__progress'}>
              <span />
              <span />
              <span />
              <span />
            </div> */}
          </>
        )}
      </div>
    </form>
  )
})

export default FormModal
