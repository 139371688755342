import { useEffect, useState } from 'react';
import Pay from '../../components/Pay/Pay';
import s from '../../payment.module.scss';

const FullPayment = ({ statusPayment, clientWithoutCours}) => {
	const [width, setWidth] = useState(window?.innerWidth);

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);
		window.scroll(0, 0);

		return () => window.removeEventListener('resize', cb);
	}, []);
	return (
		<div className={s.payment}>
			<h4 className={s.subtitle}>{clientWithoutCours ? 8 : 9}. Предоплата</h4>

			<h1 className={`${s.title} lk-title`}>Внеси предоплату по договору</h1>

			<p className={s.descr}>
				После внесения предоплаты можно бронировать даты обучения
			</p>

			<Pay />

			{(statusPayment == 'prepaid' || statusPayment == 'paid') && <div className={s.bottom}>
				<div>
					<img src='/static/lk/iconAlertBlack.svg' alt='иконка' />
					<span>Никакого риска!</span>
				</div>

				<p>
					Skilla вернет 100% предоплаты в любой момент по твоему запросу (Статья 6
					Гарантия возврата средств, пункт 6.1. Договора)
				</p>
			</div>
			}

			<img
				className={s.illustation}
				src={
					width > 575
						? '/static/lk/founder2.png'
						: '/static/lk/founder2-mobile.png'
				}
				alt='Основатель'
			/>
		</div>
	);
};

export default FullPayment;
