import cn from 'classnames'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { checkPremiumDate } from '../../utils/checkPremiumDate.js'
import s from './lk.module.scss'

import { getLkForm, getLkGetZoom, getLkPaymentInformation, getUser } from '../../Api'
import Access from '../../components/lk/Access/Access'
import Application from '../../components/lk/Application/Application'
import Auth from '../../components/lk/Auth/Auth'
import Calendar from '../../components/lk/Calendar/Calendar.jsx'
import Contract from '../../components/lk/Contract/Contract'
import Exam from '../../components/lk/Exam/Exam.jsx'
import Graph from '../../components/lk/Graph/Graph'
import LkSidebar from '../../components/lk/lkSidebar/LkSidebar'
import Material from '../../components/lk/Material/Material'
import Payment from '../../components/lk/Payment/Payment'
import Plan from '../../components/lk/Plan/Plan'
import Questionary from '../../components/lk/Questionary/Questionary'
import Training from '../../components/lk/training/Training'
import Zoom from '../../components/lk/Zoom/Zoom'

import { selectLkAccess } from '../../store/reducer/lk/access/selector.js'
import { selectLKAuth } from '../../store/reducer/lk/authChech/selector'
import { setAddAuth, setAddUser } from '../../store/reducer/lk/authChech/slice'
import { selectLKContract } from '../../store/reducer/lk/contract/selector.js'
import { setAddStatus } from '../../store/reducer/lk/form/slice'
import { selectLKSidebar } from '../../store/reducer/lk/sidebar/selector'
import { setAddActiveLink, setAddSocket, setShowSidebar } from '../../store/reducer/lk/sidebar/slice'
import { selectLkZoom } from '../../store/reducer/lk/zoom/selector.js'

import { setAddStatusZoom } from '../../store/reducer/lk/zoom/slice'

import Book from '../../components/lk/Book/Book.jsx'
import { setAddOverlay } from '../../store/reducer/lk/contract/slice.js'
import { selectLkForm } from '../../store/reducer/lk/form/selector'
import { selectLKPayment } from '../../store/reducer/lk/payment/selector'
import { setAddPaymentInfo } from '../../store/reducer/lk/payment/slice.js'
import { selectLkTraining } from '../../store/reducer/lk/training/selector.js'
import Course from '../../components/lk/Сourse/Course.jsx'
import Entity from '../../components/lk/Entity/Entity.jsx'

const Lk = () => {
  const [width, setWidth] = useState(window?.innerWidth)
  const [endDatePremium, setEndDatePremium] = useState(null)
  const [clientWithoutCours, setClientWithoutCours] = useState(false)
  const { activeLink, showMobile, socketData } = useSelector(selectLKSidebar)
  const { information } = useSelector(selectLkAccess)
  const { user, auth, namePage } = useSelector(selectLKAuth)
  const { persent, isContract, signed, isOverlay } = useSelector(selectLKContract)
  const userCode = user?.user?.code
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { paymentInfo } = useSelector(selectLKPayment)
  const { status } = useSelector(selectLkForm)
  const { statusZoom } = useSelector(selectLkZoom)
  const { trainingInfo } = useSelector(selectLkTraining)
  const menuActive = user?.user?.menu
  console.log(activeLink, user, status)
  const statusForm = status?.form?.status;
  const statusFormDate = status?.form?.date;
  const сoursDate = user?.user?.menu[7].date_change;
  console.log(clientWithoutCours)

  const statusPayment = paymentInfo?.payment?.status

  const informationAcceessStatus = information?.access?.status

  const statusTraining = trainingInfo?.training?.status

  Cookies.set('code', user?.user?.code)

  useEffect(() => {
    user?.user?.menu[7].name == 'Договор-оферта' ? setClientWithoutCours(true) : setClientWithoutCours(false)
  }, [user?.user?.menu[7].name])

  useEffect(() => {
    if (user?.user?.code) {
      const ws = new WebSocket(`wss://lk.skilla.ru:8018/?code=${user?.user?.code}`)

      ws.addEventListener('message', (e) => {
        dispatch(setAddSocket(JSON.parse(e.data)))
      })
    }
  }, [user?.user?.code])

  useEffect(() => {
    setEndDatePremium(checkPremiumDate(сoursDate))
  }, [activeLink])

  useEffect(() => {
    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)
    window.scroll(0, 0)

    return () => window.removeEventListener('resize', cb)
  }, [])

  useEffect(() => {
    if (!userCode) {
      dispatch(setAddAuth(false))
    }
  }, [])

  useEffect(() => {
    if (!auth && !userCode) {
      dispatch(setAddAuth(false))
      dispatch(setAddActiveLink(1))
      dispatch(setAddUser({}))
      dispatch(setShowSidebar(false))

      localStorage.removeItem('activeLink')
      localStorage.removeItem('stepLk')
      localStorage.removeItem('user')
      localStorage.removeItem('auth')
      localStorage.removeItem('showMobile')
      localStorage.removeItem('data')
      localStorage.removeItem('showMobileState')
      localStorage.removeItem('isContract')
      localStorage.removeItem('placePicker')
      localStorage.removeItem('trainingInfo')
      localStorage.removeItem('isCheckFromPage')
      localStorage.removeItem('paymentInfo')
      localStorage.removeItem('persent')
      localStorage.removeItem('is_online')
      localStorage.removeItem('signed')
      localStorage.removeItem('isCheckFromPage')

      navigate('/enter')
    }
  }, [])

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const { data } = await getUser(user?.user?.code)

        dispatch(setAddUser(data))
        localStorage.setItem('user', JSON.stringify(data))
      } catch (e) {
        console.log(e)
      }
    }

    if (user?.user?.code) {
      getUserInfo()
    }
  }, [activeLink, socketData])

  useEffect(() => {
    const getLkFormStatus = async () => {
      try {
        const { data } = await getLkForm(user?.user?.code)
        console.log('Статус роада лк', data)

        dispatch(setAddStatus(data))
        localStorage.setItem('status', JSON.stringify(data))
      } catch (e) {
        console.log(e)
      }
    }

    if (user?.user?.code) {
      getLkFormStatus()
    }
  }, [])

  useEffect(() => {
    const getZoomStatus = async () => {
      try {
        const { data } = await getLkGetZoom(user?.user?.code)

        dispatch(setAddStatusZoom(data))
        localStorage.setItem('statusZoom', JSON.stringify(data))
      } catch (e) {
        console.log(e)
      }
    }

    if (user?.user?.code) {
      getZoomStatus()
    }
  }, [])

  useEffect(() => {
    const getPaymentInfo = async () => {
      try {
        const { data } = await getLkPaymentInformation(user?.user?.code)

        dispatch(setAddPaymentInfo(data))
        localStorage.setItem('paymentInfo', JSON.stringify(data))
      } catch (e) {
        console.log(e)
      }
    }

    getPaymentInfo()
  }, [activeLink])

  const onSidebarMobile = () => {
    dispatch(setShowSidebar(true))
    localStorage.setItem('showMobile', JSON.stringify(true))
    document.body.classList.add('overlay')
  }

  const onHideSidebarMobile = () => {
    dispatch(setShowSidebar(false))
    localStorage.setItem('showMobile', JSON.stringify(false))
    document.body.classList.remove('overlay')
  }

  const toGoBookPage = () => {
    dispatch(setAddActiveLink('book'))
    localStorage.setItem('activeLink', JSON.stringify('book'))
    dispatch(setAddOverlay(false))
  }

  console.log(activeLink)
  return (
    <div className={s.lk}>
      <Helmet>
        <title>Личный кабинет партнера</title>
      </Helmet>
      <LkSidebar />

      {width <= 771 && (
        <div className={s.top_mobile}>
          <div>
            <img src="/static/lk/logo-skilla.png" alt="логотип" />

            {!showMobile ? (
              <button onClick={onSidebarMobile} className={s.burger}>
                <img src="/static/lk/burger.svg" alt="открыть меню" />
              </button>
            ) : (
              <button onClick={onHideSidebarMobile} className={s.burger}>
                <img src="/static/lk/burgerClose.svg" alt="закрыть меню" />
              </button>
            )}
          </div>
        </div>
      )}

      <main
        className={cn(s.main, {
          [s.main_overlay]: isOverlay,
          [s.main_noBG]: activeLink === 'graph' /* || (activeLink === 9 && width > 770) */,
          [s.main_contract]: persent > 4 && activeLink === 7,

          [s.main_calendar]: activeLink === 'calendar',

          [s.main_auth]: activeLink === 1,
          [s.main_book]: activeLink === 'book' || !user?.user?.code,
          [s.main_access]:
            (activeLink === 10 && informationAcceessStatus === 'waiting') ||
            informationAcceessStatus === 'confirmed',
          [s.contract]: activeLink === 7 && persent <= 4,
          [s.main_payment]: activeLink === 8 && statusPayment !== 'waiting',
          [s.main_zoom]: activeLink === 5 && statusZoom !== 'failure',
          [s.main_material]: activeLink === 2,

          [s.main_contractVip]: persent <= 4 && (activeLink === 7 || activeLink === 8) && !isContract && signed === 'unsigned',
          [s.main_forBaner]:
            user?.user?.code &&
            statusPayment &&
            persent &&
            statusPayment !== 'prepaid' &&
            statusPayment !== 'paid' &&
            persent <= 4 &&
            activeLink !== 'graph' &&
            endDatePremium &&
            namePage !== 'start' &&
            namePage !== 'check' &&
            width > 770,

          [s.main_questionary]: activeLink === 6,

          [s.main_exam]: activeLink === 11,
        })}
      >
        {activeLink === 1 && <Auth endDatePremium={endDatePremium} />}

        {activeLink === 2 && user?.user?.code && <Material endDatePremium={endDatePremium} />}

        {activeLink === 3 && user?.user?.code && <Application endDatePremium={endDatePremium} />}

        {activeLink === 4 && user?.user?.code && <Plan endDatePremium={endDatePremium} />}

        {activeLink === 5 && user?.user?.code && <Zoom endDatePremium={endDatePremium} />}

        {activeLink === 6 && user?.user?.code && <Questionary endDatePremium={endDatePremium} />}

       

        {activeLink === 7 && user?.user?.code && !clientWithoutCours && <Course endDatePremium={endDatePremium} />}

        {activeLink === 7 && user?.user?.code && clientWithoutCours && <Contract endDatePremium={endDatePremium} />}

        {activeLink === 8 && user?.user?.code && !clientWithoutCours && <Contract endDatePremium={endDatePremium} />}

        {activeLink === 8 && user?.user?.code && clientWithoutCours && <Payment endDatePremium={endDatePremium} />}

        {activeLink === 9 && user?.user?.code && !clientWithoutCours && <Payment endDatePremium={endDatePremium} />}

        {activeLink === 9 && user?.user?.code && clientWithoutCours && <Training endDatePremium={endDatePremium} />}

        {activeLink === 10 && user?.user?.code && !clientWithoutCours && <Training endDatePremium={endDatePremium} />}

        {activeLink === 10 && user?.user?.code && clientWithoutCours && <Access endDatePremium={endDatePremium} />}

        {activeLink === 11 && user?.user?.code && !clientWithoutCours && <Access endDatePremium={endDatePremium} />}

        {activeLink === 11 && user?.user?.code && clientWithoutCours && <Exam endDatePremium={endDatePremium} />}

        {activeLink === 12 && user?.user?.code && !clientWithoutCours && <Exam endDatePremium={endDatePremium} />}


     

        {activeLink === 13 && user?.user?.code && <Entity endDatePremium={endDatePremium} />}

        {activeLink === 'book' && user?.user?.code && <Book endDatePremium={endDatePremium} />}

        {activeLink === 'graph' && <Graph />}
        {activeLink === 'calendar' && <Calendar />}

       {/*  {activeLink !== 'book' && activeLink !== 6 && activeLink !== 11 && (
          <div
            onClick={toGoBookPage}
            className={cn(s.banner, {
              [s.bannerNoMargin]:
                activeLink === 2 ||
                (activeLink === 5 && statusZoom !== 'failure' && statusZoom !== 'request') ||
                (activeLink === 6 && (statusForm === 'request' || statusForm === 'rejected')) ||
                (activeLink === 7 && persent <= 4 && !isContract) ||
                (activeLink === 8 && (statusPayment === 'waiting' || statusPayment === 'returned')) ||
                (activeLink === 9 && statusTraining !== 'waiting' && statusTraining !== 'finished') ||
                activeLink === 10 ||
                activeLink === 'graph' ||
                activeLink === 'calendar',

              [s.bannerHide]: !user?.user?.code || activeLink === 1,
            })}
          >
            <div className={s.banner_right}>
              {width > 770 && <img className={s.banner_img} src={'/static/lk/banner.png'} alt="баннер" />}

              <div className={s.banner_content}>
                <h2>Книга основателя Скилла</h2>
                <p>Как парень из глубинки создал с нуля миллиардный бизнес в России</p>

                {width <= 770 && (
                  <img className={s.banner_img} src={'/static/lk/banner-mobile.png'} alt="баннер" />
                )}

                <button className={s.banner_btn}>
                  Читать бесплатно
                  <img src="/static/lk/IconForward.svg" alt="" />
                </button>
              </div>
            </div>

            {width > 770 && <img className={s.banner_litres} src="/static/lk/litres.png" alt="литрес" />}
          </div>
        )} */}
      </main>
    </div>
  )
}

export default Lk
