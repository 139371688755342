import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import parse from 'html-react-parser';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import SmsCode from '../../lk/SmsCode/SmsCode';

import * as yup from 'yup';
import {
	getLkContract,
	getLkContractSign,
	getLkForm,
	getUser,
	getViewMenu,
} from '../../../Api';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside.js';
import { selectLKAuth } from '../../../store/reducer/lk/authChech/selector.js';
import { setAddUser } from '../../../store/reducer/lk/authChech/slice.js';
import { selectLKContract } from '../../../store/reducer/lk/contract/selector.js';

import { selectLKPayment } from '../../../store/reducer/lk/payment/selector';

import {
	setAddContact,
	setAddContactText,
	setAddContractNumber,
	setAddDate,
	setAddIsOnline,
	setAddIsPersent,
	setAddLoading,
	setAddNumberReg,
	setAddOverlay,
	setAddShowModalForm,
	setAddSigned,
	setIsCheckFromPage,
} from '../../../store/reducer/lk/contract/slice.js';
import { selectLkForm } from '../../../store/reducer/lk/form/selector.js';
import { setAddStatus } from '../../../store/reducer/lk/form/slice.js';
import { selectLKSidebar } from '../../../store/reducer/lk/sidebar/selector';
import Skeleton from '../../skeleton/Skeleton';
import Spinner from '../../SpinnerSmall';
import Done from '../Contract/done/Done';
import Failed from '../Contract/failed/Failed';
import PremiumDone from '../Contract/premiumDone/PremiumDone';
import UlItem from '../ui/UlItem/UlItem';
import DocView from './DocView.jsx';
import s from './contract.module.scss';


const Contract = memo(({ endDatePremium }) => {
	const [width, setWidth] = useState(window?.innerWidth);
	const [load, setLoad] = useState(false);
	const [clientWithoutCours, setClientWithoutCours] = useState(false)
	const { isLoading, isContract, contractText, signed, persent, is_online } =
		useSelector(selectLKContract);
	const { status } = useSelector(selectLkForm);
	const { user } = useSelector(selectLKAuth);
	const { activeLink, socketData } = useSelector(selectLKSidebar);
	const { refBtn, refPopup, isShow, setIsShow } = useOnClickOutside(false);
	const { paymentInfo } = useSelector(selectLKPayment);
	const statusPayment = paymentInfo?.payment?.status;
	const prepayStatus = user?.user?.menu[9] == 'finished' ? true : false;

	const dispatch = useDispatch();

	useEffect(() => {
		user?.user?.menu[7].name == 'Договор-оферта' ? setClientWithoutCours(true) : setClientWithoutCours(false)
	  }, [user?.user?.menu[7].name])

	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.scroll(0, 0);
		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
		const checkViewMenu = async () => {
			try {
				const { data } = await getViewMenu(user?.user?.code, /* activeLink */7);
			} catch (e) {
				console.log(e);
			}
		};

		checkViewMenu();
	}, []);
	console.log(activeLink)

	useEffect(() => {
		getLkForm(user?.user?.code)
			.then(({ data }) => {
				dispatch(setAddStatus(data));
				localStorage.setItem('status', JSON.stringify(data));
			})

			.catch(e => {
				console.log(e);
			});
	}, [socketData]);

	useEffect(() => {
		const getUserInfo = async () => {
			try {
				const { data } = await getUser(user?.user?.code);

				dispatch(setAddUser(data));
				localStorage.setItem('user', JSON.stringify(data));
			} catch (e) {
				console.log(e);
			}
		};

		if (user?.user?.code) {
			getUserInfo();
		}
	}, [socketData]);

	useEffect(() => {
		window.scroll(0, 0);
	}, []);

	//получение данных договора

	useEffect(() => {
		dispatch(setAddLoading(true));
		const getContractInfo = async () => {
			try {
				const { data } = await getLkContract(user?.user?.code);
				dispatch(setAddLoading(false));

				dispatch(setAddContactText(data?.contract?.text));
				dispatch(setAddSigned(data?.contract?.status));
				dispatch(setAddIsOnline(data?.contract?.is_online));
				dispatch(setAddIsPersent(data?.contract?.persent));
				dispatch(setAddDate(data?.contract?.signed_date));
				dispatch(setAddContractNumber(data?.contract?.num));
				localStorage.setItem(
					'persent',
					JSON.stringify(data?.contract?.persent)
				);
				localStorage.setItem('signed', JSON.stringify(data?.contract?.status));
				localStorage.setItem(
					'is_online',
					JSON.stringify(data?.contract?.is_online)
				);
			} catch (e) {
				dispatch(setAddLoading(false));
				console.log(e);
			}
		};

		getContractInfo();
	}, [socketData]);
	//получение нового договора
    console.log(`https://lk.skilla.ru/frmanager/contracts/lk/?code=${user?.user?.code}`)


	const showPopupSign = () => {
		setIsShow(true);
		dispatch(setAddOverlay(true));
		document.body.classList.add('overlay');
	};

	const schema = yup.object().shape({
		checkbox: yup
			.bool()
			.oneOf([true], 'You must accept the terms and conditions'),
		checkbox2: yup
			.bool()
			.oneOf([true], 'You must accept the terms and conditions'),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	const onSubmit = ({ number }) => {
		dispatch(setAddNumberReg(number));
		document.body.classList.add('overlay');

		const getСontractSign = async () => {
			try {
				const { data } = await getLkContractSign(user?.user?.code, number);

				dispatch(setAddShowModalForm(true));
			} catch (e) {
				console.log(e);
			}
		};

		getСontractSign();
	};

	const onShowContract = bol => {
		dispatch(setAddContact(bol));
		dispatch(setAddLoading(true));
		localStorage.setItem('isContract', JSON.stringify(true));

		const getContract = async () => {
			try {
				const { data } = await getLkContract(user?.user?.code);
				dispatch(setAddContactText(data?.contract?.text));
				dispatch(setAddIsOnline(data?.contract?.is_online));
				dispatch(setAddIsPersent(data?.contract?.persent));
				dispatch(setAddLoading(false));
				localStorage.setItem(
					'persent',
					JSON.stringify(data?.contract?.persent)
				);
				localStorage.setItem('signed', JSON.stringify(data?.contract?.status));
				localStorage.setItem(
					'is_online',
					JSON.stringify(data?.contract?.is_online)
				);
			} catch (e) {
				dispatch(setAddLoading(false));
				console.log(e);
			}
		};

		getContract();
	};

	const options = {
		day: 'numeric',
		month: 'long',
	};

	const date = status?.form?.date;

	const handleCheckStart = new Date(date);
	handleCheckStart.setDate(handleCheckStart.getDate() + 7);

	const endPremium = handleCheckStart.toLocaleDateString('ru-RU', options);
	const yearNow = new Date().getUTCFullYear();

	const toGoVipPage = () => {
		dispatch(setIsCheckFromPage(true));
		localStorage.setItem('isCheckFromPage', JSON.stringify(true));
		window.open('/premium', '_blank');
	};

	return (
		<>
			<SmsCode />

			{statusPayment &&
				persent &&
				statusPayment !== 'prepaid' &&
				statusPayment !== 'paid' &&
				persent <= 4 &&
				endDatePremium &&
				(width > 575 ? (
					<header onClick={toGoVipPage} className={s.header}>
						<img src='/static/lk/background.png' alt='Баннер' />

						<div className={s.header_btn}>Узнать больше</div>
					</header>
				) : (
					<header className={s.header}>
						<div onClick={toGoVipPage}>
							<img
								style={{ width: '100%' }}
								src='/static/lk/background-mobile.png'
								alt='Баннер'
							/>
						</div>
					</header>
				))}

			{persent <= 4 && !isContract && signed === 'unsigned' && (
				<div className={s.contract}>

					<h4 className={s.subtitle}>
						{width > 575 ? `${clientWithoutCours ? 7 : 8}. Договор-оферта` : `Шаг ${clientWithoutCours ? 7 : 8}`}
					</h4>
					<h1 className={`${s.title} lk-title`}>
						Стань Премиум-предпринимателем бесплатно!
					</h1>

					<div className={s.info}>
						<div className={s.left}>
							<ul className={s.list}>
								<h3 className={s.list_title}>Главные преимущества</h3>
								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Возможность инвестировать в свой бизнес и гарантированно
										заработать
									</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Гарантия возврата средств, если прибыль не устроит (Ст. 6 в
										Договоре)
									</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Ежемесячные отчисления в Скилла 4%, вместо 6%. Сэкономь до 1
										млн/год
									</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>Персональный бизнес-ассистент</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>Приоритетное решение вопросов в чате</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Подготовка налоговой и бухгалтерской отчетности бесплатно
										под ключ
									</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Прием звонков Контактным центром от исполнителей бесплатно
										без условий
									</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>Прямая связь с исполнительным директором</span>
								</UlItem>
							</ul>
						</div>

						<div className={s.right}>
							<ul className={s.list}>
								<h3 className={s.list_title}>Прокачка и отдых</h3>
								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Премиальные ежегодные мероприятия в разных городах
									</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Личный коучинг в мини-группах с
										наставниками-предпринимателями
									</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Поездки по России и заграницу, более 30 мероприятий в год
									</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>Организация всех поездок под ключ</span>
								</UlItem>
							</ul>
						</div>
					</div>

					<div className={s.footer}>
						<div className={s.date}>
							Предложение действительно только до {endPremium} {yearNow}
						</div>

						{width <= 575 && (
							<img
								className={s.founder}
								src='/static/lk/founder-mobile.png'
								alt='Основатель'
							/>
						)}

						<div className={s.btns}>
							<button onClick={() => onShowContract(true)}>
								Изучить договор
							</button>
							<a onClick={toGoVipPage} href='/premium' target='_blank'>
								Подробнее о статусе
							</a>
						</div>
					</div>

					{width > 575 && (
						<img
							className={s.founder}
							src='/static/lk/founder.png'
							alt='Основатель'
						/>
					)}
				</div>
			)}

			{persent > 4 && !isContract && signed === 'unsigned' && (
				<div className={s.contract}>
					<h4 className={s.subtitle}>
						{width > 575 ? `${clientWithoutCours ? 7 : 8}. Договор-оферта` : `Шаг ${clientWithoutCours ? 7 : 8}`}
					</h4>
					<h1 className={`${s.title} lk-title`}>
						Стань предпринимателем и работай на себя!
					</h1>

					<div className={s.info_notPremium}>
						<div className={s.top_notPremium}>
							<ul className={s.list}>
								<h3 className={s.list_title}>
									Получи на все время сотрудничества
								</h3>
								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Возможность инвестировать в свой бизнес и гарантированно
										заработать
									</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Гарантия возврата средств, если прибыль не устроит (Ст. 6 в
										Договоре)
									</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Глобальное обучение (очно, онлайн и с наставником)
									</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Куратор в лице бизнес-ассистента и чат поддержки для любых
										вопросов
									</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Контактный центр, принимающий все звонки от клиентов и
										исполнителей
									</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Знаменитая Конференция Бизнес-Герои и топовые
										спикеры-предприниматели
									</span>
								</UlItem>
							</ul>
						</div>

						<div className={s.bottom_notPremium}>
							<ul className={s.list}>
								<h3 className={s.list_title}>На обучении в Санкт-Петербурге</h3>
								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Трансфер в Санкт-Петербурге из/в аэропорта и вокзалов
									</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>Отель мирового бренда в сердце города</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Книга-биография основателя компании Упорова Кирилла
									</span>
								</UlItem>

								<UlItem contract>
									<div className={s.done}>
										<svg
											width='24'
											height='24'
											viewBox='0 0 24 24'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
										>
											<path
												d='M8 11L6.5 12.5L12.429 18.6299L23 8L21.5 6.5L12.5 15.5L8 11Z'
												fill='#002CFB'
											/>
											<path
												d='M1 12.5L2.5 11L8 17L6.5 18.5L1 12.5Z'
												fill='#002CFB'
											/>
											<path
												d='M12 13L17.2635 7.52505L15.8492 6.11084L10.5 11.5L12 13Z'
												fill='#002CFB'
											/>
										</svg>
									</div>

									<span>
										Нетворкинг с другими предпринимателями-новичками на обучении
										в офисе Скилла
									</span>
								</UlItem>
							</ul>
						</div>
					</div>

					<div className={s.footer}>
						<div className={s.date}>
							Заключи договор пока в твоем городе возможно партнерство
						</div>

						<div className={s.btn}>
							<button onClick={() => onShowContract(true)}>
								Изучить договор
							</button>
						</div>
					</div>

					<img
						className={s.founder}
						src={
							width > 575
								? '/static/lk/founder2.png'
								: '/static/lk/founder2-mobile.png'
						}
						alt='Основатель'
					/>
				</div>
			)}

			{isContract && signed === 'unsigned' && (
				<>
					{!isLoading && contractText ? (
						<div className={s.contract_vipStatus}>
							<DocView link={`https://lk.skilla.ru/frmanager/contracts/lk/?code=${user?.user?.code}`} height={width > 575 ? 700 : 500}/>
							{/* <div className={s.inner}>
								<p>{parse(contractText)}</p>
								
							</div> */}
						</div>
					) : (
						<Skeleton />
					)}

					{width > 575 && (
						<div className={s.consent}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className={s.consent_left}>
									<div className={s.checkbox}>
										<input
											className={s.input}
											{...register('checkbox', {
												required: true,
											})}
											id='checkbox'
											type='checkbox'
											name='checkbox'
											autoComplete='off'
										/>
										<label
											htmlFor='checkbox'
											className={cn(s.label, {
												[s.error]: errors.checkbox,
											})}
										>
											Я принимаю условия настоящего договора и подтверждаю
											полноту и корректность введенных персональных данных
										</label>
									</div>

									<div className={s.checkbox}>
										<input
											className={s.input}
											{...register('checkbox2', {
												required: true,
											})}
											id='checkbox2'
											type='checkbox'
											name='checkbox2'
											autoComplete='off'
										/>
										<label
											htmlFor='checkbox2'
											className={cn(s.label, {
												[s.error]: errors.checkbox2,
											})}
										>
											Я принимаю{' '}
											<a
												href='https://skilla.ru/agreement.html'
												target='_blank'
											>
												Соглашение
											</a>{' '}
											о подписании документов с использованием СМС (простая
											электронная подпись)
										</label>
									</div>
								</div>

								<div className={s.consent_right}>
									<div className={s.item}>
										<div className={s.wrapperInput}>
											<ReactInputMask
												className={cn(s.inputNumber, {
													[s.input_error]: errors.number,
												})}
												{...register('number')}
												disabled
												name='number'
												mask='+7 (\999) 999-99-99'
												maskChar=''
												alwaysShowMask={false}
												placeholder={user?.user?.phones[0].phone}
												defaultValue={user?.user?.phones[0].phone}
												autoComplete='off'
											/>
										</div>
									</div>

									{!load ? (
										<button className={s.btn_contract}>
											Присоединится к условиям оферты
										</button>
									) : (
										<div style={{ textAlign: 'center' }}>
											<Spinner widthSize={50} heightSize={50} />
										</div>
									)}
								</div>
							</form>
						</div>
					)}

					{width <= 575 && (
						<button ref={refBtn} onClick={showPopupSign} className={s.go_sign}>
							Перейти к подписанию
						</button>
					)}

					{width <= 575 && isShow && (
						<div ref={refPopup} className={s.popup_sign}>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className={s.consent_left}>
									<div className={s.checkbox}>
										<input
											className={s.input}
											{...register('checkbox', {
												required: true,
											})}
											id='checkbox'
											type='checkbox'
											name='checkbox'
											autoComplete='off'
										/>
										<label
											htmlFor='checkbox'
											className={cn(s.label, {
												[s.error]: errors.checkbox,
											})}
										>
											Я принимаю условия настоящего договора и подтверждаю
											полноту и корректность введенных персональных данных
										</label>
									</div>

									<div className={s.checkbox}>
										<input
											className={s.input}
											{...register('checkbox2', {
												required: true,
											})}
											id='checkbox2'
											type='checkbox'
											name='checkbox2'
											autoComplete='off'
										/>
										<label
											htmlFor='checkbox2'
											className={cn(s.label, {
												[s.error]: errors.checkbox2,
											})}
										>
											Я принимаю{' '}
											<a
												href='https://skilla.ru/agreement.html'
												target='_blank'
											>
												Соглашение
											</a>{' '}
											о подписании документов с использованием СМС (простая
											электронная подпись)
										</label>
									</div>
								</div>
								<div className={s.consent_right}>
									<div className={s.item}>
										<div className={s.wrapperInput}>
											<ReactInputMask
												className={cn(s.inputNumber, {
													[s.input_error]: errors.number,
												})}
												{...register('number')}
												disabled
												name='number'
												mask='+7 (\999) 999-99-99'
												maskChar=''
												alwaysShowMask={false}
												placeholder={user?.user?.phones[0].phone}
												defaultValue={user?.user?.phones[0].phone}
												autoComplete='off'
											/>
										</div>
									</div>

									{!load ? (
										<button className={s.btn_contract}>
											Присоединится к условиям оферты
										</button>
									) : (
										<div style={{ textAlign: 'center' }}>
											<Spinner widthSize={50} heightSize={50} />
										</div>
									)}
								</div>
							</form>
						</div>
					)}
				</>
			)}

			{signed === 'signed' && persent <= 4 && is_online && (
				<PremiumDone statusPayment={statusPayment} persent={persent} prepayStatus={prepayStatus}/>
			)}

			{signed === 'signed' && persent > 4 && is_online && <Done prepayStatus={prepayStatus}/>}

			{!is_online && <Failed />}
		</>
	);
});

export default Contract;
