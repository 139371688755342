import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLkForm, getUser } from '../../../../../Api';
import { selectLKAuth } from '../../../../../store/reducer/lk/authChech/selector';
import { setAddUser } from '../../../../../store/reducer/lk/authChech/slice';
import { selectLkForm } from '../../../../../store/reducer/lk/form/selector';
import { setAddStatus } from '../../../../../store/reducer/lk/form/slice';
import { selectLKSidebar } from '../../../../../store/reducer/lk/sidebar/selector';
import { setAddActiveLink } from '../../../../../store/reducer/lk/sidebar/slice.js';
import s from './done.module.scss';

const Done = () => {
	const [width, setWidth] = useState(window?.innerWidth);
	const { user } = useSelector(selectLKAuth);
	const { step, status } = useSelector(selectLkForm);
	const {socketData} = useSelector(selectLKSidebar)
  
	const dispatch = useDispatch();

	const statusForm = status?.form?.status;
	console.log(statusForm)


	useEffect(() => {
		const cb = () => {
			setWidth(window.innerWidth);
		};

		window.addEventListener('resize', cb);

		return () => window.removeEventListener('resize', cb);
	}, []);

	useEffect(() => {
		getUser(user?.user?.code).then(({ data }) => {
			dispatch(setAddUser(data));
			localStorage.setItem('user', JSON.stringify(data));
		});
	}, [socketData]);

	useEffect(() => {
		localStorage.setItem('stepLk', JSON.stringify(step));
	}, []);

	useEffect(() => {
		getLkForm(user?.user?.code)
			.then(({ data }) => {
				dispatch(setAddStatus(data));
				localStorage.setItem('status', JSON.stringify(data));
			})

			.catch(e => {
				console.log(e);
			});
	}, [socketData]);

	

	const goContractPage = () => {
		dispatch(setAddActiveLink(7));
		localStorage.setItem('activeLink', JSON.stringify(7));
	};

	return (
		<div className={s.done}>
			<div className={s.wrapper_title}>
				{statusForm === 'rejected' && (
					<img src='/static/lk/iconNotApproval.svg' alt='Иконка' />
				)}

				{statusForm === 'request' && (
					<img src='/static/lk/iconTime.svg' alt='Иконка' />
				)}

				{statusForm === 'accepted' && (
					<img src='/static/lk/iconDoneBig.svg' alt='Иконка' />
				)}

				<h4
					className={cn(s.subtitle, {
						[s.subtitle__approval]: statusForm === 'accepted',
						[s.subtitle__notApproval]: statusForm === 'rejected',
					})}
				>
					{width > 575 ? '6. Анкета партнера' : 'Шаг 6'}
				</h4>
			</div>

			{statusForm === 'accepted' && (
				<h1 className={`${s.title} lk-title`}>Анкета одобрена!</h1>
			)}

			{statusForm === 'rejected' && (
				<h1 className={`${s.title} lk-title`}>Анкета не одобрена :(</h1>
			)}

			{statusForm === 'request' && (
				<h1 className={`${s.title} lk-title`}>Анкета отправлена!</h1>
			)}

			{statusForm === 'rejected' ? (
				<div>
					<p
						style={{ marginBottom: '30px', maxWidth: '600px' }}
						className={s.descr}
					>
						К сожалению, ты не прошел скоринг и Skilla не готова предложить тебе
						сотрудничество.
					</p>
					<p
						style={{ marginBottom: '518px', maxWidth: '600px' }}
						className={s.descr}
					>
						{' '}
						В некоторых случаях можно попробовать оформить партнерство на
						родственника или твоего бизнес-партнера.
					</p>
				</div>
			) : statusForm === 'request' ? (
				<p className={s.descr}>
					Оценка отобразится здесь в течение одного рабочего дня
				</p>
			) : statusForm === 'accepted' ? (
				<p className={s.descr_approval}>
					Skilla одобрила твою анкету на партнерство. {/* Приступай к изучению
					договора. */}Приступай к прохождению вводного курса.{/* , погрузись в мир бизнеса Скилла */}
				</p>
			) : (
				''
			)}

			{statusForm === 'accepted' && (
				<a onClick={goContractPage} className={s.btn}>
					{/* Изучить договор */}Введение в бизнес
				</a>
			)}

			{statusForm === 'rejected' ? (
				<img
					className={s.illustration__rejected}
					src={
						width > 575
							? '/static/lk/illustration/illustrationBad.png'
							: '/static/lk/illustration/illustrationBad-mobile.png'
					}
					alt='Иллюстрация'
				/>
			) : statusForm === 'request' ? (
				<img
					className={s.illustation}
					src={
						width > 575
							? '/static/lk/illustration/illustration-technology.png'
							: '/static/lk/illustration/illustration-technology-mobile.png'
					}
					alt='Иллюстрация'
				/>
			) : statusForm === 'accepted' ? (
				<img
					className={s.illustation__approval}
					src={
						width > 575
							? '/static/lk/illustration/illustrationThreePeople.png'
							: '/static/lk/illustration/illustrationThreePeople-mobile.png'
					}
					alt='Иллюстрация'
				/>
			) : (
				''
			)}
		</div>
	);
};

export default Done;
