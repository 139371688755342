import React, { memo, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { getNumWorkers } from '../Api'
import { selectForm } from '../store/reducer/form/selector'
import { selectLKAuth } from '../store/reducer/lk/authChech/selector'
import { selectLKContract } from '../store/reducer/lk/contract/selector'
import { selectLkForm } from '../store/reducer/lk/form/selector'
import { selectMain } from '../store/reducer/main/selector'
import { setAddActiveLink, setAddActiveLoadingPage, setAddActiveTarget } from '../store/reducer/main/slice'
import FooterBook from './footerBook/FooterBook'
import Form from './Form'
import ModalHH from './ModalHh/ModalHH'

const DreamJob = ({ type }) => {
  useEffect(() => {
    const list = document.querySelectorAll('[data-id=wg-103771-1-1]')
    list.forEach((el) => {
      el.src = 'https://dreamjob.ru/uploads/widgets/wg-103771-1-1.svg?' + ~~(Date.now() / 864e5)
    })
  }, [])
  return (
    <a
      style={{
        position: type !== 'mob' ? 'absolute' : '',
        bottom: '162px',
        right: '0',
        flexShrink: '0',
        marginTop: type == 'mob' ? '12px' : '',
      }}
      href="https://dreamjob.ru/employers/103771"
      target="_blank"
      rel="noopener"
    >
      <img
        data-id="wg-103771-1-1"
        width={type == 'mob' ? '160' : '170'}
        height={type == 'mob' ? '108' : '114'}
        title="Рейтинг работодателя на Dream Job"
        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3C/svg%3E"
        alt="Skilla Бизнес-агрегатор отзывы сотрудников о работодателе на Dream Job"
        style={{ border: '0' }}
      />
    </a>
  )
}

const Footer = memo(function Footer({ page, type = '', formRef, title, id, book, inputRefFooter }) {
  const [width, setWidth] = useState(window?.innerWidth)
  const [numWorkers, setNumWorkers] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const { status } = useSelector(selectLkForm)
  const { user } = useSelector(selectLKAuth)
  const dispatch = useDispatch()
  const [firstVideo, setFirstVideo] = useState(false)

  const { activeLink, onClickForm, activeLoadingPage } = useSelector(selectMain)
  const { isCheckFromPage } = useSelector(selectLKContract)
  const { onClick } = useSelector(selectForm)

  const navigate = useNavigate()
  const currentYear = new Date().getUTCFullYear()
  const location = useLocation()

  const options = {
    day: 'numeric',
    month: 'long',
  }
  /*   const date = status?.form?.date */
  const сoursDate = user?.user?.menu[7]?.date_change ? user?.user?.menu[7]?.date_change : '0000-00-00';

  const handleCheckStart = new Date(сoursDate)
  handleCheckStart.setDate(handleCheckStart.getDate() + 7)

  const endPremium = handleCheckStart.toLocaleDateString('ru-RU', options)
  const yearNow = new Date().getUTCFullYear()

  const dateToday = new Date().getDate()

  const removeDate = сoursDate ? (handleCheckStart.getDate() - dateToday) : 0

  const setTab = (e) => {
    const childrenCount = e.target.children.length
    let childrenItems = []

    if (childrenCount) {
      childrenItems = Array.from(e.target.children)
    } else {
      childrenItems = Array.from(e.target.parentNode.children)
    }

    childrenItems.forEach((children) => {
      if (children.classList.value.includes('active')) {
        children.classList.remove('active')
      } else {
        children.classList.add('active')
      }
    })
  }

  useEffect(() => {
    getNumWorkers().then(({ data }) => {
      setNumWorkers(data.num.toLocaleString())
    })

    const cb = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener('resize', cb)

    return () => window.removeEventListener('resize', cb)
  }, [])

  const scrollToForm = (type) => {
    formRef.current.scrollIntoView({ behavior: 'smooth' })
    dispatch(setAddActiveLink(type))
  }

  const scrollToFormFranchise = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' })
    inputRefFooter.current.getInputDOMNode().focus()
  }

  const onActiveTarget = (type, section) => {
    dispatch(setAddActiveTarget(type))
    dispatch(setAddActiveLoadingPage(section))
  }

  const handleOpenModalHh = () => {
    setOpenModal(true)
  }

  if (page === 'customer')
    return (
      <>
        {openModal && <ModalHH setOpenModal={setOpenModal} />}
        {width > 768 && (
          <StyledFooter>
            {/*  {type !== 'faq' && (
              <header className="footer-header">
                <div className="container container_footer-header">
                  <div className="footer-header__content">
                    <div>
                      {type === 'contracts' ? (
                        <h2 className="footer-header__title_customer footer-header__title ">
                          {console.log(type)}
                          Закажите демонстрацию возможностей <br /> платформы Скилла
                        </h2>
                      ) : type === 'technologies' ? (
                        <div className="footer-header__title footer-header__title_customer">
                          <h2 className="footer-header__title footer-header__title_customer technologies">
                            Закажите демонстрацию возможностей платформы Скилла
                          </h2>
                        </div>
                      ) : type === 'heroes' ? (
                        <h2 className="footer-header__title footer-header__title_customer">
                          Присоединяйся к <span>успешной команде</span> лидеров России
                        </h2>
                      ) : (
                        <h2 className="footer-header__title footer-header__title_customer">
                          Закажите демонстрацию возможностей <br /> платформы Скилла
                        </h2>
                      )}

                      {type === 'contracts' ? (
                        <p className="footer-header__description contracts">
                          Начните работать с исполнителями на платформе. <br /> Эксперт свяжется с вами!
                        </p>
                      ) : type === 'technologies' ? (
                        <p className="footer-header__description">
                          Начните работать с исполнителями на платформе. <br /> Эксперт свяжется с вами!
                        </p>
                      ) : (
                        <p className="footer-header__description">
                          Начните работать с исполнителями на платформе. <br /> Эксперт свяжется с вами!
                        </p>
                      )}

                      {type === 'contracts' || type === 'technologies' ? (
                        <Form page="contracts" title="contracts" />
                      ) : (
                        <Form page="customer" />
                      )}

                      {type === 'contracts' ? (
                        <>
                          <div className={onClickForm && width > 768 ? 'footer__logos mt' : 'footer__logos'}>
                            <div>
                              <img
                                src="/static/customer/header/logos/1.png"
                                alt="Конституционный суд Российской Федераци"
                              />
                              <p className="logos__descr">Конституционный суд Российской Федераци</p>
                            </div>

                            <div>
                              <img src="/static/customer/header/logos/2.png" alt="Правительство Москвы" />
                              <p className="logos__descr">Правительство Москвы</p>
                            </div>

                            <div>
                              <img
                                src="/static/customer/header/logos/3.png"
                                alt="Администрация Санкт-Петербурга"
                              />
                              <p className="logos__descr">Администрация Санкт-Петербурга</p>
                            </div>

                            <div>
                              <img src="/static/customer/header/logos/4.png" alt="МВД РФ" />
                              <p className="logos__descr">МВД РФ</p>
                            </div>
                          </div>

                          <div className="logos-bottom">
                            <img src="/static/customer/header/logos/bottom/1.svg" alt="Сбер" />
                            <img src="/static/customer/header/logos/bottom/2.svg" alt="Втб" />
                            <img src="/static/customer/header/logos/bottom/3.svg" alt="Альфа банк" />
                            <img src="/static/customer/header/logos/bottom/4.svg" alt="М-видео" />
                            <img src="/static/customer/header/logos/bottom/5.svg" alt="Деловые линии" />
                            <img src="/static/customer/header/logos/bottom/6.svg" alt="Магнит" />
                            <img src="/static/customer/header/logos/bottom/7.svg" alt="Мегафон" />
                          </div>
                        </>
                      ) : type === 'technologies' ? (
                        <div className="logos-bottom footer">
                          <picture>
                            <source srcSet="/static/customer/technologies/logos.webp" type="image/webp" />
                            <img src="/static/customer/technologies/logos.png" alt="логотипы" />
                          </picture>
                        </div>
                      ) : (
                        <div className="header__clients">
                          <a
                            href="https://appgallery.huawei.com/#/app/C103862195?channelId=Skilla%20Official&id=1f01b81b103942dc8b9033f135eff478&s=38C79299AC884CC8EAC853E9E0FBDDCF2F32B4BC9BE70C49CE2B51F0D56F567D&detailType=0&v="
                            target="_blank"
                            className="header__client"
                          >
                            <picture>
                              <source srcSet="/static/customer/header/links/01.png" type="image/webp" />
                              <img src="/static/customer/header/links/01.png" alt="изображение" />
                            </picture>
                          </a>

                          <a
                            href="https://skilla.ru/apk/skilla.apk"
                            target="_blank"
                            className="header__client"
                          >
                            <picture>
                              <source srcSet="/static/customer/header/links/02.png" type="image/webp" />
                              <img src="/static/customer/header/links/02.png" alt="изображение" />
                            </picture>
                          </a>

                          <a
                            href="https://play.google.com/store/apps/details?id=com.skilla.Skilla"
                            target="_blank"
                            className="header__client"
                          >
                            <picture>
                              <source srcSet="/static/customer/header/links/03.png" type="image/webp" />
                              <img src="/static/customer/header/links/03.png" alt="изображение" />
                            </picture>
                          </a>

                          <a
                            href="https://apps.apple.com/ru/app/skilla-work/id1531442328"
                            target="_blank"
                            className="header__client"
                          >
                            <picture>
                              <source srcSet="/static/customer/header/links/04.png" type="image/webp" />
                              <img src="/static/customer/header/links/04.png" alt="изображение" />
                            </picture>
                          </a>
                        </div>
                      )}
                    </div>

                    <p
                      className={
                        type === 'contracts'
                          ? 'header__info contracts'
                          : type === 'technologies'
                            ? 'header__info technologies'
                            : 'header__info'
                      }
                    >
                      Отправляя форму, вы соглашаетесь с
                      <Link
                        target="_blank"
                        onClick={() => dispatch(setAddActiveTarget('customer'))}
                        to="/politics"
                      >
                        условиями
                      </Link>
                    </p>
                  </div>

                  {type !== 'contracts' ? (
                    <div className="customer-illustration">
                      <picture>
                        <source srcSet="/static/customer/footer/header/customer2.webp" type="image/webp" />
                        <img
                          src="/static/customer/footer/header/customer2.webp"
                          alt="иллюстрация"
                          className={
                            type === 'technologies'
                              ? 'footer-header__image customer technologies'
                              : 'footer-header__image customer'
                          }
                        />
                      </picture>
                    </div>
                  ) : (
                    ''
                  )}

                  {type === 'contracts' && (
                    <div className="customer-illustration">
                      <picture>
                        <source srcSet="/static/customer/footer/header/customer2.png" type="image/webp" />
                        <img
                          src="/static/customer/footer/header/customer2.png"
                          alt="иллюстрация"
                          className="footer-header__image customer contracts"
                          style={{ marginRight: '-200px' }}
                        />
                      </picture>
                    </div>
                  )}
                </div>
              </header>
            )} */}
            <main className="footer-main">
              <div className="container container_footer-main">
                <div className="footer-main__left">
                  <div onClick={() => window.scroll(0, 0)} className="footer-main__logo">
                    <div>
                      <picture>
                        {/*<source srcSet="/static/logo.webp" type="image/webp" />
                        <img src="/static/logo.png" alt="Skilla" />*/}
                        <source srcSet="/static/customer/Logo_customer.webp" type="image/webp" />
                        <img
                          src="/static/customer/Logo_customer.svg"
                          alt="Skilla заказчикам"
                          className="scrollnav__logo"
                          width={211}
                          height={30}
                        />
                      </picture>
                    </div>
                    <p>Технологичная HR-Tech площадка</p>
                  </div>
                  <div className="footer-main-important">
                    <DreamJob />
                    <div className="footer-hh">
                      <h5 className="footer-main-item__title">Привлекательный работодатель 2023</h5>
                      <picture onClick={handleOpenModalHh}>
                        <source srcSet="/static/customer/footer/main/hhFinall.jpg" type="image/webp" />
                        <img
                          src="/static/customer/footer/main/hhFinall.jpg"
                          alt="hh"
                          className="footer-main-important__image-hh"
                        />
                      </picture>
                    </div>

                    <div className="footer-main-important__links">
                      {activeLink === 'politics-home' ? (
                        <button
                          onClick={() => scrollToForm('politics-home')}
                          className="footer-main-important__link"
                        >
                          Политика конфиденциальности
                        </button>
                      ) : (
                        <Link
                          target="_blank"
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/politics"
                          className="footer-main-important__link"
                        >
                          Политика конфиденциальности
                        </Link>
                      )}

                      <a
                        href="https://reestr.digital.gov.ru/reestr/1284748/"
                        target="_blank"
                        className="footer-main-important__link"
                      >
                        Skilla IS в Реестре российского ПО
                      </a>

                      <a href="https://skilla.ru/svidetelstvo.html" className="footer-main-important__link">
                        Свидетельство о государственной регистрации <br></br> программы для ЭВМ
                      </a>
                    </div>

                    <div className="footer-main-important__images">
                      <a href="#" onClick={(event) => event.preventDefault()}>
                        <picture>
                          <source srcSet="/static/customer/footer/main/pay/01.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/pay/01.png"
                            alt="MasterCard"
                            className="footer-main-important__image"
                          />
                        </picture>
                      </a>
                      <a href="#" onClick={(event) => event.preventDefault()}>
                        <picture>
                          <source srcSet="/static/customer/footer/main/pay/02.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/pay/02.png"
                            alt="МИР"
                            className="footer-main-important__image"
                          />
                        </picture>
                      </a>
                      <a href="#" onClick={(event) => event.preventDefault()}>
                        <picture>
                          <source srcSet="/static/customer/footer/main/pay/03.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/pay/03.png"
                            alt="Visa"
                            className="footer-main-important__image"
                          />
                        </picture>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="footer-main-right">
                  <header className="footer-main-right__header">
                    {/* <div className="footer-main-item">
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/"
                        className="footer-main-item__title"
                      >
                        Заказчикам
                      </Link>

                      <div className="footer-main-item__links">
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/"
                          className="footer-main-item__link"
                        >
                          Главная
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/orders"
                          className="footer-main-item__link"
                        >
                          Виды услуг
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/geography"
                          className="footer-main-item__link"
                        >
                          Карта присутствия
                        </Link>

                        <Link
                          to="/customer/technologies"
                          onClick={() => onActiveTarget('customer', 'customer')}
                          className="footer-main-item__link"
                        >
                          Технологии
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/contracts"
                          className="footer-main-item__link"
                        >
                          Госконтракты и тендеры
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/faq"
                          className="footer-main-item__link"
                        >
                          Вопрос-ответ
                        </Link>
                      </div>

                      {activeLink === 'www.skilla.ru/' ? (
                        <button
                          onClick={() => scrollToForm('www.skilla.ru/')}
                          className="footer-main-item__button"
                        >
                          Разместить заказ
                        </button>
                      ) : (
                        <Link
                          onClick={() => dispatch(setAddActiveLink('www.skilla.ru/'))}
                          to="/"
                          className="footer-main-item__button"
                        >
                          Разместить заказ
                        </Link>
                      )}
                    </div> */}

                    <div className="footer-main-item">
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business"
                        className="footer-main-item__title"
                      >
                        Предпринимателям
                      </Link>

                      <div className="footer-main-item__links">
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business"
                          className="footer-main-item__link"
                        >
                          Главная
                        </Link>

                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/partners"
                          className="footer-main-item__link"
                        >
                          Кейсы
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/training"
                          className="footer-main-item__link"
                        >
                          Обучение
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/heroes"
                          className="footer-main-item__link"
                        >
                          Бизнес-Герои
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/heroes/interview"
                          className="footer-main-item__link"
                        >
                          Большое интервью
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/faq"
                          className="footer-main-item__link"
                        >
                          Вопрос-ответ
                        </Link>

                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/reviews"
                          className="footer-main-item__link"
                        >
                          Отзывы
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/about"
                          className="footer-main-item__link"
                        >
                          О компании
                        </Link>
                        {/*<Link
                          onClick={() => onActiveTarget('business', 'business-franchise')}
                          to="/business/franchise"
                          className="footer-main-item__link"
                        >
                          Стать партнером
                        </Link>*/}
                      </div>

                      {activeLink === 'www.skilla.ru/business/franchise' ? (
                        <button
                          onClick={() => scrollToForm('www.skilla.ru/business/franchise')}
                          className="footer-main-item__button"
                        >
                          Стать партнером
                        </button>
                      ) : (
                        <Link
                          onClick={() => onActiveTarget('business', 'www.skilla.ru/business/franchise')}
                          to="/business/franchise"
                          className="footer-main-item__button"
                        >
                          Стать партнером
                        </Link>
                      )}
                    </div>

                    {/* <div className="footer-main-item">
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker"
                        className="footer-main-item__title"
                      >
                        Исполнителям
                      </Link>

                      <div className="footer-main-item__links">
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker"
                          className="footer-main-item__link"
                        >
                          Главная
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/orders"
                          className="footer-main-item__link"
                        >
                          Виды заказов
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/performers/reviews"
                          className="footer-main-item__link"
                        >
                          Отзывы исполнителей
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/cases"
                          className="footer-main-item__link"
                        >
                          Какие бывают заказчики
                        </Link>

                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/faq"
                          className="footer-main-item__link"
                        >
                          Вопрос-ответ
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/download"
                          className="footer-main-item__link"
                        >
                          Скачать приложение
                        </Link>
                      </div>

                      {activeLink === 'www.skilla.ru/worker/download' ? (
                        <button
                          onClick={() => scrollToForm('www.skilla.ru/worker/download')}
                          className="footer-main-item__button"
                        >
                          Стать исполнителем
                        </button>
                      ) : (
                        <Link
                          onClick={() => onActiveTarget('worker', 'www.skilla.ru/worker/download')}
                          to="/worker/download"
                          className="footer-main-item__button"
                        >
                          Стать исполнителем
                        </Link>
                      )}
                    </div> */}

                    <div className="footer-main-item">
                      <h5 className="footer-main-item__title">Соискателям</h5>
                      <div className="footer__containerqr">
                        <div className="footer-main-item__links">
                          <a href="https://kudrovo.hh.ru/employer/818282" className="footer-main-item__link">
                            Вакансии
                          </a>

                          <a
                            href="https://skilla.ru/3dskilla/"
                            target="_blank"
                            className="footer-main-item__link"
                          >
                            Наш офис
                          </a>
                        </div>
                        {/*<picture className="footer__qr">
                          <source srcSet="/static/customer/footer/main/qr-code.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/qr-code.png"
                            alt="qr-code"
                            className="footer-main-important__qr-code"
                          />
                        </picture>*/}
                      </div>
                    </div>
                  </header>
                  <footer className="footer-main-right__footer">
                    <div className="footer-main-right__images">
                      <a href="https://vk.com/myskilla" target="_blank">
                        <picture>
                          <source srcSet="/static/customer/footer/main/media/VK.svg" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/media/VK.svg"
                            alt="ВК"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                      <a href="https://tenchat.ru/kir_uporov" target="_blank">
                        <picture>
                          <source srcSet="/static/customer/footer/main/media/TenChat.svg" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/media/TenChat.svg"
                            alt="tenchat"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                      <a href="https://rutube.ru/channel/38773211/" target="_blank">
                        <picture>
                          <source srcSet="/static/customer/footer/main/media/rutube.svg" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/media/rutube.svg"
                            alt="YouTube"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                      <a href="https://t.me/skillabiz" target="_blank">
                        <picture>
                          <source
                            srcSet="/static/customer/footer/main/media/telegram.svg"
                            type="image/webp"
                          />
                          <img
                            src="/static/customer/footer/main/media/telegram.svg"
                            alt="YouTube"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                      <a href="https://dzen.ru/skilla?share_to=link" target="_blank">
                        <picture>
                          <source srcSet="/static/customer/footer/main/media/dzen.svg" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/media/dzen.svg"
                            alt="YouTube"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                    </div>
                    <div className="footer-main-right__flex">
                      <p className="footer-main-right__description">
                        <span>Скилла © 2010-{currentYear} Копирование информации запрещено </span>

                        <span>Скилла — зарегистрированный товарный знак, номер свидетельства 771589</span>

                        <span>ООО «Скилла Инновации» ИНН 4706050219</span>

                        <span>Санкт-Петербург, пр. Энергетиков, д.10, оф.416 hi@skilla.ru</span>

                        <span className="footer-main-right__description__bottom">
                          Информация на данном сайте носит информационный характер и не является офертой. ст.
                          437 ч. 1 ГК РФ.
                        </span>
                      </p>
                      <a href="https://lk.skilla.ru" className="footer-main-right__button">
                        <picture>
                          <source srcSet="/static/customer/footer/main/mini-logo.png" type="image/webp" />
                          <img src="/static/customer/footer/main/mini-logo.png" alt="логотип" />
                        </picture>

                        <span className="arrow-right-top-smail icon"></span>
                      </a>
                    </div>
                  </footer>
                </div>
              </div>
            </main>
            {/*{!book && <FooterBook />}*/}
            <footer className="footer-footer">
              <div className="container container_footer-footer">
                <a href="https://apps.apple.com/ru/app/skilla-work/id1531442328" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/01.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/01.png"
                      alt="App Store"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.skilla.Skilla" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/02.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/02.png"
                      alt="Google Play"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                {/*<a href="https://skilla.ru/apk/skilla.apk" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/03.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/03.png"
                      alt="NASHSTORE"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>*/}

                <a
                  href="https://appgallery.huawei.com/#/app/C103862195?channelId=Skilla%20Official&id=1f01b81b103942dc8b9033f135eff478&s=38C79299AC884CC8EAC853E9E0FBDDCF2F32B4BC9BE70C49CE2B51F0D56F567D&detailType=0&v="
                  target="_blank"
                >
                  <picture>
                    <source srcSet="/static/customer/footer/footer/04.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/04.png"
                      alt="AppGallery"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>
              </div>
            </footer>
          </StyledFooter>
        )}
        {width <= 768 && (
          <StyledFooterMobile>
            {type !== 'faq' && (
              <header className="footer-header footer-header">
                {/*   <div className="container container_footer-header">
                  <div className="footer-header__content">
                    <div>
                      {type === 'contracts' ? (
                        <h2 className="footer-header__title footer-header__title_worker">
                          Предложить участие в тендере
                        </h2>
                      ) : type === 'technologies' ? (
                        <>
                          <h2
                            style={{ paddingTop: '70px' }}
                            className="footer-header__title footer-header__title_customer technologies"
                          >
                            Оптимизируй бизнес-процессы с помощью современных <br></br>
                            <span style={{ color: '#ffd500' }}>HR-решений</span>
                          </h2>

                          <p className="footer-header__description">
                            Сообщи нам о планируемом тендере, мы отреагируем незамедлительно
                          </p>
                        </>
                      ) : (
                        <h2 className="footer-header__title footer-header__title_worker">
                          Хотите заказать исполнителей?
                        </h2>
                      )}
                      {type === 'contracts' || type === 'technologies' ? (
                        <Form page="contracts" title="contracts" />
                      ) : (
                        <Form page="customer" />
                      )}
                    </div>
                  </div>

                  <picture>
                    <source srcSet="/static/customer/footer/header/customer2mob.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/header/customer2mob.webp"
                      alt="иллюстрация"
                      className="footer-header__image"
                      style={{ position: 'static', width: '100%', height: 'auto', marginTop: '10px' }}
                    />
                  </picture>
                </div> */}

                {type === 'technologies' && (
                  <div className='logos-bottom footer'>
                    <img
                      src='/static/customer/technologies/logos-mobile.png'
                      alt='логотипы'
                    />
                  </div>
                )}
              </header>
            )}
            <main className="footer-main footer-main_customer">
              <div className="container container_footer-main">
                <div className="footer-main__logo">
                  <div onClick={() => window.scroll(0, 0)}>
                    <picture>
                      <source srcSet="/static/logo.webp" type="image/webp" />
                      <img src="/static/logo.png" alt="Skilla" />

                    </picture>
                  </div>
                  <p>Технологичная HR-Tech площадка</p>

                  <div className="footer-hh">
                    <h5 className="footer-hh__title">Привлекательный работодатель 2023</h5>
                    <picture onClick={handleOpenModalHh}>
                      <source srcSet="/static/customer/footer/main/hhFinall.jpg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/hhFinall.jpg"
                        alt="hh"
                        className="footer-main-important__image-hh"
                      />
                    </picture>

                    <DreamJob type={'mob'} />
                  </div>
                </div>
                <div className="footer-main__items">
                  {/*  <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Заказчикам</h5>

                    <div className="footer-main-item__links">
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/"
                        className="footer-main-item__link"
                      >
                        Главная
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/orders"
                        className="footer-main-item__link"
                      >
                        Виды услуг
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/geography"
                        className="footer-main-item__link"
                      >
                        Карта присутствия
                      </Link>
                      <Link
                        to="/customer/technologies"
                        onClick={() => onActiveTarget('customer', 'customer')}
                        className="footer-main-item__link"
                      >
                        Технологии
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/contracts"
                        className="footer-main-item__link"
                      >
                        Госконтракты и тендеры
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/faq"
                        className="footer-main-item__link"
                      >
                        Вопрос-ответ
                      </Link>
                    </div>
                    {activeLink === 'www.skilla.ru/' ? (
                      <button
                        onClick={() => scrollToForm('www.skilla.ru/')}
                        className="footer-main-item__button"
                      >
                        Разместить заказ
                      </button>
                    ) : (
                      <Link
                        onClick={() => dispatch(setAddActiveLink('www.skilla.ru/'))}
                        to="/"
                        className="footer-main-item__button"
                      >
                        Разместить заказ
                      </Link>
                    )}
                  </div> */}
                  <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Предпринимателям</h5>

                    <div className="footer-main-item__links">
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business"
                        className="footer-main-item__link"
                      >
                        Главная
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/partners"
                        className="footer-main-item__link"
                      >
                        Кейсы
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/training"
                        className="footer-main-item__link"
                      >
                        Обучение
                      </Link>

                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/heroes"
                        className="footer-main-item__link"
                      >
                        Бизнес-Герои
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/heroes/interview"
                        className="footer-main-item__link"
                      >
                        Большое интервью
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/faq"
                        className="footer-main-item__link"
                      >
                        Вопрос-ответ
                      </Link>

                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/reviews"
                        className="footer-main-item__link"
                      >
                        Отзывы
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/about"
                        className="footer-main-item__link"
                      >
                        О компании
                      </Link>
                      {/*<Link
                        onClick={() => onActiveTarget('business', 'business-franchise')}
                        to="/business/franchise"
                        className="footer-main-item__link"
                      >
                        Стать партнером
                      </Link>*/}
                    </div>

                    {activeLink === 'www.skilla.ru/business/franchise' ? (
                      <button
                        onClick={() => scrollToForm('www.skilla.ru/business/franchise')}
                        className="footer-main-item__button"
                      >
                        Стать партнером
                      </button>
                    ) : (
                      <Link
                        onClick={() => onActiveTarget('business', 'www.skilla.ru/business/franchise')}
                        to="/business/franchise"
                        className="footer-main-item__button"
                      >
                        Стать партнером
                      </Link>
                    )}
                  </div>
                  {/* <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Исполнителям</h5>

                    <div className="footer-main-item__links">
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker"
                        className="footer-main-item__link"
                      >
                        Главная
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/orders"
                        className="footer-main-item__link"
                      >
                        Виды заказов
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/performers/reviews"
                        className="footer-main-item__link"
                      >
                        Отзывы исполнителей
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/cases"
                        className="footer-main-item__link"
                      >
                        Какие бывают заказчики
                      </Link>

                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/faq"
                        className="footer-main-item__link"
                      >
                        Вопрос-ответ
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/download"
                        className="footer-main-item__link"
                      >
                        Скачать приложение
                      </Link>
                    </div>

                    {activeLink === 'www.skilla.ru/worker/download' ? (
                      <button
                        onClick={() => scrollToForm('www.skilla.ru/worker/download')}
                        className="footer-main-item__button"
                      >
                        Стать исполнителем
                      </button>
                    ) : (
                      <Link
                        onClick={() => onActiveTarget('worker', 'www.skilla.ru/worker/download')}
                        to="/worker/download"
                        className="footer-main-item__button"
                      >
                        Стать исполнителем
                      </Link>
                    )}
                  </div> */}
                  <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Соискателям</h5>

                    <div className="footer-main-item__links">
                      <a href="https://kudrovo.hh.ru/employer/818282" className="footer-main-item__link">
                        Вакансии
                      </a>

                      <a
                        href="https://skilla.ru/3dskilla/"
                        target="_blank"
                        className="footer-main-item__link"
                      >
                        Наш офис
                      </a>
                    </div>
                  </div>
                </div>
                <div className="footer-main-right__images">
                  <a href="https://vk.com/myskilla" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/VK.svg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/VK.svg"
                        alt="ВК"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                  <a href="https://tenchat.ru/kir_uporov" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/TenChat.svg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/TenChat.svg"
                        alt="tenchat"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                  <a href="https://rutube.ru/channel/38773211/" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/rutube.svg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/rutube.svg"
                        alt="YouTube"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                  <a href="https://t.me/skillabiz" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/telegram.svg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/telegram.svg"
                        alt="YouTube"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                  <a href="https://dzen.ru/skilla?share_to=link" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/dzen.svg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/dzen.svg"
                        alt="YouTube"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                </div>
                <div className="footer-main-important__links">
                  {activeLink === 'politics-home' ? (
                    <button
                      onClick={() => scrollToForm('politics-home')}
                      className="footer-main-important__link"
                    >
                      Политика конфиденциальности
                    </button>
                  ) : (
                    <Link
                      target="_blank"
                      onClick={() => onActiveTarget('customer', 'customer')}
                      to="/politics"
                      className="footer-main-important__link"
                    >
                      Политика конфиденциальности
                    </Link>
                  )}

                  <a
                    href="https://reestr.digital.gov.ru/reestr/1284748/"
                    target="_blank"
                    className="footer-main-important__link"
                  >
                    Skilla IS в Реестре российского ПО
                  </a>

                  <a href="https://skilla.ru/svidetelstvo.html" className="footer-main-important__link">
                    Свидетельство о государственной регистрации <br></br> программы для ЭВМ
                  </a>
                </div>
                <div className="footer-main-important__images">
                  <a href="#" onClick={(event) => event.preventDefault()}>
                    <picture>
                      <source srcSet="/static/customer/footer/main/pay/01.webp" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/pay/01.png"
                        alt="MasterCard"
                        className="footer-main-important__image"
                      />
                    </picture>
                  </a>
                  <a href="#" onClick={(event) => event.preventDefault()}>
                    <picture>
                      <source srcSet="/static/customer/footer/main/pay/02.webp" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/pay/02.png"
                        alt="МИР"
                        className="footer-main-important__image"
                      />
                    </picture>
                  </a>
                  <a href="#" onClick={(event) => event.preventDefault()}>
                    <picture>
                      <source srcSet="/static/customer/footer/main/pay/03.webp" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/pay/03.png"
                        alt="Visa"
                        className="footer-main-important__image"
                      />
                    </picture>
                  </a>
                </div>
                <p className="footer-main-right__description">
                  <span>Skilla © 2010-{currentYear} Копирование информации запрещено </span>

                  <span>Skilla — зарегистрированный товарный знак, номер свидетельства 771589</span>

                  <span>ООО «Скилла Инновации» ИНН 4706050219</span>

                  <span>Санкт-Петербург, пр. Энергетиков, д.10, оф.416 hi@skilla.ru</span>

                  <span className="footer-main-right__description__bottom">
                    Информация на данном сайте носит информационный характер и не является офертой. ст. 437 ч.
                    1 ГК РФ.
                  </span>
                </p>
                <a href="https://lk.skilla.ru" className="footer-main-right__button">
                  <picture>
                    <source srcSet="/static/customer/footer/main/mini-logo.png" type="image/webp" />
                    <img src="/static/customer/footer/main/mini-logo.png" alt="логотип" />
                  </picture>
                  <span className="arrow-right-top-smail icon"></span>
                </a>
              </div>
            </main>
            {/*{!book && <FooterBook />}*/}
            <footer className="footer-footer">
              <div className="container container_footer-footer">
                <a href="https://apps.apple.com/ru/app/skilla-work/id1531442328" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/01.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/01.png"
                      alt="App Store"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.skilla.Skilla" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/02.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/02.png"
                      alt="Google Play"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                {/*<a href="https://skilla.ru/apk/skilla.apk" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/03.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/03.png"
                      alt="NASHSTORE"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>*/}

                <a
                  href="https://appgallery.huawei.com/#/app/C103862195?channelId=Skilla%20Official&id=1f01b81b103942dc8b9033f135eff478&s=38C79299AC884CC8EAC853E9E0FBDDCF2F32B4BC9BE70C49CE2B51F0D56F567D&detailType=0&v="
                  target="_blank"
                >
                  <picture>
                    <source srcSet="/static/customer/footer/footer/04.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/04.png"
                      alt="AppGallery"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>
              </div>
            </footer>
          </StyledFooterMobile>
        )}
      </>
    )

  if (page === 'politics')
    return (
      <>
        {openModal && <ModalHH setOpenModal={setOpenModal} />}
        {width > 768 && (
          <StyledFooter>
            <main className="footer-main">
              <div className="container container_footer-main">
                <div className="footer-main__left">
                  <div onClick={() => window.scroll(0, 0)} className="footer-main__logo">
                    <div>
                      <picture>
                        {/*<source srcSet="/static/logo.webp" type="image/webp" />
                        <img src="/static/logo.png" alt="Skilla" />*/}
                        <img
                          src="/static/customer/Logo_customer.svg"
                          alt="Skilla заказчикам"
                          className="scrollnav__logo"
                          width={211}
                          height={30}
                        />
                      </picture>
                    </div>
                    <p>Технологичная HR-Tech площадка</p>
                  </div>
                  <div className="footer-main-important">
                    <DreamJob />
                    <div className="footer-hh">
                      <h5 className="footer-hh__title">Привлекательный работодатель 2023</h5>
                      <picture onClick={handleOpenModalHh}>
                        <source srcSet="/static/customer/footer/main/hhFinall.jpg" type="image/webp" />
                        <img
                          src="/static/customer/footer/main/hhFinall.jpg"
                          alt="hh"
                          className="footer-main-important__image-hh"
                        />
                      </picture>
                    </div>

                    <div className="footer-main-important__links">
                      {activeLink === 'politics-home' ? (
                        <button
                          onClick={() => scrollToForm('politics-home')}
                          className="footer-main-important__link"
                        >
                          Политика конфиденциальности
                        </button>
                      ) : (
                        <Link
                          target="_blank"
                          onClick={() => onActiveTarget('', 'politics-home')}
                          to="/politics"
                          className="footer-main-important__link"
                        >
                          Политика конфиденциальности
                        </Link>
                      )}

                      <a
                        href="https://reestr.digital.gov.ru/reestr/1284748/"
                        target="_blank"
                        className="footer-main-important__link"
                      >
                        Skilla IS в Реестре российского ПО
                      </a>
                    </div>

                    <div className="footer-main-important__images">
                      <a href="#" onClick={(event) => event.preventDefault()}>
                        <picture>
                          <source srcSet="/static/customer/footer/main/pay/01.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/pay/01.png"
                            alt="MasterCard"
                            className="footer-main-important__image"
                          />
                        </picture>
                      </a>
                      <a href="#" onClick={(event) => event.preventDefault()}>
                        <picture>
                          <source srcSet="/static/customer/footer/main/pay/02.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/pay/02.png"
                            alt="МИР"
                            className="footer-main-important__image"
                          />
                        </picture>
                      </a>
                      <a href="#" onClick={(event) => event.preventDefault()}>
                        <picture>
                          <source srcSet="/static/customer/footer/main/pay/03.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/pay/03.png"
                            alt="Visa"
                            className="footer-main-important__image"
                          />
                        </picture>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="footer-main-right">
                  <header className="footer-main-right__header">
                    {/* <div className="footer-main-item">
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/"
                        className="footer-main-item__title"
                      >
                        Заказчикам
                      </Link>

                      <div className="footer-main-item__links">
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/"
                          className="footer-main-item__link"
                        >
                          Главная
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/orders"
                          className="footer-main-item__link"
                        >
                          Виды услуг
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/geography"
                          className="footer-main-item__link"
                        >
                          Карта присутствия
                        </Link>
                        <Link
                          to="/customer/technologies"
                          onClick={() => onActiveTarget('customer', 'customer')}
                          className="footer-main-item__link"
                        >
                          Технологии
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/contracts"
                          className="footer-main-item__link"
                        >
                          Госконтракты и тендеры
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/faq"
                          className="footer-main-item__link"
                        >
                          Вопрос-ответ
                        </Link>
                      </div>

                      {activeLink === 'www.skilla.ru/' ? (
                        <button
                          onClick={() => scrollToForm('www.skilla.ru/')}
                          className="footer-main-item__button"
                        >
                          Разместить заказ
                        </button>
                      ) : (
                        <Link
                          onClick={() => dispatch(setAddActiveLink('www.skilla.ru/'))}
                          to="/"
                          className="footer-main-item__button"
                        >
                          Разместить заказ
                        </Link>
                      )}
                    </div> */}

                    <div className="footer-main-item">
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business"
                        className="footer-main-item__title"
                      >
                        Предпринимателям
                      </Link>

                      <div className="footer-main-item__links">
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business"
                          className="footer-main-item__link"
                        >
                          Главная
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/partners"
                          className="footer-main-item__link"
                        >
                          Кейсы
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/training"
                          className="footer-main-item__link"
                        >
                          Обучение
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/heroes"
                          className="footer-main-item__link"
                        >
                          Бизнес-Герои
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/heroes/interview"
                          className="footer-main-item__link"
                        >
                          Большое интервью
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/faq"
                          className="footer-main-item__link"
                        >
                          Вопрос-ответ
                        </Link>

                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/reviews"
                          className="footer-main-item__link"
                        >
                          Отзывы
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/about"
                          className="footer-main-item__link"
                        >
                          О компании
                        </Link>
                        {/*<Link
                          onClick={() => onActiveTarget('business', 'business-franchise')}
                          to="/business/franchise"
                          className="footer-main-item__link"
                        >
                          Стать партнером
                        </Link>*/}
                      </div>

                      {activeLink === 'www.skilla.ru/business/franchise' ? (
                        <button
                          onClick={() => scrollToForm('www.skilla.ru/business/franchise')}
                          className="footer-main-item__button"
                        >
                          Стать партнером
                        </button>
                      ) : (
                        <Link
                          onClick={() => onActiveTarget('business', 'www.skilla.ru/business/franchise')}
                          to="/business/franchise"
                          className="footer-main-item__button"
                        >
                          Стать партнером
                        </Link>
                      )}
                    </div>

                    {/* <div className="footer-main-item">
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker"
                        className="footer-main-item__title"
                      >
                        Исполнителям
                      </Link>

                      <div className="footer-main-item__links">
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker"
                          className="footer-main-item__link"
                        >
                          Главная
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/orders"
                          className="footer-main-item__link"
                        >
                          Виды заказов
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/performers/reviews"
                          className="footer-main-item__link"
                        >
                          Отзывы исполнителей
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/cases"
                          className="footer-main-item__link"
                        >
                          Какие бывают заказчики
                        </Link>

                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/faq"
                          className="footer-main-item__link"
                        >
                          Вопрос-ответ
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/download"
                          className="footer-main-item__link"
                        >
                          Скачать приложение
                        </Link>
                      </div>
                      {activeLink === 'www.skilla.ru/worker/download' ? (
                        <button
                          onClick={() => scrollToForm('www.skilla.ru/worker/download')}
                          className="footer-main-item__button"
                        >
                          Стать исполнителем
                        </button>
                      ) : (
                        <Link
                          onClick={() => onActiveTarget('worker', 'www.skilla.ru/worker/download')}
                          to="/worker/download"
                          className="footer-main-item__button"
                        >
                          Стать исполнителем
                        </Link>
                      )}
                    </div> */}

                    <div className="footer-main-item">
                      <h5 className="footer-main-item__title">Соискателям</h5>

                      <div className="footer__containerqr">
                        <div className="footer-main-item__links">
                          <a href="https://kudrovo.hh.ru/employer/818282" className="footer-main-item__link">
                            Вакансии
                          </a>

                          <a
                            href="https://skilla.ru/3dskilla/"
                            target="_blank"
                            className="footer-main-item__link"
                          >
                            Наш офис
                          </a>
                        </div>

                        <picture className="footer__qr">
                          <source srcSet="/static/customer/footer/main/qr-code.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/qr-code.png"
                            alt="qr-code"
                            className="footer-main-important__qr-code"
                          />
                        </picture>
                      </div>
                    </div>
                  </header>
                  <footer className="footer-main-right__footer">
                    <div className="footer-main-right__images">
                      <a href="https://vk.com/myskilla" target="_blank">
                        <picture>
                          <source srcSet="/static/customer/footer/main/media/VK.svg" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/media/VK.svg"
                            alt="ВК"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                      <a href="https://tenchat.ru/kir_uporov" target="_blank">
                        <picture>
                          <source srcSet="/static/customer/footer/main/media/TenChat.svg" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/media/TenChat.svg"
                            alt="tenchat"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                      <a href="https://rutube.ru/channel/38773211/" target="_blank">
                        <picture>
                          <source srcSet="/static/customer/footer/main/media/rutube.svg" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/media/rutube.svg"
                            alt="YouTube"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                      <a href="https://t.me/skillabiz" target="_blank">
                        <picture>
                          <source
                            srcSet="/static/customer/footer/main/media/telegram.svg"
                            type="image/webp"
                          />
                          <img
                            src="/static/customer/footer/main/media/telegram.svg"
                            alt="YouTube"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                      <a href="https://dzen.ru/skilla?share_to=link" target="_blank">
                        <picture>
                          <source srcSet="/static/customer/footer/main/media/dzen.svg" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/media/dzen.svg"
                            alt="YouTube"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                    </div>
                    <div className="footer-main-right__flex">
                      <p className="footer-main-right__description">
                        <span>Skilla © 2010-{currentYear} Копирование информации запрещено </span>

                        <span>Skilla — зарегистрированный товарный знак, номер свидетельства 771589</span>

                        <span>ООО «Скилла Инновации» ИНН 4706050219</span>

                        <span>Санкт-Петербург, пр. Энергетиков, д.10, оф.416 hi@skilla.ru</span>

                        <span className="footer-main-right__description__bottom">
                          Информация на данном сайте носит информационный характер и не является офертой. ст.
                          437 ч. 1 ГК РФ.
                        </span>
                      </p>
                      <a href="https://lk.skilla.ru" className="footer-main-right__button">
                        <picture>
                          <source srcSet="/static/customer/footer/main/mini-logo.png" type="image/webp" />
                          <img src="/static/customer/footer/main/mini-logo.png" alt="логотип" />
                        </picture>
                        <span className="arrow-right-top-smail icon"></span>
                      </a>
                    </div>
                  </footer>
                </div>
              </div>
            </main>
            {/*{!book && <FooterBook />}*/}
            <footer className="footer-footer">
              <div className="container container_footer-footer">
                <a href="https://apps.apple.com/ru/app/skilla-work/id1531442328" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/01.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/01.png"
                      alt="App Store"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.skilla.Skilla" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/02.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/02.png"
                      alt="Google Play"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                {/*<a href="https://skilla.ru/apk/skilla.apk" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/03.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/03.png"
                      alt="NASHSTORE"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>*/}

                <a
                  href="https://appgallery.huawei.com/#/app/C103862195?channelId=Skilla%20Official&id=1f01b81b103942dc8b9033f135eff478&s=38C79299AC884CC8EAC853E9E0FBDDCF2F32B4BC9BE70C49CE2B51F0D56F567D&detailType=0&v="
                  target="_blank"
                >
                  <picture>
                    <source srcSet="/static/customer/footer/footer/04.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/04.png"
                      alt="AppGallery"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>
              </div>
            </footer>
          </StyledFooter>
        )}
        {width <= 768 && (
          <StyledFooterMobile>
            <main className="footer-main footer-main_customer">
              <div className="container container_footer-main">
                <div className="footer-main__logo">
                  <div onClick={() => window.scroll(0, 0)}>
                    <picture>
                      {/*<source srcSet="/static/logo.webp" type="image/webp" />
                      <img src="/static/logo.png" alt="Skilla" />*/}
                      <img
                        src="/static/customer/Logo_customer.svg"
                        alt="Skilla заказчикам"
                        className="scrollnav__logo"
                        width={211}
                        height={30}
                      />
                    </picture>
                  </div>
                  <p>Технологичная HR-Tech площадка</p>

                  <div className="footer-hh">
                    <h5 className="footer-hh__title">Привлекательный работодатель 2023</h5>
                    <picture onClick={handleOpenModalHh}>
                      <source srcSet="/static/customer/footer/main/hhFinall.jpg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/hhFinall.jpg"
                        alt="hh"
                        className="footer-main-important__image-hh"
                      />
                    </picture>
                    <DreamJob type={'mob'} />
                  </div>
                </div>
                <div className="footer-main__items">
                  {/*  <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Заказчикам</h5>

                    <div className="footer-main-item__links">
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/"
                        className="footer-main-item__link"
                      >
                        Главная
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/orders"
                        className="footer-main-item__link"
                      >
                        Виды услуг
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/geography"
                        className="footer-main-item__link"
                      >
                        Карта присутствия
                      </Link>
                      <Link
                        to="/customer/technologies"
                        onClick={() => onActiveTarget('customer', 'customer')}
                        className="footer-main-item__link"
                      >
                        Технологии
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/contracts"
                        className="footer-main-item__link"
                      >
                        Госконтракты и тендеры
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/faq"
                        className="footer-main-item__link"
                      >
                        Вопрос-ответ
                      </Link>
                    </div>

                    {activeLink === 'www.skilla.ru/' ? (
                      <button
                        onClick={() => scrollToForm('www.skilla.ru/')}
                        className="footer-main-item__button"
                      >
                        Разместить заказ
                      </button>
                    ) : (
                      <Link
                        onClick={() => dispatch(setAddActiveLink('www.skilla.ru/'))}
                        to="/"
                        className="footer-main-item__button"
                      >
                        Разместить заказ
                      </Link>
                    )}
                  </div> */}
                  <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Предпринимателям</h5>

                    <div className="footer-main-item__links">
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business"
                        className="footer-main-item__link"
                      >
                        Главная
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/partners"
                        className="footer-main-item__link"
                      >
                        Кейсы
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/training"
                        className="footer-main-item__link"
                      >
                        Обучение
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/heroes"
                        className="footer-main-item__link"
                      >
                        Бизнес-Герои
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/heroes/interview"
                        className="footer-main-item__link"
                      >
                        Большое интервью
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/faq"
                        className="footer-main-item__link"
                      >
                        Вопрос-ответ
                      </Link>

                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/reviews"
                        className="footer-main-item__link"
                      >
                        Отзывы
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/about"
                        className="footer-main-item__link"
                      >
                        О компании
                      </Link>
                      {/*<Link
                        onClick={() => onActiveTarget('business', 'business-franchise')}
                        to="/business/franchise"
                        className="footer-main-item__link"
                      >
                        Стать партнером
                      </Link>*/}
                    </div>

                    {activeLink === 'www.skilla.ru/business/franchise' ? (
                      <button
                        onClick={() => scrollToForm('www.skilla.ru/business/franchise')}
                        className="footer-main-item__button"
                      >
                        Стать партнером
                      </button>
                    ) : (
                      <Link
                        onClick={() => onActiveTarget('business', 'www.skilla.ru/business/franchise')}
                        to="/business/franchise"
                        className="footer-main-item__button"
                      >
                        Стать партнером
                      </Link>
                    )}
                  </div>
                  {/* <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Исполнителям</h5>

                    <div className="footer-main-item__links">
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker"
                        className="footer-main-item__link"
                      >
                        Главная
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/orders"
                        className="footer-main-item__link"
                      >
                        Виды заказов
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/performers/reviews"
                        className="footer-main-item__link"
                      >
                        Отзывы исполнителей
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/cases"
                        className="footer-main-item__link"
                      >
                        Какие бывают заказчики
                      </Link>

                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/faq"
                        className="footer-main-item__link"
                      >
                        Вопрос-ответ
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/download"
                        className="footer-main-item__link"
                      >
                        Скачать приложение
                      </Link>
                    </div>
                    {activeLink === 'www.skilla.ru/worker/download' ? (
                      <button
                        onClick={() => scrollToForm('worwww.skilla.ru/worker/downloadker')}
                        className="footer-main-item__button"
                      >
                        Стать исполнителем
                      </button>
                    ) : (
                      <Link
                        onClick={() => onActiveTarget('worker', 'www.skilla.ru/worker/download')}
                        to="/worker/download"
                        className="footer-main-item__button"
                      >
                        Стать исполнителем
                      </Link>
                    )}
                  </div> */}
                  <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Соискателям</h5>

                    <div className="footer-main-item__links">
                      <a href="https://kudrovo.hh.ru/employer/818282" className="footer-main-item__link">
                        Вакансии
                      </a>

                      <a
                        href="https://skilla.ru/3dskilla/"
                        target="_blank"
                        className="footer-main-item__link"
                      >
                        Наш офис
                      </a>
                    </div>
                  </div>
                </div>
                <div className="footer-main-right__images">
                  <a href="https://vk.com/myskilla" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/VK.svg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/VK.svg"
                        alt="ВК"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                  <a href="https://tenchat.ru/kir_uporov" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/TenChat.svg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/TenChat.svg"
                        alt="tenchat"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                  <a href="https://rutube.ru/channel/38773211/" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/rutube.svg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/rutube.svg"
                        alt="YouTube"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                  <a href="https://t.me/skillabiz" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/telegram.svg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/telegram.svg"
                        alt="YouTube"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                  <a href="https://dzen.ru/skilla?share_to=link" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/dzen.svg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/dzen.svg"
                        alt="YouTube"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                </div>
                <div className="footer-main-important__links">
                  {activeLink === 'politics-home' ? (
                    <button
                      onClick={() => scrollToForm('politics-home')}
                      className="footer-main-important__link"
                    >
                      Политика конфиденциальности
                    </button>
                  ) : (
                    <Link
                      target="_blank"
                      onClick={() => onActiveTarget('', 'politics-home')}
                      to="/politics"
                      className="footer-main-important__link"
                    >
                      Политика конфиденциальности
                    </Link>
                  )}

                  <a
                    href="https://reestr.digital.gov.ru/reestr/1284748/"
                    target="_blank"
                    className="footer-main-important__link"
                  >
                    Skilla IS в Реестре российского ПО
                  </a>

                  <a href="https://skilla.ru/svidetelstvo.html" className="footer-main-important__link">
                    Свидетельство о государственной регистрации <br></br> программы для ЭВМ
                  </a>
                </div>
                <div className="footer-main-important__images">
                  <a href="#" onClick={(event) => event.preventDefault()}>
                    <picture>
                      <source srcSet="/static/customer/footer/main/pay/01.webp" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/pay/01.png"
                        alt="MasterCard"
                        className="footer-main-important__image"
                      />
                    </picture>
                  </a>
                  <a href="#" onClick={(event) => event.preventDefault()}>
                    <picture>
                      <source srcSet="/static/customer/footer/main/pay/02.webp" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/pay/02.png"
                        alt="МИР"
                        className="footer-main-important__image"
                      />
                    </picture>
                  </a>
                  <a href="#" onClick={(event) => event.preventDefault()}>
                    <picture>
                      <source srcSet="/static/customer/footer/main/pay/03.webp" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/pay/03.png"
                        alt="Visa"
                        className="footer-main-important__image"
                      />
                    </picture>
                  </a>
                </div>
                <p className="footer-main-right__description">
                  <span>Skilla © 2010-{currentYear} Копирование информации запрещено </span>

                  <span>Skilla — зарегистрированный товарный знак, номер свидетельства 771589</span>

                  <span>ООО «Скилла Инновации» ИНН 4706050219</span>

                  <span>Санкт-Петербург, пр. Энергетиков, д.10, оф.416 hi@skilla.ru</span>

                  <span className="footer-main-right__description__bottom">
                    Информация на данном сайте носит информационный характер и не является офертой. ст. 437 ч.
                    1 ГК РФ.
                  </span>
                </p>
                <a href="https://lk.skilla.ru" className="footer-main-right__button">
                  <picture>
                    <source srcSet="/static/customer/footer/main/mini-logo.png" type="image/webp" />
                    <img src="/static/customer/footer/main/mini-logo.png" alt="логотип" />
                  </picture>
                  <span className="arrow-right-top-smail icon"></span>
                </a>
              </div>
            </main>
            {/*{!book && <FooterBook />}*/}
            <footer className="footer-footer">
              <div className="container container_footer-footer">
                <a href="https://apps.apple.com/ru/app/skilla-work/id1531442328" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/01.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/01.png"
                      alt="App Store"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.skilla.Skilla" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/02.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/02.png"
                      alt="Google Play"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                {/*<a href="https://skilla.ru/apk/skilla.apk" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/03.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/03.png"
                      alt="NASHSTORE"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>*/}

                <a
                  href="https://appgallery.huawei.com/#/app/C103862195?channelId=Skilla%20Official&id=1f01b81b103942dc8b9033f135eff478&s=38C79299AC884CC8EAC853E9E0FBDDCF2F32B4BC9BE70C49CE2B51F0D56F567D&detailType=0&v="
                  target="_blank"
                >
                  <picture>
                    <source srcSet="/static/customer/footer/footer/04.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/04.png"
                      alt="AppGallery"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>
              </div>
            </footer>
          </StyledFooterMobile>
        )}
      </>
    )

  if (page === 'worker')
    return (
      <div id="2.1.1">
        {openModal && <ModalHH setOpenModal={setOpenModal} />}
        {width > 768 && (
          <StyledFooter>
            {type !== 'faq' && (
              <header className="footer-header footer-header_worker">
                <div className="container container_footer-header">
                  <div
                    className={
                      type !== 'performersReviews'
                        ? 'footer-header__content_worker'
                        : 'footer-header__content_worker--little-bottom'
                    }
                  >
                    <div>
                      {type !== 'performersReviews' ? (
                        <img
                          src="/static/worker/download/icon.png"
                          alt="Skilla работа"
                          className="footer-header__icon"
                        />
                      ) : (
                        ''
                      )}
                      {type !== 'performersReviews' ? (
                        <h2 className="footer-header__title_worker">
                          Скачай <span>приложение</span> и зарабатывай вместе с <br />
                          <span>{numWorkers}+</span> исполнителями
                        </h2>
                      ) : (
                        <h2 className="footer-header__title_worker">
                          Поделись мнением <br></br> про <span>Скилла Работа</span>
                        </h2>
                      )}
                      <div
                        className={
                          type !== 'performersReviews'
                            ? 'header__clients header__clients_worker'
                            : 'header__clients header__clients_worker--margin'
                        }
                      >
                        <a
                          href="https://appgallery.huawei.com/#/app/C103862195?channelId=Skilla%20Official&id=1f01b81b103942dc8b9033f135eff478&s=38C79299AC884CC8EAC853E9E0FBDDCF2F32B4BC9BE70C49CE2B51F0D56F567D&detailType=0&v="
                          target="_blank"
                          className="header__client"
                        >
                          <picture>
                            <source srcSet="/static/customer/header/links/01.png" type="image/webp" />
                            <img src="/static/customer/header/links/01.pngp" alt="AppGallery" />
                          </picture>
                        </a>

                        {/*<a href="https://skilla.ru/apk/skilla.apk" target="_blank" className="header__client">
                          <picture>
                            <source srcSet="/static/customer/header/links/02.png" type="image/webp" />
                            <img src="/static/customer/header/links/02.png" alt="NASHSTORE" />
                          </picture>
                        </a>*/}

                        <a
                          href="https://play.google.com/store/apps/details?id=com.skilla.Skilla"
                          target="_blank"
                          className="header__client"
                        >
                          <picture>
                            <source srcSet="/static/customer/header/links/03.png" type="image/webp" />
                            <img src="/static/customer/header/links/03.png" alt="Google Play" />
                          </picture>
                        </a>

                        <a
                          href="https://apps.apple.com/ru/app/skilla-work/id1531442328"
                          target="_blank"
                          className="header__client"
                        >
                          <picture>
                            <source srcSet="/static/customer/header/links/04.png" type="image/webp" />
                            <img src="/static/customer/header/links/04.png" alt="App Store" />
                          </picture>
                        </a>
                      </div>

                      {type !== 'performersReviews' ? (
                        <img
                          src="/static/worker/download/qr-code.png"
                          alt="qr-code"
                          className="footer-header__qr-code"
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  {type !== 'performersReviews' ? (
                    <picture>
                      <source srcSet="/static/worker/footer/header/illustration.webp" type="image/webp" />
                      <img
                        src="/static/worker/footer/header/illustration.png"
                        alt="иллюстрация"
                        className="footer-header__image footer-header__image_worker"
                      />
                    </picture>
                  ) : (
                    <picture>
                      <source srcSet="/static/worker/footer/header/illustration-2.webp" type="image/webp" />
                      <img
                        src="/static/worker/footer/header/illustration-2.png"
                        alt="иллюстрация"
                        className={
                          type !== 'performersReviews'
                            ? 'footer-header__image footer-header__image_worker'
                            : 'footer-header__image footer-header__image_worker-2'
                        }
                      />
                    </picture>
                  )}
                </div>
              </header>
            )}
            <main className="footer-main footer-main_worker">
              <div className="container container_footer-main">
                <div className="footer-main__left">
                  <div onClick={() => window.scroll(0, 0)} className="footer-main__logo">
                    <div className="footer-main_worker-btn">
                      <div className="footer-main_worker-btn__wrapper">
                        <img
                          src="/static/worker/Logo_worker.svg"
                          alt="Skilla исполнителям"
                          className="scrollnav__logo"
                          width={276}
                          height={30}
                        />
                      </div>
                    </div>
                    <p>Технологичная HR-Tech площадка</p>
                  </div>
                  <div className="footer-main-important">
                    <DreamJob />
                    <div className="footer-hh">
                      <h5 className="footer-hh__title">Привлекательный работодатель 2023</h5>
                      <picture onClick={handleOpenModalHh}>
                        <source srcSet="/static/customer/footer/main/hhFinall.jpg" type="image/webp" />
                        <img
                          src="/static/customer/footer/main/hhFinall.jpg"
                          alt="hh"
                          className="footer-main-important__image-hh"
                        />
                      </picture>
                    </div>

                    <div className="footer-main-important__links">
                      {activeLink === 'politics-home' ? (
                        <button
                          onClick={() => scrollToForm('politics-home')}
                          className="footer-main-important__link"
                        >
                          Политика конфиденциальности
                        </button>
                      ) : (
                        <Link
                          target="_blank"
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/politics"
                          className="footer-main-important__link"
                        >
                          Политика конфиденциальности
                        </Link>
                      )}
                      <a
                        href="https://reestr.digital.gov.ru/reestr/1284748/"
                        target="_blank"
                        className="footer-main-important__link"
                      >
                        Skilla IS в Реестре российского ПО
                      </a>

                      <a href="https://skilla.ru/svidetelstvo.html" className="footer-main-important__link">
                        Свидетельство о государственной регистрации <br></br> программы для ЭВМ
                      </a>
                    </div>

                    <div className="footer-main-important__images">
                      <a href="#" onClick={(event) => event.preventDefault()}>
                        <picture>
                          <source srcSet="/static/customer/footer/main/pay/01.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/pay/01.png"
                            alt="MasterCard"
                            className="footer-main-important__image"
                          />
                        </picture>
                      </a>
                      <a href="#" onClick={(event) => event.preventDefault()}>
                        <picture>
                          <source srcSet="/static/customer/footer/main/pay/02.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/pay/02.png"
                            alt="МИР"
                            className="footer-main-important__image"
                          />
                        </picture>
                      </a>
                      <a href="#" onClick={(event) => event.preventDefault()}>
                        <picture>
                          <source srcSet="/static/customer/footer/main/pay/03.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/pay/03.png"
                            alt="Visa"
                            className="footer-main-important__image"
                          />
                        </picture>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="footer-main-right">
                  <header className="footer-main-right__header">
                    {/*  <div className="footer-main-item">
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer-home')}
                        to="/"
                        className="footer-main-item__title"
                      >
                        Заказчикам
                      </Link>

                      <div className="footer-main-item__links">
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/"
                          className="footer-main-item__link"
                        >
                          Главная
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/orders"
                          className="footer-main-item__link"
                        >
                          Виды услуг
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/geography"
                          className="footer-main-item__link"
                        >
                          Карта присутствия
                        </Link>
                        <Link
                          to="/customer/technologies"
                          onClick={() => onActiveTarget('customer', 'customer')}
                          className="footer-main-item__link"
                        >
                          Технологии
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/contracts"
                          className="footer-main-item__link"
                        >
                          Госконтракты и тендеры
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/faq"
                          className="footer-main-item__link"
                        >
                          Вопрос-ответ
                        </Link>
                      </div>
                      {activeLink === 'www.skilla.ru/' ? (
                        <button
                          onClick={() => scrollToForm('www.skilla.ru/')}
                          className="footer-main-item__button"
                        >
                          Разместить заказ
                        </button>
                      ) : (
                        <Link
                          onClick={() => dispatch(setAddActiveLink('www.skilla.ru/'))}
                          to="/"
                          className="footer-main-item__button"
                        >
                          Разместить заказ
                        </Link>
                      )}
                    </div> */}

                    <div className="footer-main-item">
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business"
                        className="footer-main-item__title"
                      >
                        Предпринимателям
                      </Link>

                      <div className="footer-main-item__links">
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business"
                          className="footer-main-item__link"
                        >
                          Главная
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/partners"
                          className="footer-main-item__link"
                        >
                          Кейсы
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/training"
                          className="footer-main-item__link"
                        >
                          Обучение
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/heroes"
                          className="footer-main-item__link"
                        >
                          Бизнес-Герои
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/heroes/interview"
                          className="footer-main-item__link"
                        >
                          Большое интервью
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/faq"
                          className="footer-main-item__link"
                        >
                          Вопрос-ответ
                        </Link>

                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/reviews"
                          className="footer-main-item__link"
                        >
                          Отзывы
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/about"
                          className="footer-main-item__link"
                        >
                          О компании
                        </Link>
                        {/*<Link
                          onClick={() => onActiveTarget('business', 'business-franchise')}
                          to="/business/franchise"
                          className="footer-main-item__link"
                        >
                          Стать партнером
                        </Link>*/}
                      </div>
                      {activeLink === 'www.skilla.ru/business/franchise' ? (
                        <button
                          onClick={() => scrollToForm('www.skilla.ru/business/franchise')}
                          className="footer-main-item__button"
                        >
                          Стать партнером
                        </button>
                      ) : (
                        <Link
                          onClick={() => onActiveTarget('business', 'www.skilla.ru/business/franchise')}
                          to="/business/franchise"
                          className="footer-main-item__button"
                        >
                          Стать партнером
                        </Link>
                      )}
                    </div>

                    {/*  <div className="footer-main-item">
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker"
                        className="footer-main-item__title"
                      >
                        Исполнителям
                      </Link>

                      <div className="footer-main-item__links">
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker"
                          className="footer-main-item__link"
                        >
                          Главная
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/orders"
                          className="footer-main-item__link"
                        >
                          Виды заказов
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/performers/reviews"
                          className="footer-main-item__link"
                        >
                          Отзывы исполнителей
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/cases"
                          className="footer-main-item__link"
                        >
                          Какие бывают заказчики
                        </Link>

                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/faq"
                          className="footer-main-item__link"
                        >
                          Вопрос-ответ
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/download"
                          className="footer-main-item__link"
                        >
                          Скачать приложение
                        </Link>
                      </div>

                      {activeLink === 'www.skilla.ru/worker/download' ? (
                        <button
                          onClick={() => scrollToForm('www.skilla.ru/worker/download')}
                          className="footer-main-item__button"
                        >
                          Стать исполнителем
                        </button>
                      ) : (
                        <Link
                          onClick={() => onActiveTarget('worker', 'www.skilla.ru/worker/download')}
                          to="/worker/download"
                          className="footer-main-item__button"
                        >
                          Стать исполнителем
                        </Link>
                      )}
                    </div> */}

                    <div className="footer-main-item">
                      <h5 className="footer-main-item__title">Соискателям</h5>

                      <div className="footer__containerqr">
                        <div className="footer-main-item__links">
                          <a href="https://kudrovo.hh.ru/employer/818282" className="footer-main-item__link">
                            Вакансии
                          </a>

                          <a
                            href="https://skilla.ru/3dskilla/"
                            target="_blank"
                            className="footer-main-item__link"
                          >
                            Наш офис
                          </a>
                        </div>

                        <picture className="footer__qr">
                          <source srcSet="/static/customer/footer/main/qr-code.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/qr-code.png"
                            alt="qr-code"
                            className="footer-main-important__qr-code"
                          />
                        </picture>
                      </div>
                    </div>
                  </header>
                  <footer className="footer-main-right__footer">
                    <div className="footer-main-right__images">
                      <a href="https://vk.com/myskilla" target="_blank">
                        <picture>
                          <source srcSet="/static/customer/footer/main/media/VK.svg" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/media/VK.svg"
                            alt="ВК"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                      <a href="https://tenchat.ru/kir_uporov" target="_blank">
                        <picture>
                          <source srcSet="/static/customer/footer/main/media/TenChat.svg" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/media/TenChat.svg"
                            alt="tenchat"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                      <a href="https://rutube.ru/channel/38773211/" target="_blank">
                        <picture>
                          <source srcSet="/static/customer/footer/main/media/rutube.svg" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/media/rutube.svg"
                            alt="YouTube"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                      <a href="https://t.me/skillabiz" target="_blank">
                        <picture>
                          <source
                            srcSet="/static/customer/footer/main/media/telegram.svg"
                            type="image/webp"
                          />
                          <img
                            src="/static/customer/footer/main/media/telegram.svg"
                            alt="YouTube"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                      <a href="https://dzen.ru/skilla?share_to=link" target="_blank">
                        <picture>
                          <source srcSet="/static/customer/footer/main/media/dzen.svg" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/media/dzen.svg"
                            alt="YouTube"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                    </div>
                    <div className="footer-main-right__flex">
                      <p className="footer-main-right__description">
                        <span>Skilla © 2010-{currentYear} Копирование информации запрещено </span>

                        <span>Skilla — зарегистрированный товарный знак, номер свидетельства 771589</span>

                        <span>ООО «Скилла Инновации» ИНН 4706050219</span>

                        <span>Санкт-Петербург, пр. Энергетиков, д.10, оф.416 hi@skilla.ru</span>

                        <span className="footer-main-right__description__bottom">
                          Информация на данном сайте носит информационный характер и не является офертой. ст.
                          437 ч. 1 ГК РФ.
                        </span>
                      </p>
                      <a href="https://lk.skilla.ru" className="footer-main-right__button">
                        <picture>
                          <source srcSet="/static/customer/footer/main/mini-logo.png" type="image/webp" />
                          <img src="/static/customer/footer/main/mini-logo.png" alt="логотип" />
                        </picture>
                        <span className="arrow-right-top-smail icon"></span>
                      </a>
                    </div>
                  </footer>
                </div>
              </div>
            </main>
            {/*{!book && <FooterBook />}*/}
            <footer className="footer-footer">
              <div className="container container_footer-footer">
                <a href="https://apps.apple.com/ru/app/skilla-work/id1531442328" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/01.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/01.png"
                      alt="App Store"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.skilla.Skilla" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/02.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/02.png"
                      alt="Google Play"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                {/*<a href="https://skilla.ru/apk/skilla.apk" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/03.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/03.png"
                      alt="NASHSTORE"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>*/}

                <a
                  href="https://appgallery.huawei.com/#/app/C103862195?channelId=Skilla%20Official&id=1f01b81b103942dc8b9033f135eff478&s=38C79299AC884CC8EAC853E9E0FBDDCF2F32B4BC9BE70C49CE2B51F0D56F567D&detailType=0&v="
                  target="_blank"
                >
                  <picture>
                    <source srcSet="/static/customer/footer/footer/04.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/04.png"
                      alt="AppGallery"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>
              </div>
            </footer>
          </StyledFooter>
        )}
        {width <= 768 && (
          <StyledFooterMobile>
            {type !== 'faq' && (
              <header className="footer-header footer-header_worker">
                <div className="container container_footer-header">
                  <div className="footer-header__content_worker">
                    <div>
                      <img
                        src="/static/worker/download/icon.png"
                        alt="Skilla работа"
                        className="footer-header__icon"
                      />

                      {type !== 'performersReviews' ? (
                        <h2 className="footer-header__title footer-header__title_worker">
                          Скачай <span>приложение</span> и зарабатывай вместе с <br />
                          <span>{numWorkers}+</span> исполнителями
                        </h2>
                      ) : (
                        <h2 className="footer-header__title footer-header__title_worker">
                          Поделись мнением <br></br> про <span>Skilla Работа</span>
                        </h2>
                      )}

                      {type === 'performersReviews' && (
                        <button
                          className="performersReviews__btn"
                          onClick={() => navigate('/worker/download')}
                        >
                          Оставить отзыв
                          <span className="down icon"></span>
                        </button>
                      )}

                      {type !== 'performersReviews' && (
                        <div className="header__clients header__clients_worker">
                          <a
                            href="https://appgallery.huawei.com/#/app/C103862195?channelId=Skilla%20Official&id=1f01b81b103942dc8b9033f135eff478&s=38C79299AC884CC8EAC853E9E0FBDDCF2F32B4BC9BE70C49CE2B51F0D56F567D&detailType=0&v="
                            target="_blank"
                            className="header__client"
                          >
                            <picture>
                              <source srcSet="/static/customer/header/links/01.png" type="image/webp" />
                              <img src="/static/customer/header/links/01.png" alt="AppGallery" />
                            </picture>
                          </a>

                          {/*<a
                            href="https://skilla.ru/apk/skilla.apk"
                            target="_blank"
                            className="header__client"
                          >
                            <picture>
                              <source srcSet="/static/customer/header/links/02.png" type="image/webp" />
                              <img src="/static/customer/header/links/02.png" alt="NASHSTORE" />
                            </picture>
                          </a>*/}

                          <a
                            href="https://play.google.com/store/apps/details?id=com.skilla.Skilla"
                            target="_blank"
                            className="header__client"
                          >
                            <picture>
                              <source srcSet="/static/customer/header/links/03.png" type="image/webp" />
                              <img src="/static/customer/header/links/03.png" alt="Google Play" />
                            </picture>
                          </a>

                          <a
                            href="https://apps.apple.com/ru/app/skilla-work/id1531442328"
                            target="_blank"
                            className="header__client"
                          >
                            <picture>
                              <source srcSet="/static/customer/header/links/04.png" type="image/webp" />
                              <img src="/static/customer/header/links/04.png" alt="App Store" />
                            </picture>
                          </a>
                        </div>
                      )}

                      {type !== 'performersReviews' && (
                        <img
                          src="/static/worker/download/qr-code.png"
                          alt="qr-code"
                          className="footer-header__qr-code"
                        />
                      )}
                    </div>
                  </div>
                  {type !== 'performersReviews' ? (
                    <picture>
                      <source
                        srcSet="/static/worker/footer/header/illustration-mobile.webp"
                        type="image/webp"
                      />
                      <img
                        src="/static/worker/footer/header/illustration-mobile.png"
                        alt="иллюстрация"
                        className="footer-header__image footer-header__image_worker"
                      />
                    </picture>
                  ) : (
                    <picture>
                      <source
                        srcSet="/static/worker/footer/header/illustration-performers-mobile.webp"
                        type="image/webp"
                      />
                      <img
                        src="/static/worker/footer/header/illustration-performers-mobile.png"
                        alt="иллюстрация"
                        className="footer-header__image footer-header__image-performers"
                      />
                    </picture>
                  )}
                </div>
              </header>
            )}
            <main className="footer-main footer-main_worker">
              <div className="container container_footer-main">
                <div onClick={() => window.scroll(0, 0)} className="footer-main_worker-btn">
                  <div className="footer-main_worker-btn__wrapper">
                    <img
                      src="/static/worker/Logo_worker.svg"
                      alt="Skilla исполнителям"
                      className="scrollnav__logo"
                      width={276}
                      height={30}
                    />
                  </div>
                  <p>Технологичная HR-Tech площадка</p>

                  <div className="footer-hh">
                    <h5 className="footer-hh__title">Привлекательный работодатель 2023</h5>
                    <picture onClick={handleOpenModalHh}>
                      <source srcSet="/static/customer/footer/main/hhFinall.jpg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/hhFinall.jpg"
                        alt="hh"
                        className="footer-main-important__image-hh"
                      />
                    </picture>

                    <DreamJob type={'mob'} />
                  </div>
                </div>
                <div className="footer-main__items">
                  {/* <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Заказчикам</h5>

                    <div className="footer-main-item__links">
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/"
                        className="footer-main-item__link"
                      >
                        Главная
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/orders"
                        className="footer-main-item__link"
                      >
                        Виды услуг
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/geography"
                        className="footer-main-item__link"
                      >
                        Карта присутствия
                      </Link>
                      <Link
                        to="/customer/technologies"
                        onClick={() => onActiveTarget('customer', 'customer')}
                        className="footer-main-item__link"
                      >
                        Технологии
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/contracts"
                        className="footer-main-item__link"
                      >
                        Госконтракты и тендеры
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/faq"
                        className="footer-main-item__link"
                      >
                        Вопрос-ответ
                      </Link>
                    </div>

                    {activeLink === 'www.skilla.ru/' ? (
                      <button
                        onClick={() => scrollToForm('www.skilla.ru/')}
                        className="footer-main-item__button"
                      >
                        Разместить заказ
                      </button>
                    ) : (
                      <Link
                        onClick={() => dispatch(setAddActiveLink('www.skilla.ru/'))}
                        to="/"
                        className="footer-main-item__button"
                      >
                        Разместить заказ
                      </Link>
                    )}
                  </div> */}
                  <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Предпринимателям</h5>

                    <div className="footer-main-item__links">
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business"
                        className="footer-main-item__link"
                      >
                        Главная
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/partners"
                        className="footer-main-item__link"
                      >
                        Кейсы
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/training"
                        className="footer-main-item__link"
                      >
                        Обучение
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/heroes"
                        className="footer-main-item__link"
                      >
                        Бизнес-Герои
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/heroes/interview"
                        className="footer-main-item__link"
                      >
                        Большое интервью
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/faq"
                        className="footer-main-item__link"
                      >
                        Вопрос-ответ
                      </Link>

                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/reviews"
                        className="footer-main-item__link"
                      >
                        Отзывы
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/about"
                        className="footer-main-item__link"
                      >
                        О компании
                      </Link>
                      {/*<Link
                        onClick={() => onActiveTarget('business', 'business-franchise')}
                        to="/business/franchise"
                        className="footer-main-item__link"
                      >
                        Стать партнером
                      </Link>*/}
                    </div>
                    {activeLink === 'www.skilla.ru/business/franchise' ? (
                      <button
                        onClick={() => scrollToForm('www.skilla.ru/business/franchise')}
                        className="footer-main-item__button"
                      >
                        Стать партнером
                      </button>
                    ) : (
                      <Link
                        onClick={() => onActiveTarget('business', 'www.skilla.ru/business/franchise')}
                        to="/business/franchise"
                        className="footer-main-item__button"
                      >
                        Стать партнером
                      </Link>
                    )}
                  </div>
                  {/* <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Исполнителям</h5>

                    <div className="footer-main-item__links">
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker"
                        className="footer-main-item__link"
                      >
                        Главная
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/orders"
                        className="footer-main-item__link"
                      >
                        Виды заказов
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/performers/reviews"
                        className="footer-main-item__link"
                      >
                        Отзывы исполнителей
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/cases"
                        className="footer-main-item__link"
                      >
                        Какие бывают заказчики
                      </Link>

                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/faq"
                        className="footer-main-item__link"
                      >
                        Вопрос-ответ
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/download"
                        className="footer-main-item__link"
                      >
                        Скачать приложение
                      </Link>
                    </div>
                    {activeLink === 'www.skilla.ru/worker/download' ? (
                      <button
                        onClick={() => scrollToForm('www.skilla.ru/worker/download')}
                        className="footer-main-item__button"
                      >
                        Стать исполнителем
                      </button>
                    ) : (
                      <Link
                        onClick={() => onActiveTarget('worker', 'www.skilla.ru/worker/download')}
                        to="/worker/download"
                        className="footer-main-item__button"
                      >
                        Стать исполнителем
                      </Link>
                    )}
                  </div> */}
                  <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Соискателям</h5>

                    <div className="footer-main-item__links">
                      <a href="https://kudrovo.hh.ru/employer/818282" className="footer-main-item__link">
                        Вакансии
                      </a>

                      <a
                        href="https://skilla.ru/3dskilla/"
                        target="_blank"
                        className="footer-main-item__link"
                      >
                        Наш офис
                      </a>
                    </div>
                  </div>
                </div>
                <div className="footer-main-right__images">
                  <a href="https://vk.com/myskilla" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/VK.svg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/VK.svg"
                        alt="ВК"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                  <a href="https://tenchat.ru/kir_uporov" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/TenChat.svg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/TenChat.svg"
                        alt="tenchat"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                  <a href="https://rutube.ru/channel/38773211/" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/rutube.svg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/rutube.svg"
                        alt="YouTube"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                  <a href="https://t.me/skillabiz" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/telegram.svg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/telegram.svg"
                        alt="YouTube"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                  <a href="https://dzen.ru/skilla?share_to=link" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/dzen.svg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/dzen.svg"
                        alt="YouTube"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                </div>
                <div className="footer-main-important__links">
                  {activeLink === 'politics-home' ? (
                    <button
                      onClick={() => scrollToForm('politics-home')}
                      className="footer-main-important__link"
                    >
                      Политика конфиденциальности
                    </button>
                  ) : (
                    <Link
                      target="_blank"
                      onClick={() => onActiveTarget('worker', 'worker')}
                      to="/politics"
                      className="footer-main-important__link"
                    >
                      Политика конфиденциальности
                    </Link>
                  )}

                  <a
                    href="https://reestr.digital.gov.ru/reestr/1284748/"
                    target="_blank"
                    className="footer-main-important__link"
                  >
                    Skilla IS в Реестре российского ПО
                  </a>

                  <a href="https://skilla.ru/svidetelstvo.html" className="footer-main-important__link">
                    Свидетельство о государственной регистрации <br></br> программы для ЭВМ
                  </a>
                </div>
                <div className="footer-main-important__images">
                  <a href="#" onClick={(event) => event.preventDefault()}>
                    <picture>
                      <source srcSet="/static/customer/footer/main/pay/01.webp" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/pay/01.png"
                        alt="MasterCard"
                        className="footer-main-important__image"
                      />
                    </picture>
                  </a>
                  <a href="#" onClick={(event) => event.preventDefault()}>
                    <picture>
                      <source srcSet="/static/customer/footer/main/pay/02.webp" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/pay/02.png"
                        alt="МИР"
                        className="footer-main-important__image"
                      />
                    </picture>
                  </a>
                  <a href="#" onClick={(event) => event.preventDefault()}>
                    <picture>
                      <source srcSet="/static/customer/footer/main/pay/03.webp" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/pay/03.png"
                        alt="Visa"
                        className="footer-main-important__image"
                      />
                    </picture>
                  </a>
                </div>
                <p className="footer-main-right__description">
                  <span>Skilla © 2010-{currentYear} Копирование информации запрещено </span>

                  <span>Skilla — зарегистрированный товарный знак, номер свидетельства 771589</span>

                  <span>ООО «Скилла Инновации» ИНН 4706050219</span>

                  <span>Санкт-Петербург, пр. Энергетиков, д.10, оф.416 hi@skilla.ru</span>

                  <span className="footer-main-right__description__bottom">
                    Информация на данном сайте носит информационный характер и не является офертой. ст. 437 ч.
                    1 ГК РФ.
                  </span>
                </p>
                <a href="https://lk.skilla.ru" className="footer-main-right__button">
                  <picture>
                    <source srcSet="/static/customer/footer/main/mini-logo.png" type="image/webp" />
                    <img src="/static/customer/footer/main/mini-logo.png" alt="логотип" />
                  </picture>
                  <span className="arrow-right-top-smail icon"></span>
                </a>
              </div>
            </main>
            {/*{!book && <FooterBook />}*/}
            <footer className="footer-footer">
              <div className="container container_footer-footer">
                <a href="https://apps.apple.com/ru/app/skilla-work/id1531442328" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/01.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/01.png"
                      alt="App Store"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.skilla.Skilla" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/02.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/02.png"
                      alt="Google Play"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                {/*<a href="https://skilla.ru/apk/skilla.apk" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/03.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/03.png"
                      alt="NASHSTORE"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>*/}

                <a
                  href="https://appgallery.huawei.com/#/app/C103862195?channelId=Skilla%20Official&id=1f01b81b103942dc8b9033f135eff478&s=38C79299AC884CC8EAC853E9E0FBDDCF2F32B4BC9BE70C49CE2B51F0D56F567D&detailType=0&v="
                  target="_blank"
                >
                  <picture>
                    <source srcSet="/static/customer/footer/footer/04.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/04.png"
                      alt="AppGallery"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>
              </div>
            </footer>
          </StyledFooterMobile>
        )}
      </div>
    )

  if (page === 'business')
    return (
      <>
        {openModal && <ModalHH setOpenModal={setOpenModal} />}
        {width > 768 && (
          <StyledFooter id={id}>
            {type !== 'faq' && type !== 'diagnostics' && type !== 'vip' && (
              <header
                ref={formRef}
                className={
                  onClickForm
                    ? 'footer-header footer-header_business pt'
                    : 'footer-header footer-header_business'
                }
              >
                <div className="container container_footer-header">
                  <div className="footer-header__content_business">
                    <div>
                      {type === 'main' ? (
                        <>
                          <h2
                            style={{ marginBottom: onClick ? '150px' : '' }}
                            className="footer-header__title footer-header__title_business footer-header__title_business_main"
                          >
                            Получи расчет <br></br> <span>финансовых показателей</span>
                          </h2>
                          <p className="footer-header__description">
                            Расскажем, как открыть бизнес и окупить расходы за полгода
                          </p>
                        </>
                      ) : type === 'about' ? (
                        <h2
                          style={{ marginBottom: onClick ? '50px' : '' }}
                          className="footer-header__title about"
                        >
                          Думаешь, <span>как открыть бизнес?</span> Оставь заявку и пройди обучение в
                          Санкт-Петербурге
                        </h2>
                      ) : (
                        <>
                          {title === 'training' ? (
                            <div>
                              <h2
                                style={{ marginBottom: onClick ? '90px' : '' }}
                                className="footer-header__title footer-header__title_business"
                              >
                                Не упусти шанс и открой <span>партнерский офис</span> Скилла <br></br> в своем
                                городе
                              </h2>

                              <p className="footer-header__description">
                                Расскажем, сколько можно заработать
                              </p>
                            </div>
                          ) : (
                            <>
                              <h2
                                style={{ marginBottom: onClick ? '300px' : '' }}
                                className="footer-header__title footer-header__title_business"
                              >
                                Партнерский офис <br></br> и <span>собственный бизнес</span> <br /> в твоём
                                городе
                              </h2>

                              <p className="footer-header__description">
                                Возможность открыть бизнес с минимальными вложениями и зарабатывать в сфере
                                аутсорсинга. <br />
                                <br />
                                Узнай, сколько можно заработать
                              </p>
                            </>
                          )}
                        </>
                      )}

                      {type === 'about' ? (
                        <Form page="business" type="about-footer" />
                      ) : (
                        <Form page="business" type="main" inputRef={inputRefFooter} />
                      )}

                      <p className="header__info">
                        Отправляя форму, вы соглашаетесь с
                        <Link
                          target="_blank"
                          onClick={() => dispatch(setAddActiveTarget('business'))}
                          to="/politics"
                        >
                          условиями
                        </Link>
                      </p>
                    </div>
                    {type === 'franchise' ? (
                      <div className="grid-container">
                        <div className="post-tag">
                          <a href="#">франшиза купить</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">франшиза цена</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">открытая франшиза</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">franchise</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">франшиза сколько</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">малая франшиза</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">топ франшиз</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">лучшие франшизы</a>
                        </div>
                      </div>
                    ) : title === 'reviews' ? (
                      <div className="grid-container">
                        <div className="post-tag">
                          <a href="#">skilla франшиза</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">скилла франшиза</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">ооо скилла отзывы</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">skilla отзывы</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">скилла инновации отзывы</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">skilla ru отзывы</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">скилла отзывы</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">отзывы о франшизах</a>
                        </div>
                      </div>
                    ) : title === 'business' ? (
                      <div className="grid-container">
                        <div className="post-tag">
                          <a href="#">какой бизнес открыть</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">какой бизнес можно открыть</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">как открыть свой бизнес</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">открыть бизнес с минимальными вложениями</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">как стать бизнесменом</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">хочу стать бизнесменом</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">где открыть бизнес</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">бизнес идеи для начинающих</a>
                        </div>
                      </div>
                    ) : type === 'about' ? (
                      <div className="grid-container">
                        <div className="post-tag">
                          <a href="#">обучение бизнесу</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">управление бизнесом обучение</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">программы обучения бизнесу</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">обучение бизнес процессам</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">ведение бизнеса</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">создание бизнеса</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">создание бизнес процессов</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">обучение индивидуального предпринимателя</a>
                        </div>
                      </div>
                    ) : title === 'partners' ? (
                      <div className="grid-container">
                        <div className="post-tag">
                          <a href="#">бизнес кейс</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">бизнес кейсы примеры</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">бизнес интервью</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">отзывы владельцев бизнеса</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">хочу стать бизнесменом</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">стать частным предпринимателем</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">предпринимательское дело</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">предпринимательство бизнес</a>
                        </div>
                      </div>
                    ) : title === 'reviews' ? (
                      <div className="grid-container">
                        <div className="post-tag">
                          <a href="#">skilla франшиза</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">помощь в развитии бизнеса</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">начало бизнеса с нуля</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">обучение бизнесу</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">обучение бизнес процессам</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">частное партнерство</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">партнерство в сфере труда</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">партнерство развитие</a>
                        </div>
                      </div>
                    ) : type === 'heroes' && page === 'business' ? (
                      <div className="grid-container">
                        <div className="post-tag">
                          <a href="#">малый бизнес москвы</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">купить бизнес в москве</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">бизнес в россии</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">бизнес мероприятия</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">бизнес мероприятия 2024</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">бизнес мероприятия 2025</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">бизнес мероприятия в москве</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">герои бизнеса</a>
                        </div>
                      </div>
                    ) : title === 'faq' ? (
                      <div className="grid-container">
                        <div className="post-tag">
                          <a href="#">бизнес процесс</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">управление бизнес процессами</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">обучение бизнесу</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">условия франшизы</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">франшизы в россии</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">без франшизы</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">купить бизнес франшиза</a>
                        </div>
                        <div className="post-tag">
                          <a href="#">недорогие франшизы</a>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {activeLink === 'www.skilla.ru/business/franchise' ? (
                    firstVideo ? (
                      <div className="triggers__video" style={{ marginRight: '200px' }}>
                        <iframe
                          id="ytplayer"
                          type="text/html"
                          width="200%"
                          height="60%"
                          src="https://rutube.ru/play/embed/bfe36022288e54d74c1d89a040d912c3/"
                          frameborder="0"
                          allowFullScreen
                        ></iframe>
                      </div>
                    ) : (
                      <picture className="triggers__video">
                        <img
                          className="middle-heroes__preview"
                          onClick={() => setFirstVideo(true)}
                          width={width > 1025 ? 720 : 343}
                          height={width > 1025 ? 405 : 192}
                          src="/static/business/events-heroes/preview-img/self-employed.jpg"
                          alt="Открыть видео"
                          style={{ cursor: 'pointer' }}
                        />
                      </picture>
                    )
                  ) : (
                    <picture>
                      <source srcSet="/static/business/footer/header/illustration.webp" type="image/webp" />
                      <img
                        src="/static/business/footer/header/illustration.webp"
                        alt="иллюстрация"
                        className={
                          activeLink === 'www.skilla.ru/business/training'
                            ? 'footer-header__image footer-header__image_training'
                            : activeLink === 'www.skilla.ru/business/training'
                              ? 'footer-header__image footer-header__image_training'
                              : activeLink === 'www.skilla.ru/business/reviews'
                                ? 'footer-header__image footer-header__image_reviews'
                                : ' footer-header__image footer-header__image_business'
                        }
                      />
                    </picture>
                  )}
                </div>
              </header>
            )}

            {type === 'vip' && (
              <header className={'footer-header footer-header_business footer-header_vip'}>
                <div className="container container_footer-header">
                  <div className="footer-header__content_business">
                    <div
                      style={{
                        marginBottom: (isCheckFromPage || user?.user?.code) && '350px',
                      }}
                    >
                      <h2 className="footer-header__title">
                        Быть с лучшими — <br></br>
                        <p>ключ к развитию бизнеса и жизни на 100%</p>
                      </h2>
                      {!isCheckFromPage && !user?.user?.code && <Form page="business" type="vip" />}

                      {removeDate >= 0 && (
                        <p className="header__info vip">
                          Предложение действительно только до {endPremium} {yearNow}
                        </p>
                      )}
                    </div>
                  </div>

                  <picture>
                    <source srcSet="/static/vip/footer.webp" type="image/webp" />
                    <img
                      src="/static/vip/footer.png"
                      alt="иллюстрация"
                      className="footer-header__image footer-header__image_business vip"
                    />
                  </picture>
                </div>
              </header>
            )}

            <main className="footer-main footer-main_business">
              <div
                style={{ alignItems: type === 'diagnostics' ? 'end' : '' }}
                className="container container_footer-main"
              >
                <div className="footer-main__left">
                  <div
                    onClick={() => window.scroll(0, 0)}
                    className="footer-main__logo"
                    style={{ marginBottom: `${location.pathname === '/business/franchise2' ? '30px' : ''}` }}
                  >
                    <div>
                      <img src="/static/business/Logo_business.svg" alt="логотип" width={276} height={30} />
                    </div>
                    <p>Технологичная HR-Tech площадка</p>
                  </div>
                  <div className="footer-main-important">
                    <DreamJob />
                    {type === 'diagnostics' ? (
                      ''
                    ) : (
                      <div className="footer-hh">
                        <h5 className="footer-hh__title">Привлекательный работодатель 2023</h5>
                        <picture onClick={handleOpenModalHh}>
                          <source srcSet="/static/customer/footer/main/hhFinall.jpg" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/hhFinall.jpg"
                            alt="hh"
                            className="footer-main-important__image-hh"
                          />
                        </picture>
                      </div>
                    )}

                    <div className="footer-main-important__links">
                      {activeLink === 'politics-home' ? (
                        <button
                          onClick={() => scrollToForm('politics-home')}
                          className="footer-main-important__link"
                        >
                          Политика конфиденциальности
                        </button>
                      ) : (
                        <Link
                          target="_blank"
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/politics"
                          className="footer-main-important__link"
                        >
                          Политика конфиденциальности
                        </Link>
                      )}

                      <a
                        href="https://reestr.digital.gov.ru/reestr/1284748/"
                        target="_blank"
                        className="footer-main-important__link"
                      >
                        Skilla IS в Реестре российского ПО
                      </a>

                      <a href="https://skilla.ru/svidetelstvo.html" className="footer-main-important__link">
                        Свидетельство о государственной регистрации <br></br> программы для ЭВМ
                      </a>
                    </div>

                    <div className="footer-main-important__images">
                      <a href="#" onClick={(event) => event.preventDefault()}>
                        <picture>
                          <source srcSet="/static/customer/footer/main/pay/01.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/pay/01.png"
                            alt="MasterCard"
                            className="footer-main-important__image"
                          />
                        </picture>
                      </a>
                      <a href="#" onClick={(event) => event.preventDefault()}>
                        <picture>
                          <source srcSet="/static/customer/footer/main/pay/02.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/pay/02.png"
                            alt="МИР"
                            className="footer-main-important__image"
                          />
                        </picture>
                      </a>
                      <a href="#" onClick={(event) => event.preventDefault()}>
                        <picture>
                          <source srcSet="/static/customer/footer/main/pay/03.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/pay/03.png"
                            alt="Visa"
                            className="footer-main-important__image"
                          />
                        </picture>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="footer-main-right">
                  {type === 'diagnostics' ? (
                    ''
                  ) : (
                    <header className="footer-main-right__header fran2">
                      {/*   <div className="footer-main-item">
                        <Link
                          onClick={() =>
                            location.pathname === '/business/franchise2'
                              ? scrollToFormFranchise()
                              : onActiveTarget('customer', 'customer')
                          }
                          to={location.pathname === '/business/franchise2' ? '' : '/'}
                          className="footer-main-item__title"
                        >
                          Заказчикам
                        </Link>

                        <div className="footer-main-item__links">
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('customer', 'customer')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/'}
                            className="footer-main-item__link"
                          >
                            Главная
                          </Link>
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('customer', 'customer')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/customer/orders'}
                            className="footer-main-item__link"
                          >
                            Виды услуг
                          </Link>
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('customer', 'customer')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/customer/geography'}
                            className="footer-main-item__link"
                          >
                            Карта присутствия
                          </Link>
                          <Link
                            to={location.pathname === '/business/franchise2' ? '' : '/customer/technologies'}
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('customer', 'customer')
                            }
                            className="footer-main-item__link"
                          >
                            Технологии
                          </Link>
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('customer', 'customer')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/customer/contracts'}
                            className="footer-main-item__link"
                          >
                            Госконтракты и тендеры
                          </Link>
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('customer', 'customer')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/customer/faq'}
                            className="footer-main-item__link"
                          >
                            Вопрос-ответ
                          </Link>
                        </div>

                        {activeLink === 'www.skilla.ru/' ? (
                          <button
                            onClick={() => scrollToForm('www.skilla.ru/')}
                            className="footer-main-item__button"
                          >
                            Разместить заказ
                          </button>
                        ) : (
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : dispatch(setAddActiveLink('www.skilla.ru/'))
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/'}
                            className="footer-main-item__button"
                          >
                            Разместить заказ
                          </Link>
                        )}
                      </div> */}

                      <div className="footer-main-item">
                        <Link
                          onClick={() =>
                            location.pathname === '/business/franchise2'
                              ? scrollToFormFranchise()
                              : onActiveTarget('business', 'business')
                          }
                          to={location.pathname === '/business/franchise2' ? '' : '/business'}
                          className="footer-main-item__title"
                        >
                          Предпринимателям
                        </Link>

                        <div className="footer-main-item__links">
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('business', 'business')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/business'}
                            className="footer-main-item__link"
                          >
                            Главная
                          </Link>
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('business', 'business')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/business/partners'}
                            className="footer-main-item__link"
                          >
                            Кейсы
                          </Link>
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('business', 'business')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/business/training'}
                            className="footer-main-item__link"
                          >
                            Обучение
                          </Link>
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('business', 'business')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/business/heroes'}
                            className="footer-main-item__link"
                          >
                            Бизнес-Герои
                          </Link>
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('business', 'business')
                            }
                            to={
                              location.pathname === '/business/franchise2' ? '' : '/business/heroes/interview'
                            }
                            className="footer-main-item__link"
                          >
                            Большое интервью
                          </Link>
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('business', 'business')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/business/faq'}
                            className="footer-main-item__link"
                          >
                            Вопрос-ответ
                          </Link>

                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('business', 'business')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/business/reviews'}
                            className="footer-main-item__link"
                          >
                            Отзывы
                          </Link>
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('business', 'business')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/business/about'}
                            className="footer-main-item__link"
                          >
                            О компании
                          </Link>
                          {/*<Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('business', 'business-franchise')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/business/franchise'}
                            className="footer-main-item__link"
                          >
                            Стать партнером
                          </Link>*
                          <a
                            href="https://www.beboss.ru/franchise/4186-franshiza-skilla-rabota"
                            target="_blank"
                            className="footer-main-item__link"
                          >
                            Франшиза Skilla Работа на БИБОСС
                          </a>*/}
                        </div>

                        {activeLink === 'www.skilla.ru/business/franchise' ? (
                          <button
                            onClick={() => scrollToForm('www.skilla.ru/business/franchise')}
                            className="footer-main-item__button"
                          >
                            Стать партнером
                          </button>
                        ) : (
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('business', 'www.skilla.ru/business/franchise')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/business/franchise'}
                            className="footer-main-item__button"
                          >
                            Стать партнером
                          </Link>
                        )}
                      </div>

                      {/*  <div className="footer-main-item">
                        <Link
                          onClick={() =>
                            location.pathname === '/business/franchise2'
                              ? scrollToFormFranchise()
                              : onActiveTarget('worker', 'worker')
                          }
                          to={location.pathname === '/business/franchise2' ? '' : '/worker'}
                          className="footer-main-item__title"
                        >
                          Исполнителям
                        </Link>

                        <div className="footer-main-item__links">
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('worker', 'worker')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/worker'}
                            className="footer-main-item__link"
                          >
                            Главная
                          </Link>
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('worker', 'worker')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/worker/orders'}
                            className="footer-main-item__link"
                          >
                            Виды заказов
                          </Link>
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('worker', 'worker')
                            }
                            to={
                              location.pathname === '/business/franchise2' ? '' : '/worker/performers/reviews'
                            }
                            className="footer-main-item__link"
                          >
                            Отзывы исполнителей
                          </Link>
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('worker', 'worker')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/worker/cases'}
                            className="footer-main-item__link"
                          >
                            Какие бывают заказчики
                          </Link>

                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('worker', 'worker')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/worker/faq'}
                            className="footer-main-item__link"
                          >
                            Вопрос-ответ
                          </Link>
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('worker', 'worker')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/worker/download'}
                            className="footer-main-item__link"
                          >
                            Скачать приложение
                          </Link>
                        </div>

                        {activeLink === 'www.skilla.ru/worker/download' ? (
                          <button
                            onClick={() => scrollToForm('www.skilla.ru/worker/download')}
                            className="footer-main-item__button"
                          >
                            Стать исполнителем
                          </button>
                        ) : (
                          <Link
                            onClick={() =>
                              location.pathname === '/business/franchise2'
                                ? scrollToFormFranchise()
                                : onActiveTarget('worker', 'www.skilla.ru/worker/download')
                            }
                            to={location.pathname === '/business/franchise2' ? '' : '/worker/download'}
                            className="footer-main-item__button"
                          >
                            Стать исполнителем
                          </Link>
                        )}
                      </div> */}

                      <div className="footer-main-item">
                        <h5 className="footer-main-item__title">Соискателям</h5>

                        <div className="footer__containerqr">
                          <div
                            className="footer-main-item__links"
                            onClick={() =>
                              location.pathname === '/business/franchise2' ? scrollToFormFranchise() : ''
                            }
                          >
                            {location.pathname === '/business/franchise2' ? (
                              <p style={{ cursor: 'pointer' }} className="footer-main-item__link">
                                Вакансии
                              </p>
                            ) : (
                              <a
                                href="https://kudrovo.hh.ru/employer/818282"
                                className="footer-main-item__link"
                              >
                                Вакансии
                              </a>
                            )}

                            {location.pathname === '/business/franchise2' ? (
                              <p style={{ cursor: 'pointer' }} className="footer-main-item__link">
                                Наш офис
                              </p>
                            ) : (
                              <a
                                href="https://skilla.ru/3dskilla/"
                                target="_blank"
                                className="footer-main-item__link"
                              >
                                Наш офис
                              </a>
                            )}
                          </div>

                          <picture className="footer__qr">
                            <source srcSet="/static/customer/footer/main/qr-code.png" type="image/webp" />
                            <img
                              src="/static/customer/footer/main/qr-code.png"
                              alt="qr-code"
                              className="footer-main-important__qr-code"
                            />
                          </picture>
                        </div>
                      </div>
                    </header>
                  )}
                  <footer className="footer-main-right__footer">
                    {type === 'diagnostics' ? (
                      ''
                    ) : (
                      <div
                        className="footer-main-right__images"
                        style={{ display: location.pathname === '/business/franchise2' ? 'none' : '' }}
                      >
                        <a href="https://vk.com/myskilla" target="_blank">
                          <picture>
                            <source srcSet="/static/customer/footer/main/media/VK.svg" type="image/webp" />
                            <img
                              src="/static/customer/footer/main/media/VK.svg"
                              alt="ВК"
                              className="footer-main-right__image"
                            />
                          </picture>
                        </a>
                        <a href="https://tenchat.ru/kir_uporov" target="_blank">
                          <picture>
                            <source
                              srcSet="/static/customer/footer/main/media/TenChat.svg"
                              type="image/webp"
                            />
                            <img
                              src="/static/customer/footer/main/media/TenChat.svg"
                              alt="tenchat"
                              className="footer-main-right__image"
                            />
                          </picture>
                        </a>
                        <a href="https://rutube.ru/channel/38773211/" target="_blank">
                          <picture>
                            <source
                              srcSet="/static/customer/footer/main/media/rutube.svg"
                              type="image/webp"
                            />
                            <img
                              src="/static/customer/footer/main/media/rutube.svg"
                              alt="YouTube"
                              className="footer-main-right__image"
                            />
                          </picture>
                        </a>
                        <a href="https://t.me/skillabiz" target="_blank">
                          <picture>
                            <source
                              srcSet="/static/customer/footer/main/media/telegram.svg"
                              type="image/webp"
                            />
                            <img
                              src="/static/customer/footer/main/media/telegram.svg"
                              alt="YouTube"
                              className="footer-main-right__image"
                            />
                          </picture>
                        </a>
                        <a href="https://dzen.ru/skilla?share_to=link" target="_blank">
                          <picture>
                            <source srcSet="/static/customer/footer/main/media/dzen.svg" type="image/webp" />
                            <img
                              src="/static/customer/footer/main/media/dzen.svg"
                              alt="YouTube"
                              className="footer-main-right__image"
                            />
                          </picture>
                        </a>
                      </div>
                    )}
                    <div
                      className="footer-main-right__flex"
                      style={{ marginTop: location.pathname === '/business/franchise2' ? '30px' : '' }}
                    >
                      <p className="footer-main-right__description">
                        <span>Skilla © 2010-{currentYear} Копирование информации запрещено </span>

                        <span>Skilla — зарегистрированный товарный знак, номер свидетельства 771589</span>

                        <span>ООО «Скилла Инновации» ИНН 4706050219</span>

                        <span>Санкт-Петербург, пр. Энергетиков, д.10, оф.416 hi@skilla.ru</span>

                        <span className="footer-main-right__description__bottom">
                          Информация на данном сайте носит информационный характер и не является офертой. ст.
                          437 ч. 1 ГК РФ.
                        </span>
                      </p>
                      {type === 'diagnostics' ? (
                        ''
                      ) : (
                        <div
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            location.pathname === '/business/franchise2' ? scrollToFormFranchise() : ''
                          }
                        >
                          <a
                            style={{
                              pointerEvents: location.pathname === '/business/franchise2' ? 'none' : '',
                            }}
                            href="https://lk.skilla.ru"
                            className="footer-main-right__button"
                          >
                            <picture>
                              <source srcSet="/static/customer/footer/main/mini-logo.png" type="image/webp" />
                              <img src="/static/customer/footer/main/mini-logo.png" alt="логотип" />
                            </picture>
                            <span className="arrow-right-top-smail icon"></span>
                          </a>
                        </div>
                      )}
                    </div>
                  </footer>
                </div>
              </div>
            </main>
            {type === 'diagnostics' ? (
              ''
            ) : (
              <>
                {/*{!book && location.pathname !== '/business/franchise2' && <FooterBook />}*/}
                <footer className="footer-footer">
                  <div className="container container_footer-footer">
                    <a
                      style={{ pointerEvents: location.pathname === '/business/franchise2' ? 'none' : '' }}
                      href="https://apps.apple.com/ru/app/skilla-work/id1531442328"
                      target="_blank"
                    >
                      <picture>
                        <source srcSet="/static/customer/footer/footer/01.webp" type="image/webp" />
                        <img
                          src="/static/customer/footer/footer/01.png"
                          alt="App Store"
                          className="footer-footer__image"
                        />
                      </picture>
                    </a>

                    <a
                      style={{ pointerEvents: location.pathname === '/business/franchise2' ? 'none' : '' }}
                      href="https://play.google.com/store/apps/details?id=com.skilla.Skilla"
                      target="_blank"
                    >
                      <picture>
                        <source srcSet="/static/customer/footer/footer/02.webp" type="image/webp" />
                        <img
                          src="/static/customer/footer/footer/02.png"
                          alt="Google Play"
                          className="footer-footer__image"
                        />
                      </picture>
                    </a>

                    {/*<a
                      style={{ pointerEvents: location.pathname === '/business/franchise2' ? 'none' : '' }}
                      href="https://skilla.ru/apk/skilla.apk"
                      target="_blank"
                    >
                      <picture>
                        <source srcSet="/static/customer/footer/footer/03.webp" type="image/webp" />
                        <img
                          src="/static/customer/footer/footer/03.png"
                          alt="NASHSTORE"
                          className="footer-footer__image"
                        />
                      </picture>
                    </a>*/}

                    <a
                      style={{ pointerEvents: location.pathname === '/business/franchise2' ? 'none' : '' }}
                      href="https://appgallery.huawei.com/#/app/C103862195?channelId=Skilla%20Official&id=1f01b81b103942dc8b9033f135eff478&s=38C79299AC884CC8EAC853E9E0FBDDCF2F32B4BC9BE70C49CE2B51F0D56F567D&detailType=0&v="
                      target="_blank"
                    >
                      <picture>
                        <source srcSet="/static/customer/footer/footer/04.webp" type="image/webp" />
                        <img
                          src="/static/customer/footer/footer/04.png"
                          alt="AppGallery"
                          className="footer-footer__image"
                        />
                      </picture>
                    </a>
                  </div>
                </footer>
              </>
            )}
          </StyledFooter>
        )}
        {width <= 768 && (
          <StyledFooterMobile id={id}>
            {type !== 'faq' && type !== 'diagnostics' && type !== 'vip' && (
              <header ref={formRef} className="footer-header footer-header_business">
                <div className="container container_footer-header">
                  <div className="footer-header__content_business">
                    <div>
                      {type === 'main' ? (
                        <>
                          <h2 className="footer-header__title footer-header__title_business footer-header__title_business_main">
                            Получи расчет <span>финансовых показателей</span>
                          </h2>
                          <p className="footer-header__description">
                            Расскажем, как открыть бизнес и окупить расходы за полгода
                          </p>
                        </>
                      ) : type === 'about' ? (
                        <h2 className="footer-header__title footer-header__title_business">
                          Запишись на реальную экскурсию и<span>открой партнерский офис Скилла</span> в своем
                          городе
                        </h2>
                      ) : (
                        <>
                          <h2 className="footer-header__title footer-header__title_business">
                            Открой <span>партнерский офис</span> в своем городе
                          </h2>
                          <p className="footer-header__description">Открой партнерский офис в своем городе</p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {type === 'about' ? (
                  <Form page="business" type="about-footer" />
                ) : (
                  <Form inputRef={inputRefFooter} page="business" type="main" />
                )}

                <p className="header__info">
                  Отправляя форму, вы соглашаетесь с
                  <Link
                    target="_blank"
                    onClick={() => dispatch(setAddActiveTarget('business'))}
                    to="/politics"
                  >
                    условиями
                  </Link>
                </p>
                <picture>
                  <source
                    srcSet="/static/business/footer/header/illustration-mobile.webp"
                    type="image/webp"
                  />
                  <img
                    src="/static/business/footer/header/illustration-mobile.webp"
                    alt="иллюстрация"
                    className="footer-header__image footer-header__image_business"
                  />
                </picture>
              </header>
            )}

            {type === 'vip' && (
              <header className={'footer-header footer-header_business footer-header_vip'}>
                <div className="container container_footer-header">
                  <div className="footer-header__content_business">
                    <div>
                      <h2 className="footer-header__title">
                        Общение с лучшими — <br></br>
                        <p>ключ к развитию бизнеса и удовольствие от жизни</p>
                      </h2>

                      <p style={{ color: '#E3E4E8', fontSize: '16px' }}>
                        Узнай больше у бизнес-ассистентов в чате
                      </p>
                      {!isCheckFromPage && !user?.user?.code && <Form page="business" type="vip" />}
                    </div>
                  </div>

                  <picture>
                    <source srcSet="/static/vip/footer-mobile.webp" type="image/webp" />
                    <img
                      src="/static/vip/footer-mobile.png"
                      alt="иллюстрация"
                      className="footer-header__image footer-header__image_business vip"
                    />
                  </picture>

                  {removeDate >= 0 && (
                    <p
                      style={{
                        lineHeight: '130%',
                        fontSize: '14px',
                        textAlign: 'center',
                        marginBottom: '40px',
                        opacity: '0.7',
                      }}
                      className="header__info vip"
                    >
                      Предложение действительно до {endPremium} {yearNow}
                    </p>
                  )}
                </div>
              </header>
            )}
            <main className="footer-main footer-main_business">
              <div className="container container_footer-main">
                <div className="footer-main__logo">
                  <div onClick={() => window.scroll(0, 0)}>
                    <img src="/static/business/Logo_business.svg" alt="логотип" width={276} height={30} />
                  </div>
                  <p>Технологичная HR-Tech площадка</p>

                  <div className="footer-hh">
                    <h5 className="footer-hh__title">Привлекательный работодатель 2023</h5>
                    <picture onClick={handleOpenModalHh}>
                      <source srcSet="/static/customer/footer/main/hhFinall.jpg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/hhFinall.jpg"
                        alt="hh"
                        className="footer-main-important__image-hh"
                      />
                    </picture>
                    <DreamJob type={'mob'} />
                  </div>
                </div>
                {type !== 'diagnostics' && (
                  <div
                    className="footer-main__items"
                    style={{ display: location.pathname === '/business/franchise2' ? 'none' : '' }}
                  >
                    {/*   <div className="footer-main-item" onClick={setTab}>
                      <h5 className="footer-main-item__title">Заказчикам</h5>

                      <div className="footer-main-item__links">
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/"
                          className="footer-main-item__link"
                        >
                          Главная
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/orders"
                          className="footer-main-item__link"
                        >
                          Виды услуг
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/geography"
                          className="footer-main-item__link"
                        >
                          Карта присутствия
                        </Link>
                        <Link
                          to="/customer/technologies"
                          onClick={() => onActiveTarget('customer', 'customer')}
                          className="footer-main-item__link"
                        >
                          Технологии
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/contracts"
                          className="footer-main-item__link"
                        >
                          Госконтракты и тендеры
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/faq"
                          className="footer-main-item__link"
                        >
                          Вопрос-ответ
                        </Link>
                      </div>

                      {activeLink === 'www.skilla.ru/' ? (
                        <button
                          onClick={() => scrollToForm('www.skilla.ru/')}
                          className="footer-main-item__button"
                        >
                          Разместить заказ
                        </button>
                      ) : (
                        <Link
                          onClick={() => dispatch(setAddActiveLink('www.skilla.ru/'))}
                          to="/"
                          className="footer-main-item__button"
                        >
                          Разместить заказ
                        </Link>
                      )}
                    </div> */}
                    <div className="footer-main-item" onClick={setTab}>
                      <h5 className="footer-main-item__title">Предпринимателям</h5>

                      <div className="footer-main-item__links">
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business"
                          className="footer-main-item__link"
                        >
                          Главная
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/partners"
                          className="footer-main-item__link"
                        >
                          Кейсы
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/training"
                          className="footer-main-item__link"
                        >
                          Обучение
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/heroes"
                          className="footer-main-item__link"
                        >
                          Бизнес-Герои
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/heroes/interview"
                          className="footer-main-item__link"
                        >
                          Большое интервью
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/faq"
                          className="footer-main-item__link"
                        >
                          Вопрос-ответ
                        </Link>

                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/reviews"
                          className="footer-main-item__link"
                        >
                          Отзывы
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/about"
                          className="footer-main-item__link"
                        >
                          О компании
                        </Link>
                        {/*<Link
                          onClick={() => onActiveTarget('business', 'business-franchise')}
                          to="/business/franchise"
                          className="footer-main-item__link"
                        >
                          Стать партнером
                        </Link>*/}
                      </div>
                      {activeLink === 'www.skilla.ru/business/franchise' ? (
                        <button
                          onClick={() => scrollToForm('business-franshise')}
                          className="footer-main-item__button"
                        >
                          Стать партнером
                        </button>
                      ) : (
                        <Link
                          onClick={() => onActiveTarget('business', 'www.skilla.ru/business/franchise')}
                          to="/business/franchise"
                          className="footer-main-item__button"
                        >
                          Стать партнером
                        </Link>
                      )}
                    </div>
                    {/*  <div className="footer-main-item" onClick={setTab}>
                      <h5 className="footer-main-item__title">Исполнителям</h5>

                      <div className="footer-main-item__links">
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker"
                          className="footer-main-item__link"
                        >
                          Главная
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/orders"
                          className="footer-main-item__link"
                        >
                          Виды заказов
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/performers/reviews"
                          className="footer-main-item__link"
                        >
                          Отзывы исполнителей
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/cases"
                          className="footer-main-item__link"
                        >
                          Какие бывают заказчики
                        </Link>

                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/faq"
                          className="footer-main-item__link"
                        >
                          Вопрос-ответ
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/download"
                          className="footer-main-item__link"
                        >
                          Скачать приложение
                        </Link>
                      </div>
                      {activeLink === 'www.skilla.ru/worker/download' ? (
                        <button
                          onClick={() => scrollToForm('www.skilla.ru/worker/download')}
                          className="footer-main-item__button"
                        >
                          Стать исполнителем
                        </button>
                      ) : (
                        <Link
                          onClick={() => onActiveTarget('worker', 'www.skilla.ru/worker/download')}
                          to="/worker/download"
                          className="footer-main-item__button"
                        >
                          Стать исполнителем
                        </Link>
                      )}
                    </div> */}
                    <div className="footer-main-item" onClick={setTab}>
                      <h5 className="footer-main-item__title">Соискателям</h5>

                      <div className="footer-main-item__links">
                        <a href="https://kudrovo.hh.ru/employer/818282" className="footer-main-item__link">
                          Вакансии
                        </a>

                        <a
                          href="https://skilla.ru/3dskilla/"
                          target="_blank"
                          className="footer-main-item__link"
                        >
                          Наш офис
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                {type !== 'diagnostics' && (
                  <div
                    className="footer-main-right__images"
                    style={{ display: location.pathname === '/business/franchise2' ? 'none' : '' }}
                  >
                    <a href="https://vk.com/myskilla" target="_blank">
                      <picture>
                        <source srcSet="/static/customer/footer/main/media/01.png" type="image/webp" />
                        <img
                          src="/static/customer/footer/main/media/01.png"
                          alt="ВК"
                          className="footer-main-right__image"
                        />
                      </picture>
                    </a>
                    <a href="https://tenchat.ru/kir_uporov" target="_blank">
                      <picture>
                        <source srcSet="/static/customer/footer/main/media/02.png" type="image/webp" />
                        <img
                          src="/static/customer/footer/main/media/02.png"
                          alt="tenchat"
                          className="footer-main-right__image"
                        />
                      </picture>
                    </a>
                    <a href="https://rutube.ru/channel/38773211/" target="_blank">
                      <picture>
                        <source srcSet="/static/customer/footer/main/media/03.png" type="image/webp" />
                        <img
                          src="/static/customer/footer/main/media/03.png"
                          alt="YouTube"
                          className="footer-main-right__image"
                        />
                      </picture>
                    </a>
                  </div>
                )}
                <div
                  className="footer-main-important__links"
                  style={{ marginTop: location.pathname === '/business/franchise2' ? '30px' : '' }}
                >
                  {activeLink === 'politics-home' ? (
                    <button
                      onClick={() => scrollToForm('politics-home')}
                      className="footer-main-important__link"
                    >
                      Политика конфиденциальности
                    </button>
                  ) : (
                    <Link
                      target="_blank"
                      onClick={() => onActiveTarget('business', 'business')}
                      to="/politics"
                      className="footer-main-important__link"
                    >
                      Политика конфиденциальности
                    </Link>
                  )}

                  <a
                    href="https://reestr.digital.gov.ru/reestr/1284748/"
                    target="_blank"
                    className="footer-main-important__link"
                  >
                    Skilla IS в Реестре российского ПО
                  </a>

                  <a href="https://skilla.ru/svidetelstvo.html" className="footer-main-important__link">
                    Свидетельство о государственной регистрации <br></br> программы для ЭВМ
                  </a>
                </div>
                <div className="footer-main-important__images">
                  <a href="#" onClick={(event) => event.preventDefault()}>
                    <picture>
                      <source srcSet="/static/customer/footer/main/pay/01.webp" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/pay/01.png"
                        alt="MasterCard"
                        className="footer-main-important__image"
                      />
                    </picture>
                  </a>
                  <a href="#" onClick={(event) => event.preventDefault()}>
                    <picture>
                      <source srcSet="/static/customer/footer/main/pay/02.webp" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/pay/02.png"
                        alt="МИР"
                        className="footer-main-important__image"
                      />
                    </picture>
                  </a>
                  <a href="#" onClick={(event) => event.preventDefault()}>
                    <picture>
                      <source srcSet="/static/customer/footer/main/pay/03.webp" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/pay/03.png"
                        alt="Visa"
                        className="footer-main-important__image"
                      />
                    </picture>
                  </a>
                </div>
                <p className="footer-main-right__description">
                  <span>Skilla © 2010-{currentYear} Копирование информации запрещено </span>

                  <span>Skilla — зарегистрированный товарный знак, номер свидетельства 771589</span>

                  <span>ООО «Скилла Инновации» ИНН 4706050219</span>

                  <span>Санкт-Петербург, пр. Энергетиков, д.10, оф.416 hi@skilla.ru</span>

                  <span className="footer-main-right__description__bottom">
                    Информация на данном сайте носит информационный характер и не является офертой. ст. 437 ч.
                    1 ГК РФ.
                  </span>
                </p>
                {type !== 'diagnostics' && (
                  <a
                    style={{ display: location.pathname === '/business/franchise2' ? 'none' : '' }}
                    href="https://lk.skilla.ru"
                    className="footer-main-right__button"
                  >
                    <picture>
                      <source srcSet="/static/customer/footer/main/mini-logo.png" type="image/webp" />
                      <img src="/static/customer/footer/main/mini-logo.png" alt="логотип" />
                    </picture>
                    <span className="arrow-right-top-smail icon"></span>
                  </a>
                )}
              </div>
            </main>
            {type !== 'diagnostics' && (
              <>
                {/*{!book && location.pathname !== '/business/franchise2' && <FooterBook />}*/}
                <footer className="footer-footer">
                  <div className="container container_footer-footer">
                    <a
                      style={{ pointerEvents: location.pathname === '/business/franchise2' ? 'none' : '' }}
                      href="https://apps.apple.com/ru/app/skilla-work/id1531442328"
                      target="_blank"
                    >
                      <picture>
                        <source srcSet="/static/customer/footer/footer/01.webp" type="image/webp" />
                        <img
                          src="/static/customer/footer/footer/01.png"
                          alt="App Store"
                          className="footer-footer__image"
                        />
                      </picture>
                    </a>

                    <a
                      style={{ pointerEvents: location.pathname === '/business/franchise2' ? 'none' : '' }}
                      href="https://play.google.com/store/apps/details?id=com.skilla.Skilla"
                      target="_blank"
                    >
                      <picture>
                        <source srcSet="/static/customer/footer/footer/02.webp" type="image/webp" />
                        <img
                          src="/static/customer/footer/footer/02.png"
                          alt="Google Play"
                          className="footer-footer__image"
                        />
                      </picture>
                    </a>

                    {/*<a
                      style={{ pointerEvents: location.pathname === '/business/franchise2' ? 'none' : '' }}
                      href="https://skilla.ru/apk/skilla.apk"
                      target="_blank"
                    >
                      <picture>
                        <source srcSet="/static/customer/footer/footer/03.webp" type="image/webp" />
                        <img
                          src="/static/customer/footer/footer/03.png"
                          alt="NASHSTORE"
                          className="footer-footer__image"
                        />
                      </picture>
                    </a>*/}

                    <a
                      style={{ pointerEvents: location.pathname === '/business/franchise2' ? 'none' : '' }}
                      href="https://appgallery.huawei.com/#/app/C103862195?channelId=Skilla%20Official&id=1f01b81b103942dc8b9033f135eff478&s=38C79299AC884CC8EAC853E9E0FBDDCF2F32B4BC9BE70C49CE2B51F0D56F567D&detailType=0&v="
                      target="_blank"
                    >
                      <picture>
                        <source srcSet="/static/customer/footer/footer/04.webp" type="image/webp" />
                        <img
                          src="/static/customer/footer/footer/04.png"
                          alt="AppGallery"
                          className="footer-footer__image"
                        />
                      </picture>
                    </a>
                  </div>
                </footer>
              </>
            )}
          </StyledFooterMobile>
        )}
      </>
    )

  if (page === 'founder')
    return (
      <>
        {openModal && <ModalHH setOpenModal={setOpenModal} />}
        {width > 768 && (
          <StyledFooter>
            {type !== 'faq' && (
              <header id="founder" ref={formRef} className="footer-header footer-header_founder">
                <div className="container container_footer-header">
                  <div className="footer-header__content_business">
                    <div>
                      <h2
                        style={{ marginBottom: onClick ? '100px' : '' }}
                        className="footer-header__title footer-header__title_founder"
                      >
                        <span className="title-span">Пригласить в качестве эксперта</span> на мероприятия и
                        для СМИ
                      </h2>
                      <Form page="founder" />

                      <div className="footer-header_founder-text">Давай будем на связи!</div>

                      <div className="footer-main-right__images">
                        <a href="https://vk.com/myskilla" target="_blank">
                          <picture>
                            <source srcSet="/static/customer/footer/main/media/01.png" type="image/webp" />
                            <img
                              src="/static/customer/footer/main/media/01.png"
                              alt="ВК"
                              className="footer-main-right__image"
                            />
                          </picture>
                        </a>
                        <a href="https://tenchat.ru/kir_uporov" target="_blank">
                          <picture>
                            <source srcSet="/static/customer/footer/main/media/02.png" type="image/webp" />
                            <img
                              src="/static/customer/footer/main/media/02.png"
                              alt="tenchat"
                              className="footer-main-right__image"
                            />
                          </picture>
                        </a>
                        <a href="https://rutube.ru/channel/38773211/" target="_blank">
                          <picture>
                            <source srcSet="/static/customer/footer/main/media/03.png" type="image/webp" />
                            <img
                              src="/static/customer/footer/main/media/03.png"
                              alt="YouTube"
                              className="footer-main-right__image"
                            />
                          </picture>
                        </a>
                      </div>
                    </div>

                    <p className="footer-header__politics">
                      Отправляя форму, вы соглашаетесь с
                      <Link
                        target="_blank"
                        onClick={() => dispatch(setAddActiveTarget('business'))}
                        to="/politics"
                      >
                        условиями
                      </Link>
                    </p>
                  </div>
                  <picture>
                    <source srcSet="/static/business/founder/founder-photo-2.webp" type="image/webp" />
                    <img
                      src="/static/business/founder/founder-photo-2.png"
                      alt="иллюстрация"
                      className="footer-header__image footer-header__image_founder"
                    />
                  </picture>
                </div>
              </header>
            )}
            <main className="footer-main footer-main_business">
              <div className="container container_footer-main">
                <div className="footer-main__left">
                  <div onClick={() => window.scroll(0, 0)} className="footer-main__logo">
                    <div>
                      <img src="/static/business/Logo_business.svg" alt="логотип" width={276} height={30} />
                    </div>
                    <p>Технологичная HR-Tech площадка</p>
                  </div>
                  <div className="footer-main-important">
                    <DreamJob />
                    <div className="footer-hh">
                      <h5 className="footer-hh__title">Привлекательный работодатель 2023</h5>
                      <picture onClick={handleOpenModalHh}>
                        <source srcSet="/static/customer/footer/main/hhFinall.jpg" type="image/webp" />
                        <img
                          src="/static/customer/footer/main/hhFinall.jpg"
                          alt="hh"
                          className="footer-main-important__image-hh"
                        />
                      </picture>
                    </div>

                    <div className="footer-main-important__links">
                      {activeLink === 'politics-home' ? (
                        <button
                          onClick={() => onActiveTarget('business', 'business')}
                          className="footer-main-important__link"
                        >
                          Политика конфиденциальности
                        </button>
                      ) : (
                        <Link
                          target="_blank"
                          onClick={() => dispatch(setAddActiveLink('business'))}
                          to="/politics"
                          className="footer-main-important__link"
                        >
                          Политика конфиденциальности
                        </Link>
                      )}

                      <a
                        href="https://reestr.digital.gov.ru/reestr/1284748/"
                        target="_blank"
                        className="footer-main-important__link"
                      >
                        Skilla IS в Реестре российского ПО
                      </a>

                      <a href="https://skilla.ru/svidetelstvo.html" className="footer-main-important__link">
                        Свидетельство о государственной регистрации <br></br> программы для ЭВМ
                      </a>
                    </div>

                    <div className="footer-main-important__images">
                      <a href="#" onClick={(event) => event.preventDefault()}>
                        <picture>
                          <source srcSet="/static/customer/footer/main/pay/01.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/pay/01.png"
                            alt="MasterCard"
                            className="footer-main-important__image"
                          />
                        </picture>
                      </a>
                      <a href="#" onClick={(event) => event.preventDefault()}>
                        <picture>
                          <source srcSet="/static/customer/footer/main/pay/02.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/pay/02.png"
                            alt="МИР"
                            className="footer-main-important__image"
                          />
                        </picture>
                      </a>
                      <a href="#" onClick={(event) => event.preventDefault()}>
                        <picture>
                          <source srcSet="/static/customer/footer/main/pay/03.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/pay/03.png"
                            alt="Visa"
                            className="footer-main-important__image"
                          />
                        </picture>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="footer-main-right">
                  <header className="footer-main-right__header">
                    {/*  <div className="footer-main-item">
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/"
                        className="footer-main-item__title"
                      >
                        Заказчикам
                      </Link>

                      <div className="footer-main-item__links">
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/"
                          className="footer-main-item__link"
                        >
                          Главная
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/orders"
                          className="footer-main-item__link"
                        >
                          Виды услуг
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/geography"
                          className="footer-main-item__link"
                        >
                          Карта присутствия
                        </Link>
                        <Link
                          to="/customer/technologies"
                          onClick={() => onActiveTarget('customer', 'customer')}
                          className="footer-main-item__link"
                        >
                          Технологии
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/contracts"
                          className="footer-main-item__link"
                        >
                          Госконтракты и тендеры
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('customer', 'customer')}
                          to="/customer/faq"
                          className="footer-main-item__link"
                        >
                          Вопрос-ответ
                        </Link>
                      </div>

                      {activeLink === 'www.skilla.ru/' ? (
                        <button
                          onClick={() => scrollToForm('www.skilla.ru/')}
                          className="footer-main-item__button"
                        >
                          Разместить заказ
                        </button>
                      ) : (
                        <Link
                          onClick={() => dispatch(setAddActiveLink('www.skilla.ru/'))}
                          to="/"
                          className="footer-main-item__button"
                        >
                          Разместить заказ
                        </Link>
                      )}
                    </div> */}

                    <div className="footer-main-item">
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business"
                        className="footer-main-item__title"
                      >
                        Предпринимателям
                      </Link>

                      <div className="footer-main-item__links">
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business"
                          className="footer-main-item__link"
                        >
                          Главная
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/partners"
                          className="footer-main-item__link"
                        >
                          Кейсы
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/training"
                          className="footer-main-item__link"
                        >
                          Обучение
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/heroes"
                          className="footer-main-item__link"
                        >
                          Бизнес-Герои
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/heroes/interview"
                          className="footer-main-item__link"
                        >
                          Большое интервью
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/faq"
                          className="footer-main-item__link"
                        >
                          Вопрос-ответ
                        </Link>

                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/reviews"
                          className="footer-main-item__link"
                        >
                          Отзывы
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('business', 'business')}
                          to="/business/about"
                          className="footer-main-item__link"
                        >
                          О компании
                        </Link>
                        {/*<Link
                          onClick={() => onActiveTarget('business', 'business-franchise')}
                          to="/business/franchise"
                          className="footer-main-item__link"
                        >
                          Стать партнером
                        </Link>*/}
                      </div>

                      {activeLink === 'www.skilla.ru/business/franchise' ? (
                        <button
                          onClick={() => scrollToForm('www.skilla.ru/business/franchise')}
                          className="footer-main-item__button"
                        >
                          Стать партнером
                        </button>
                      ) : (
                        <Link
                          onClick={() => onActiveTarget('business', 'www.skilla.ru/business/franchise')}
                          to="/business/franchise"
                          className="footer-main-item__button"
                        >
                          Стать партнером
                        </Link>
                      )}
                    </div>

                    {/* <div className="footer-main-item">
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker"
                        className="footer-main-item__title"
                      >
                        Исполнителям
                      </Link>

                      <div className="footer-main-item__links">
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker"
                          className="footer-main-item__link"
                        >
                          Главная
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/orders"
                          className="footer-main-item__link"
                        >
                          Виды заказов
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/performers/reviews"
                          className="footer-main-item__link"
                        >
                          Отзывы исполнителей
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/cases"
                          className="footer-main-item__link"
                        >
                          Какие бывают заказчики
                        </Link>

                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/faq"
                          className="footer-main-item__link"
                        >
                          Вопрос-ответ
                        </Link>
                        <Link
                          onClick={() => onActiveTarget('worker', 'worker')}
                          to="/worker/download"
                          className="footer-main-item__link"
                        >
                          Скачать приложение
                        </Link>
                      </div>

                      {activeLink === 'www.skilla.ru/worker/download' ? (
                        <button
                          onClick={() => scrollToForm('www.skilla.ru/worker/download')}
                          className="footer-main-item__button"
                        >
                          Стать исполнителем
                        </button>
                      ) : (
                        <Link
                          onClick={() => onActiveTarget('worker', 'www.skilla.ru/worker/download')}
                          to="/worker/download"
                          className="footer-main-item__button"
                        >
                          Стать исполнителем
                        </Link>
                      )}
                    </div> */}

                    <div className="footer-main-item">
                      <h5 className="footer-main-item__title">Соискателям</h5>

                      <div className="footer__containerqr">
                        <div className="footer-main-item__links">
                          <a href="https://kudrovo.hh.ru/employer/818282" className="footer-main-item__link">
                            Вакансии
                          </a>

                          <a
                            href="https://skilla.ru/3dskilla/"
                            target="_blank"
                            className="footer-main-item__link"
                          >
                            Наш офис
                          </a>
                        </div>

                        <picture className="footer__qr">
                          <source srcSet="/static/customer/footer/main/qr-code.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/qr-code.png"
                            alt="qr-code"
                            className="footer-main-important__qr-code"
                          />
                        </picture>
                      </div>
                    </div>
                  </header>
                  <footer className="footer-main-right__footer">
                    <div className="footer-main-right__images">
                      <a href="https://vk.com/myskilla" target="_blank">
                        <picture>
                          <source srcSet="/static/customer/footer/main/media/01.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/media/01.png"
                            alt="ВК"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                      <a href="https://tenchat.ru/kir_uporov" target="_blank">
                        <picture>
                          <source srcSet="/static/customer/footer/main/media/02.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/media/02.png"
                            alt="tenchat"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                      <a href="https://rutube.ru/channel/38773211/" target="_blank">
                        <picture>
                          <source srcSet="/static/customer/footer/main/media/03.png" type="image/webp" />
                          <img
                            src="/static/customer/footer/main/media/03.png"
                            alt="YouTube"
                            className="footer-main-right__image"
                          />
                        </picture>
                      </a>
                    </div>
                    <div className="footer-main-right__flex">
                      <p className="footer-main-right__description">
                        <span>Skilla © 2010-{currentYear} Копирование информации запрещено </span>

                        <span>Skilla — зарегистрированный товарный знак, номер свидетельства 771589</span>

                        <span>ООО «Скилла Инновации» ИНН 4706050219</span>

                        <span>Санкт-Петербург, пр. Энергетиков, д.10, оф.416 hi@skilla.ru</span>

                        <span className="footer-main-right__description__bottom">
                          Информация на данном сайте носит информационный характер и не является офертой. ст.
                          437 ч. 1 ГК РФ.
                        </span>
                      </p>
                      <a href="https://lk.skilla.ru" className="footer-main-right__button">
                        <picture>
                          <source srcSet="/static/customer/footer/main/mini-logo.png" type="image/webp" />
                          <img src="/static/customer/footer/main/mini-logo.png" alt="логотип" />
                        </picture>
                        <span className="arrow-right-top-smail icon"></span>
                      </a>
                    </div>
                  </footer>
                </div>
              </div>
            </main>
            <footer className="footer-footer">
              {/*{!book && <FooterBook />}*/}
              <div className="container container_footer-footer">
                <a href="https://apps.apple.com/ru/app/skilla-work/id1531442328" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/01.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/01.png"
                      alt="App Store"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.skilla.Skilla" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/02.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/02.png"
                      alt="Google Play"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                {/*<a href="https://skilla.ru/apk/skilla.apk" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/03.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/03.png"
                      alt="NASHSTORE"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>*/}

                <a
                  href="https://appgallery.huawei.com/#/app/C103862195?channelId=Skilla%20Official&id=1f01b81b103942dc8b9033f135eff478&s=38C79299AC884CC8EAC853E9E0FBDDCF2F32B4BC9BE70C49CE2B51F0D56F567D&detailType=0&v="
                  target="_blank"
                >
                  <picture>
                    <source srcSet="/static/customer/footer/footer/04.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/04.png"
                      alt="AppGallery"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>
              </div>
            </footer>
          </StyledFooter>
        )}
        {width <= 768 && (
          <StyledFooterMobile>
            {type !== 'faq' && (
              <header id="founder" ref={formRef} className="footer-header footer-header_business">
                <div className="container container_footer-header">
                  <div className="footer-header__content_business">
                    <div>
                      <h2 className="footer-header__title footer-header__title_founder">
                        <span className="title-span">Пригласить в качестве эксперта</span> на мероприятия и
                        для СМИ
                      </h2>

                      <img
                        src="/static/business/founder/founder-photo-2-mobile.png"
                        alt="иллюстрация"
                        className="footer-header__image footer-header__image_founder"
                      />
                    </div>
                  </div>
                </div>

                <Form page="founder" />
                <p className="header__info">
                  Отправляя форму, вы соглашаетесь с
                  <Link
                    target="_blank"
                    onClick={() => dispatch(setAddActiveTarget('business'))}
                    to="/politics"
                  >
                    условиями
                  </Link>
                </p>
              </header>
            )}
            <main className="footer-main footer-main_business">
              <div className="container container_footer-main">
                <div className="footer-main__logo">
                  <div onClick={() => window.scroll(0, 0)}>
                    <picture>
                      <source srcSet="/static/logo.webp" type="image/webp" />
                      <img src="/static/logo.png" alt="Skilla" />
                    </picture>

                    <span>Предпринимателям</span>
                  </div>
                  <p>Технологичная HR-Tech площадка</p>

                  <div className="footer-hh">
                    <h5 className="footer-hh__title">Привлекательный работодатель 2023</h5>
                    <picture onClick={handleOpenModalHh}>
                      <source srcSet="/static/customer/footer/main/hhFinall.jpg" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/hhFinall.jpg"
                        alt="hh"
                        className="footer-main-important__image-hh"
                      />
                    </picture>
                    <DreamJob type={'mob'} />
                  </div>
                </div>
                <div className="footer-main__items">
                  {/*  <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Заказчикам</h5>

                    <div className="footer-main-item__links">
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/"
                        className="footer-main-item__link"
                      >
                        Главная
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/orders"
                        className="footer-main-item__link"
                      >
                        Виды услуг
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/geography"
                        className="footer-main-item__link"
                      >
                        Карта присутствия
                      </Link>
                      <Link
                        to="/customer/technologies"
                        onClick={() => onActiveTarget('customer', 'customer')}
                        className="footer-main-item__link"
                      >
                        Технологии
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/contracts"
                        className="footer-main-item__link"
                      >
                        Госконтракты и тендеры
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('customer', 'customer')}
                        to="/customer/faq"
                        className="footer-main-item__link"
                      >
                        Вопрос-ответ
                      </Link>
                    </div>
                    {activeLink === 'www.skilla.ru/' ? (
                      <button
                        onClick={() => scrollToForm('www.skilla.ru/')}
                        className="footer-main-item__button"
                      >
                        Разместить заказ
                      </button>
                    ) : (
                      <Link
                        onClick={() => dispatch(setAddActiveLink('www.skilla.ru/'))}
                        to="/"
                        className="footer-main-item__button"
                      >
                        Разместить заказ
                      </Link>
                    )}
                  </div> */}
                  <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Предпринимателям</h5>

                    <div className="footer-main-item__links">
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business"
                        className="footer-main-item__link"
                      >
                        Главная
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/partners"
                        className="footer-main-item__link"
                      >
                        Кейсы
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/training"
                        className="footer-main-item__link"
                      >
                        Обучение
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/heroes"
                        className="footer-main-item__link"
                      >
                        Бизнес-Герои
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/heroes/interview"
                        className="footer-main-item__link"
                      >
                        Большое интервью
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/faq"
                        className="footer-main-item__link"
                      >
                        Вопрос-ответ
                      </Link>

                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/reviews"
                        className="footer-main-item__link"
                      >
                        Отзывы
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('business', 'business')}
                        to="/business/about"
                        className="footer-main-item__link"
                      >
                        О компании
                      </Link>
                      {/*<Link
                        onClick={() => onActiveTarget('business', 'business-franchise')}
                        to="/business/franchise"
                        className="footer-main-item__link"
                      >
                        Стать партнером
                      </Link>*/}
                    </div>

                    {activeLink === 'www.skilla.ru/business/franchise' ? (
                      <button
                        onClick={() => scrollToForm('www.skilla.ru/business/franchise')}
                        className="footer-main-item__button"
                      >
                        Стать партнером
                      </button>
                    ) : (
                      <Link
                        onClick={() => onActiveTarget('business', 'www.skilla.ru/business/franchise')}
                        to="/business/franchise"
                        className="footer-main-item__button"
                      >
                        Стать партнером
                      </Link>
                    )}
                  </div>
                  {/* <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Исполнителям</h5>

                    <div className="footer-main-item__links">
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker"
                        className="footer-main-item__link"
                      >
                        Главная
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/orders"
                        className="footer-main-item__link"
                      >
                        Виды заказов
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/performers/reviews"
                        className="footer-main-item__link"
                      >
                        Отзывы исполнителей
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/cases"
                        className="footer-main-item__link"
                      >
                        Какие бывают заказчики
                      </Link>

                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/faq"
                        className="footer-main-item__link"
                      >
                        Вопрос-ответ
                      </Link>
                      <Link
                        onClick={() => onActiveTarget('worker', 'worker')}
                        to="/worker/download"
                        className="footer-main-item__link"
                      >
                        Скачать приложение
                      </Link>
                    </div>
                    {activeLink === 'www.skilla.ru/worker/download' ? (
                      <button
                        onClick={() => scrollToForm('www.skilla.ru/worker/download')}
                        className="footer-main-item__button"
                      >
                        Стать исполнителем
                      </button>
                    ) : (
                      <Link
                        onClick={() => onActiveTarget('worker', 'www.skilla.ru/worker/download')}
                        to="/worker/download"
                        className="footer-main-item__button"
                      >
                        Стать исполнителем
                      </Link>
                    )}
                  </div> */}
                  <div className="footer-main-item" onClick={setTab}>
                    <h5 className="footer-main-item__title">Соискателям</h5>

                    <div className="footer-main-item__links">
                      <a href="https://kudrovo.hh.ru/employer/818282" className="footer-main-item__link">
                        Вакансии
                      </a>

                      <a
                        href="https://skilla.ru/3dskilla/"
                        target="_blank"
                        className="footer-main-item__link"
                      >
                        Наш офис
                      </a>
                    </div>
                  </div>
                </div>
                <div className="footer-main-right__images">
                  <a href="https://vk.com/myskilla" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/01.png" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/01.png"
                        alt="ВК"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                  <a href="https://tenchat.ru/kir_uporov" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/02.png" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/02.png"
                        alt="tenchat"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                  <a href="https://rutube.ru/channel/38773211/" target="_blank">
                    <picture>
                      <source srcSet="/static/customer/footer/main/media/03.png" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/media/03.png"
                        alt="YouTube"
                        className="footer-main-right__image"
                      />
                    </picture>
                  </a>
                </div>
                <div className="footer-main-important__links">
                  {activeLink === 'politics-home' ? (
                    <button
                      onClick={() => scrollToForm('politics-home')}
                      className="footer-main-important__link"
                    >
                      Политика конфиденциальности
                    </button>
                  ) : (
                    <Link
                      target="_blank"
                      onClick={() => onActiveTarget('business', 'business')}
                      to="/politics"
                      className="footer-main-important__link"
                    >
                      Политика конфиденциальности
                    </Link>
                  )}

                  <a
                    href="https://reestr.digital.gov.ru/reestr/1284748/"
                    target="_blank"
                    className="footer-main-important__link"
                  >
                    Skilla IS в Реестре российского ПО
                  </a>

                  <a href="https://skilla.ru/svidetelstvo.html" className="footer-main-important__link">
                    Свидетельство о государственной регистрации <br></br> программы для ЭВМ
                  </a>
                </div>
                <div className="footer-main-important__images">
                  <a href="#" onClick={(event) => event.preventDefault()}>
                    <picture>
                      <source srcSet="/static/customer/footer/main/pay/01.webp" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/pay/01.png"
                        alt="MasterCard"
                        className="footer-main-important__image"
                      />
                    </picture>
                  </a>
                  <a href="#" onClick={(event) => event.preventDefault()}>
                    <picture>
                      <source srcSet="/static/customer/footer/main/pay/02.webp" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/pay/02.png"
                        alt="МИР"
                        className="footer-main-important__image"
                      />
                    </picture>
                  </a>
                  <a href="#" onClick={(event) => event.preventDefault()}>
                    <picture>
                      <source srcSet="/static/customer/footer/main/pay/03.webp" type="image/webp" />
                      <img
                        src="/static/customer/footer/main/pay/03.png"
                        alt="Visa"
                        className="footer-main-important__image"
                      />
                    </picture>
                  </a>
                </div>
                <p className="footer-main-right__description">
                  <span>Skilla © 2010-{currentYear} Копирование информации запрещено </span>

                  <span>Skilla — зарегистрированный товарный знак, номер свидетельства 771589</span>

                  <span>ООО «Скилла Инновации» ИНН 4706050219</span>

                  <span>Санкт-Петербург, пр. Энергетиков, д.10, оф.416 hi@skilla.ru</span>

                  <span className="footer-main-right__description__bottom">
                    Информация на данном сайте носит информационный характер и не является офертой. ст. 437 ч.
                    1 ГК РФ.
                  </span>
                </p>
                <a href="https://lk.skilla.ru" className="footer-main-right__button">
                  <picture>
                    <source srcSet="/static/customer/footer/main/mini-logo.png" type="image/webp" />
                    <img src="/static/customer/footer/main/mini-logo.png" alt="логотип" />
                  </picture>
                  <span className="arrow-right-top-smail icon"></span>
                </a>
              </div>
            </main>
            {/*{!book && <FooterBook />}*/}
            <footer className="footer-footer">
              <div className="container container_footer-footer">
                <a href="https://apps.apple.com/ru/app/skilla-work/id1531442328" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/01.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/01.png"
                      alt="App Store"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.skilla.Skilla" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/02.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/02.png"
                      alt="Google Play"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>

                {/*<a href="https://skilla.ru/apk/skilla.apk" target="_blank">
                  <picture>
                    <source srcSet="/static/customer/footer/footer/03.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/03.png"
                      alt="NASHSTORE"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>*/}

                <a
                  href="https://appgallery.huawei.com/#/app/C103862195?channelId=Skilla%20Official&id=1f01b81b103942dc8b9033f135eff478&s=38C79299AC884CC8EAC853E9E0FBDDCF2F32B4BC9BE70C49CE2B51F0D56F567D&detailType=0&v="
                  target="_blank"
                >
                  <picture>
                    <source srcSet="/static/customer/footer/footer/04.webp" type="image/webp" />
                    <img
                      src="/static/customer/footer/footer/04.png"
                      alt="AppGallery"
                      className="footer-footer__image"
                    />
                  </picture>
                </a>
              </div>
            </footer>
          </StyledFooterMobile>
        )}
      </>
    )
})
const StyledFooter = styled.footer`
  position: relative;
  color: #fff;

  .footer__video {
    position: absolute;
    right: 150px;
    z-index: 2;
    width: 720px;
    height: 405px;
    bottom: 32%;

    @media (max-width: 1520px) {
      left: 165px;
      bottom: 100px;
    }
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  }

  .post-tag {
    margin: -30px 0 35px;
    display: contents;
  }

  .post-tag a {
    display: inline-block;
    font-size: 14px;
    line-height: 13px;
    padding: 4px 7px 3px;
    border-right: 1px solid;
    position: relative;
    color: #000729;
  }

  .footer__overlay {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    background: url(/static/business/triggers/skilla-video-cover.jpg);
    background-size: cover;
    cursor: pointer;
  }

  .footer__containerqr {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .footer__qr {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 45px;
  }

  .header-form {
    padding: 24px;
    background-color: #303550;
    border-radius: 12px;
    width: max-content;
    margin: 56px 0 0 0;
    &.modalAnswer {
      background: transparent !important;
      padding: 0;
      @media (max-width: 768px) {
        width: 343px;
        margin: 32px auto;
      }
    }

    &__button {
      &.contracts {
        background: #002cfb;
        color: #fff;
        transition: all 0.2s linear;

        & span {
          margin-left: 16px;
          transition:
            all 0.3s ease,
            color 0.3s ease;
        }

        &:hover {
          background-color: #fff;
          color: #002cfb;

          & span {
            background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8.5 0L7.09 1.41L12.67 7H0.5V9H12.67L7.09 14.59L8.5 16L16.5 8L8.5 0Z' fill='%23002CFB'/%3e%3c/svg%3e");
          }
        }
      }
    }

    &.contracts {
      margin-bottom: 80px;
      background-color: rgba(0, 7, 41, 0.5);
    }

    &.business {
      background-color: rgb(48, 53, 80);
      margin: 56px 0 20px 0;
    }
  }

  .header-form__textarea {
    resize: none;
    font-family: inherit;
    width: 100%;
    min-height: 105px;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    padding: 12px 11px;
    outline: none;

    &::placeholder {
      color: #abadba;
    }
  }

  .header-form__flex {
    display: flex;
  }

  .header__clients_worker {
    &--margin {
      margin-bottom: 300px;
      position: relative;
      z-index: 2;

      @media (max-width: 1600px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        max-width: 400px;
      }
    }
  }

  .header-form__input {
    display: block;
    height: 50px;
    background-color: rgba(0, 7, 41, 0.3);
    padding: 12.5px 13px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px 0 0 4px;
    width: 275px;
    color: #fff;
    font-size: 18px;

    &.error {
      border: 1px solid red;
    }

    &.opacity {
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.2);
    }
    &::placeholder {
      color: #fff;
    }
  }

  .middle-heroes__preview {
    @media (max-width: 1600px) {
      width: 100%;
      height: 50%;
    }
  }

  .header__button {
    cursor: pointer;
    width: 310px;
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    align-items: center;
    border: 0;
    background-color: #002cfb;
    border-radius: 4px;
    padding: 16px;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;
    font-family: inherit;

    & span {
      margin-left: 16px;
      transition:
        all 0.3s ease,
        color 0.3s ease;
    }

    &:hover {
      background-color: #fff;
      color: #002cfb;

      & span {
        background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M8.5 0L7.09 1.41L12.67 7H0.5V9H12.67L7.09 14.59L8.5 16L16.5 8L8.5 0Z' fill='%23002CFB'/%3e%3c/svg%3e");
      }
    }
  }

  .header-form__labels {
    display: flex;
    grid-gap: 32px;
    margin-top: 18px;
  }

  .header-form__label {
    font-size: 16px;
    line-height: 120%;
    color: #c7c9d1;
    display: flex;
    align-items: center;
  }

  .header-form__checkbox {
    margin: 0 8px 0 0;
    width: 16px;
    height: 16px;
    cursor: pointer;

    &:checked::before {
      border: 1px solid #00ff88;
      background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='7' viewBox='0 0 6 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='3' cy='3.5' r='3' fill='%2300FF88'/%3e%3c/svg%3e ")
        no-repeat center #303550;
      background-position: 4px 3.5px;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #303550;
      border: 1px solid rgba(199, 201, 209, 0.3);
      border-radius: 50%;
    }
  }

  .header__info {
    color: #786c6c99;
    padding: 171px 0 48px;

    a {
      text-decoration: underline;
      color: #786c6c99;
      transition: all 0.2s linear;
      margin-left: 5px;
      &:hover {
        color: #97a8ff;
      }
    }
  }

  .header__info {
    &.vip {
      padding: 0 0 46px;
      font-weight: 400;
      font-size: 14px;
      color: #fff;
      opacity: 0.6;
    }

    &.contracts {
      padding: 36px 0 23px;
    }

    &.technologies {
      padding: 0 0 162px;
    }
  }

  .footer-main__logo {
    cursor: pointer;
  }

  .footer-main_worker .footer-main__logo span {
    background-color: #8800ff;
  }

  .footer-header {
    padding: 135px 0 45px 0;

    background:
      radial-gradient(55.7% 97% at 0% 3%, #000e50 21.6%, rgba(0, 23, 134, 0) 80.42%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(20.81% 43.51% at 87.01% 11.68%, #001fb2 0%, rgba(0, 23, 134, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(66.95% 140.01% at 66.95% 90.11%, #000e50 0%, #001786 42.05%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    mix-blend-mode: normal;

    background-size: 100% 100%;
    position: relative;
    &__politics {
      padding: 0;
      margin-top: 46px;
      color: rgba(255, 255, 255, 60%);
      a {
        color: rgba(255, 255, 255, 60%);
        margin-left: 5px;
        transition: all 0.2s linear;
        text-decoration: underline;

        &:hover {
          color: #97a8ff;
          text-decoration: underline;
        }
      }
    }

    @media (max-width: 768px) {
      padding-bottom: 0;
    }
  }

  .footer-header_worker {
    margin-top: 343px;
    background: url('/static/worker/footer/header/background.png') no-repeat center right / cover;
  }

  .footer-header_business {
    //min-height: 1000px;
    padding-top: 240px;
    background: url('/static/business/footer/header/background.png') no-repeat center top / cover;

    &.pt {
      padding: 310px 0 0 0;
    }
  }

  @media (max-width: 1920px) {
    .footer-header_business {
      padding-top: 83px;
    }
  }

  .footer-header_vip {
    background:
      radial-gradient(146.1% 692.06% at 98.79% 0%, rgba(0, 80, 41, 0.81) 0%, rgba(0, 7, 41, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      #000729;
    min-height: 740px;
  }

  .footer-header_founder {
    padding: 200px 0 48px 0;
    background: radial-gradient(50.82% 269.17% at 98.79% 0%, #00502b 0%, rgba(0, 7, 41, 0) 100%), #000729;

    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);

    &-text {
      font-weight: 400;
      font-size: 22px;
      line-height: 140%;
      color: #e3e4e8;
      margin: 82px 0 16px;
    }
  }

  .footer-main_business .footer-main__logo span {
    background-color: #00ff88;
    color: #000729;
  }

  .footer-header__title_worker {
    font-size: 46px;
    font-weight: 800;
    line-height: 55px;
    margin-bottom: 16px;
    span {
      color: #b866ff;
    }
  }

  .footer-header__title_customer {
    span {
      color: #ffd500;
    }
  }

  .footer-header__title {
    &.about {
      width: 860px;
    }
  }

  .footer-header__content_worker {
    position: relative;
    z-index: 1;
    max-width: 685px;
    bottom: 300px;

    &--little-bottom {
      position: relative;
      bottom: 150px;
    }
  }

  .footer-header__icon {
    margin-bottom: 32px;
  }

  .footer__logos {
    width: 922px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    &.mt {
      margin-top: 215px;
    }

    @media (max-width: 1600px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 450px;
    }
  }

  .logos-bottom {
    width: 1400px;
  }

  .footer-header__title {
    //width: 739px;
    font-size: 40px;
    line-height: 55px;
    font-weight: 800;
    margin-bottom: 16px;

    &.technologies {
      width: 904px;

      span {
        color: #ffd500;
      }

      @media (max-width: 768px) {
        span {
          color: #ffd500 !important;
        }
      }
    }
  }

  .footer-header__title_business {
    & span {
      color: #00ff88;
    }
  }

  .footer-header__title_founder {
    width: 807px;
    @media (max-width: 1600px) {
      max-width: 700px;
      width: 100%;
    }
  }

  .footer-header__content_business {
    position: relative;
    z-index: 3;
  }

  .footer-header__content_business .footer-header__description {
    color: #e3e4e8;
  }

  .footer-header__description {
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;

    &.contracts {
      //max-width: 438px;
      width: 100%;
    }

    @media (max-width: 1600px) {
      font-size: 18px;
    }
  }

  .footer-hh {
    margin-top: 24px;
    picture {
      margin-top: 12px;
      display: flex;
      width: 160px;
      height: auto;
      cursor: pointer;
      transition: all 0.2s;

      &:hover {
        filter: brightness(0.9);
      }

      img {
        border-radius: 4px;
        width: 100%;
      }
    }
  }

  .footer-hh__title {
    font-size: 18px;
    line-height: 120%;
    font-weight: 700;
    color: #e3e4e8;
  }

  .footer-header__qr-code {
    margin-top: 62px;
  }

  .footer-header__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .footer-header__form {
    margin: 56px 0;
  }

  .footer-header__image {
    &.customer {
      object-fit: contain;
      position: absolute;
      bottom: 0;
      right: -120px;
      height: 800px;

      @media (max-width: 1600px) {
        right: -125px;
      }
    }

    &.technologies {
      @media (max-width: 1600px) {
        right: -235px;
      }
    }

    &.customer {
      @media (max-width: 1700px) {
        right: -400px;
      }
    }
  }

  .footer-header__image_worker {
    height: 1000px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .footer-header__image_worker-2 {
    position: absolute;
    right: 0;
    bottom: -300px;

    @media (max-width: 1600px) {
      right: -190px;
    }
  }

  .footer-header__image_founder {
    position: absolute;
    right: 237px;
    bottom: -300px;

    @media (max-width: 1600px) {
      right: 0;
    }
  }

  .container_footer-header {
    display: flex;
    justify-content: space-between;
  }

  .footer-main {
    background:
      radial-gradient(55.7% 97% at 0% 3%, #000e50 21.6%, rgba(0, 23, 134, 0) 80.42%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(20.81% 43.51% at 87.01% 11.68%, #001fb2 0%, rgba(0, 23, 134, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(66.95% 140.01% at 66.95% 90.11%, #000e50 0%, #001786 42.05%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    mix-blend-mode: normal;
    background-size: 100% 100%;
    padding: 65px 0 35px 0;
  }

  .footer-main_worker {
    position: relative;
    background:
      radial-gradient(63.02% 48.11% at 50% 23.37%, #4c0f7b 0%, rgba(85, 20, 142, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(22.68% 53.3% at 96.51% -3.22%, #4c1279 0%, rgba(191, 119, 255, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(31.72% 67.58% at -2.76% 107.52%, #ffe9c0 0%, rgba(123, 34, 197, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(45.31% 97.19% at -0.62% 55.77%, #cc79ff 0%, rgba(123, 34, 197, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(70.6% 284.26% at 81.04% 82.79%, #2f0058 0%, #8800ff 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    background-size: 100% 100%;
  }

  .footer-main_business {
    position: relative;
    z-index: 1;
    background:
      radial-gradient(41.09% 72.88% at 10.68% 15.98%, #00341c 0%, rgba(0, 91, 48, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(37.63% 76.37% at 100% 0%, #007e43 0%, rgba(0, 91, 48, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(79.17% 174.71% at 79.17% 78.17%, #00361c 0%, #006636 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    mix-blend-mode: normal;
    background-size: 100% 101%;
  }

  .footer-main_article {
    background:
      radial-gradient(41.09% 72.88% at 10.68% 15.98%, #00341c 0%, rgba(0, 91, 48, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(37.63% 76.37% at 100% 0%, #007e43 0%, rgba(0, 91, 48, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(79.17% 174.71% at 79.17% 78.17%, #00361c 0%, #006636 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    mix-blend-mode: normal;
  }

  .container_footer-main {
    display: flex;
    justify-content: space-between;
  }

  .footer-main__left {
    display: flex;
    flex-direction: column;
    /*  justify-content: space-between; */
  }

  .footer-main__logo {
    & > div {
      display: flex;
      align-items: center;

      & span {
        display: block;
        margin-left: 8px;
        padding: 5px 8px;
        background-color: #002cfb;
        color: #fff;
        font-size: 16px;
        line-height: 120%;
        font-weight: 600;
        border-radius: 4px;

        @media (max-width: 1600px) {
          margin-right: 25px;
        }
      }
    }

    & p {
      margin-top: 16px;
      font-size: 14px;
      line-height: 120%;
      color: #abadba;
    }
  }

  .footer-main__qr-code {
    max-width: 95px;
  }

  .footer-main-important {
    position: relative;
  }

  .footer-main-important__links {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    margin: 24px 0;
  }

  .footer-main-important__link {
    color: #abadba;
    font-size: 14px;
    line-height: 120%;
    transition: color 0.3s ease;
    text-align: start;
    cursor: pointer;
    text-align: inherit;

    &:hover {
      color: #fff;
    }
  }

  .footer-main-important__images {
    display: flex;
    gap: 24px;
  }

  .footer-main-right {
    max-width: 829px;
  }

  .footer-main-right__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 24px;
  }

  .footer-main-item__title {
    display: inline-block;
    font-size: 18px;
    line-height: 120%;
    font-weight: 800;
    color: #e3e4e8;
    margin-bottom: 24px;
  }

  .footer-main-item__links {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }

  .footer-main-item__link {
    font-size: 16px;
    color: #abadba;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  .footer-main-item__button {
    padding: 0 16px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    font-family: inherit;
    font-size: 16px;
    color: #fff;
    line-height: 120%;
    margin-top: 12px;
    cursor: pointer;
    transition:
      all 0.3s ease,
      color 0.3s ease;
    display: inline-block;
    text-decoration: none;
    padding: 8px;

    &:hover {
      border: 1px solid #fff;
    }

    @media (max-width: 1600px) {
      font-size: 12px;
      padding: 7px;
    }
  }

  .footer-main-right__images {
    margin-top: 12px;
    display: flex;
    gap: 25px;
    margin-bottom: 12px;

    a {
      width: 34px;
      height: 34px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .footer-main-right__flex {
    display: flex;
    justify-content: space-between;
    padding-right: 32px;
  }

  .footer-main-right__description {
    font-size: 14px;
    line-height: 120%;
    color: #abadba;
    & span {
      display: block;
    }

    &__bottom {
      max-width: 540px;
      width: 100%;
    }
  }

  .footer-main-right__button {
    display: flex;
    align-items: center;
    height: 51px;
    padding: 0 16px;
    border-radius: 4px;
    border: 1px solid #ffffff66;
    cursor: pointer;
    transition: border-color 0.3s ease;

    & span {
      margin-left: 16px;
    }

    &:hover {
      border-color: #fff;
    }
  }

  .footer-footer {
    padding: 15px 0 12px 0;
    background-color: #0f1324;
  }

  .container_footer-footer {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .footer-header__image_business {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 90%;

    &.vip {
      bottom: -240px;
      height: 170%;
    }
  }

  @media (max-width: 1925px) {
    .footer-header__image_business {
      width: auto;
      height: 90%;
    }
  }

  .footer-header__image_training,
  .footer-header__image_reviews {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 90%;

    @media (max-width: 1600px) {
      right: -150px;
      width: initial;
    }
  }

  @media screen and (max-width: 1600px) {
    .footer-header__image_worker {
      right: -430px;
    }

    .footer-header_business {
      padding-top: 100px;
      padding-bottom: 40px;

      & .header__info {
        padding: 0 0 40px;
        line-height: 130%;
      }
    }

    .footer-header__title_business_main span {
      display: block;
    }
  }

  .footer-main_worker-btn {
    margin-bottom: 30px;
    &__span {
      background: #8800ff;
      display: block;
      margin-left: 8px;
      padding: 5px 8px;
      color: #fff;
      font-size: 12px;
      line-height: 120%;
      font-weight: 600;
      border-radius: 4px;
    }
    &__wrapper {
      display: flex;
      align-items: center;
    }

    p {
      margin-top: 16px;
      font-size: 12px;
      line-height: 120%;
      color: #abadba;
    }
  }
`

const StyledFooterMobile = styled.footer`
  color: #fff;
   
  .footer-hh {
    margin-top: 12px;
	display: flex;
	flex-direction: column;
	picture {
		margin-top: 16px;
		display: flex;
		width: 160px;
		height: auto;
		cursor: pointer;
        transition: all 0.2s;
		&:hover {
			filter: brightness(0.9);
		}
	
		img {
			height: 100%;
			width: 100%;
		}
	}
	
  }

  .footer-hh__title {
	font-size: 18px;
	line-height: 120%;
	font-weight: 700;
	color: #e3e4e8;
}

  .header-form {
    padding: 20px;
    background-color: #303550 !important;
    border-radius: 5px;
    width: 100%;
    margin: 30px 0;

    &--founder {
      margin: 0;
      position: relative;
      z-index: 3;
    }
  }

  .footer-header {
  display: none;
    padding: 50px 0 290px 0;
    background: radial-gradient(55.7% 97% at 0% 3%, #000e50 21.6%, rgba(0, 23, 134, 0) 80.42%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(20.81% 43.51% at 87.01% 11.68%, #001fb2 0%, rgba(0, 23, 134, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(66.95% 140.01% at 66.95% 90.11%, #000e50 0%, #001786 42.05%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    mix-blend-mode: normal;
    position: relative;
	min-height: 720px;

	@media (max-width: 768px) {
		padding-bottom: 0;
	}
  }

  .footer-header_worker {
    padding: 80px 0 0 0;
    background: url('/static/worker/footer/header/background.png') no-repeat center top / cover;
  }

  .footer-header_business {
    display: block;
    overflow: hidden;
    padding: 90px 0 0 0;
    background: url('/static/business/footer/header/background.png') no-repeat center top / cover;
  }

  .footer-header__icon {
    margin-bottom: 15px;
  }

  .header__clients {
    display: none;
  }

  .footer-header__title {
    font-size: 24px;
    font-weight: 800;
    line-height: 120%;
    margin-bottom: 16px;
  }

  .footer-header__title_worker {
    margin-bottom: 15px;
  }

  .footer-header__title_business {
    & span {
      color: #00ff88;
    }
  }

    .footer-header__title_worker {
    & span {
      color: #b866ff;
    }
  }

  .footer-header__title_founder {
    margin-top: 25px !important;
  }

  .footer-header__image_founder {
    margin-top: -75px !important;
    bottom: -35px !important;
    right: -45px !important;
  }

  .footer-header__description {
    font-size: 16px;
    color: #e3e4e8;
    line-height: 130%;
  }

  .header-form__flex {
    display: flex;
  }

  .header-form__input {
    display: block;
    height: 50px;
    background-color: rgba(0, 7, 41, 0.3);
    padding: 12.5px 13px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px 0 0 4px;
    width: 275px;
    color: #fff;

    &::placeholder {
      color: #fff;
    }
  }

  .header-form__button {
    margin-left: 0;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 12.5px 24px;
    font-size: 18px;
    line-height: 120%;
    font-weight: 600;
    cursor: pointer;
    background-color: #00ff88;
    border-radius: 4px;
    border: 0;
    font-size: 16px;
    transition: background-color 0.3s ease;
    color: #000;
    font-family: inherit;

    & span {
      margin-left: 12px;
    }

    &:hover {
      background-color: #00cc6e;
    }

    > contracts {
      background: #002cfb;
    }
  }

  .header-form__labels {
    display: grid;
    grid-gap: 25px;
    grid-template-columns: 1fr 1fr;
    margin-top: 18px;
    padding-left: 16px;
  }

  .header-form__textarea {
    resize: none;
    font-family: inherit;
    width: 100%;
    min-height: 105px;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 0;
    padding: 12px 11px;
    margin-bottom: 8px;
    outline: none;

    &::placeholder {
      color: #abadba;
    }
  }

  .header-form__label {
    font-size: 16px;
    line-height: 120%;
    color: #c7c9d1;
    display: flex;
    align-items: center;
  }

  .header-form__checkbox {
    margin: 0 8px 0 0;
    width: 16px;
    height: 16px;

    &:checked::before {
      border: 1px solid #00ff88;
      background: url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='7' viewBox='0 0 6 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='3' cy='3.5' r='3' fill='%2300FF88'/%3e%3c/svg%3e ")
        no-repeat center #303550;
      background-position: 4px 3.5px;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background-color: #303550;
      border: 1px solid rgba(199, 201, 209, 0.3);
      border-radius: 50%;
    }
  }

  .footer-header__image {
    position: relative;
    &-performers {
      margin-top: -66px;
    bottom: -28px;
    left: 4px;
    position: relative;

    @media(max-width: 768px) {
      margin-top: -230px;
      bottom: 0;
    }
    }
}


  }

  .footer-header__image_worker {
    position: relative;
  }

  .footer-main_customer {
    background-color: #000729;
    padding: 40px 0 50px 0;
  }

  .footer-main_worker {
    background: radial-gradient(63.02% 48.11% at 50% 23.37%, #4c0f7b 0%, rgba(85, 20, 142, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(22.68% 53.3% at 96.51% -3.22%, #4c1279 0%, rgba(191, 119, 255, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(31.72% 67.58% at -2.76% 107.52%, #ffe9c0 0%, rgba(123, 34, 197, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(45.31% 97.19% at -0.62% 55.77%, #cc79ff 0%, rgba(123, 34, 197, 0) 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
      radial-gradient(70.6% 284.26% at 81.04% 82.79%, #2f0058 0%, #8800ff 100%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
      position: relative;
      padding: 40px 0 50px 0;
      z-index: 1;
  }

  .footer-main_business {
    background: radial-gradient(41.09% 72.88% at 10.68% 15.98%, #00341C 0%, rgba(0, 91, 48, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(37.63% 76.37% at 100% 0%, #007E43 0%, rgba(0, 91, 48, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(79.17% 174.71% at 79.17% 78.17%, #00361C 0%, #006636 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    mix-blend-mode: normal;
    padding: 40px 0 50px 0;

    & .footer-main__logo > div > span {
      background-color: #00ff88;
      color: #000729;
      font-weight: 700;
    }
  }

  .footer-main__logo {
    margin-bottom: 30px;
    & > div {
      display: flex;
      align-items: center;

      & span {
        display: block;
        margin-left: 8px;
        padding: 5px 8px;
        background-color: #002cfb;
        color: #fff;
        font-size: 12px;
        line-height: 120%;
        font-weight: 600;
        border-radius: 4px;
      }
    }

    & p {
      margin-top: 16px;
      font-size: 12px;
      line-height: 120%;
      color: #abadba;
    }

    & img {
      border-radius: 4px;
    }
  }

  .footer-main-item {
    cursor: pointer;
  }

  .footer-main-important__links {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin: 55px 0 32px 0;
  }

  .footer-main-important__link {
    color: #abadba;
    font-size: 14px;
    line-height: 120%;
    transition: color 0.3s ease;
    text-align: inherit;

    &:hover {
      color: #fff;
    }
  }

  .footer-main-important__images {
    display: flex;
    gap: 24px;
  }

  .footer-main-right__header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 24px;
  }

  .footer-main__items {
    margin-bottom: 55px;
  }

  .footer-main-item__title {
    display: inline-block;
    font-size: 18px;
    line-height: 120%;
    font-weight: 700;
    color: #e3e4e8;
    margin-bottom: 24px;
    display: flex;
    align-items: center;

    &::after {
      content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M10.59 0.589844L6 5.16984L1.41 0.589844L0 1.99984L6 7.99984L12 1.99984L10.59 0.589844Z' fill='white'/%3e%3c/svg%3e ");
      display: block;
      margin-left: 14px;
    }

    &.active::after {
      content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M10.59 7.99982L6 3.41982L1.41 7.99982L0 6.58982L6 0.589817L12 6.58982L10.59 7.99982Z' fill='white'/%3e%3c/svg%3e ");
    }
  }

  .footer-main-item__links {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    display: none;

    &.active {
      display: grid;
    }
  }

  .footer-main-item__link {
    font-size: 14px;
    line-height: 120%;
    color: #abadba;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }

  .footer-main-item__button {
    padding: 0 16px;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 4px;
    font-family: inherit;
    font-size: 14px;
    color: #fff;
    line-height: 120%;
    margin-top: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: none;
    margin-bottom: 24px;
    text-decoration: none;
    padding: 8px;

    &.active {
      display: inline-block;
    }

    &:hover {
      color: #040d36;
      background-color: #fff;
    }
  }

  .footer-main-right__images {
    display: flex;
    justify-content: center;
    gap: 32px;
  }


  .footer-main-right__description {
    margin: 32px 0;
    font-size: 12px;
    line-height: 120%;
    color: #abadba;

    & span {
      display: block;
    }
  }

  .footer-main-right__button {
    display: flex;
    align-items: center;
    width: max-content;
    padding: 6px 13px;
    border-radius: 4px;
    border: 1px solid #ffffff66;
    cursor: pointer;
    transition: border-color 0.3s ease;

    & img {
      height: 23px;
    }

    & span {
      margin-left: 13px;
      width: 12px;
      height: 12px;
    }

    &:hover {
      border-color: #fff;
    }
  }

  .container_footer-footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 15px;

    & a {
      display: block;
      width: 100%;
      height: 100%;

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .footer-footer {
    background-color: #000729;
  }

  .header__form {
    margin: 0 !important;
  }

  .footer-main_worker-btn {
    margin-bottom: 30px;

        &__span {
      background: #8800FF;
      display: block;
      margin-left: 8px;
      padding: 5px 8px;
      color: #fff;
      font-size: 12px;
      line-height: 120%;
      font-weight: 600;
      border-radius: 4px;
    }
    &__wrapper {
    display: flex;
    align-items: center;
    }

    p {
      margin-top: 16px;
    font-size: 12px;
    line-height: 120%;
    color: #abadba;
}
    }

  .performersReviews {
    &__btn {
      @media (max-width: 768px) {
        position: relative;
        z-index: 2;
        align-items: center;
        background-color:rgba(231, 204, 255, 0.25);
        border: 0;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        display: flex;
        font-family: inherit;
        font-size: 20px;
        font-weight: 600;
        padding: 12px 24px;
        transition: background-color 0.3s ease;
        margin: 72px auto;
      }
    }
  }

  .down {
    @media (max-width: 768px) {
      margin-left: 17px;
    }
  }

`

export default Footer
